import React, { useState, useCallback } from 'react';
import PhoneInput, {
  getCountryCallingCode,
} from 'react-phone-number-input/mobile';
import { useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import Flags from 'country-flag-icons/react/1x1';

import type { OptionProps, StylesConfig, MenuPosition } from 'react-select';
import type { Control } from 'react-hook-form';
import type { FeatureProps } from 'react-phone-number-input';
import type { FlagComponent } from 'country-flag-icons/react/1x1';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import 'react-phone-number-input/style.css';
import styles from './index.module.scss';

type Props = {
  control: Control<any>;
  name: string;
  isBasicStyles?: boolean;
  placeholder?: string;
  selectMenuPosition?: MenuPosition;
};

type SelectOption = { value: keyof FlagComponent; label: string };

const customStyles: StylesConfig<SelectOption, false> = {
  control: provided => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
    paddingLeft: 0,
    backgroundColor: 'transparent',
  }),
  valueContainer: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0,
    paddingLeft: '4px',
    backgroundColor: 'transparent',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  container: provided => ({
    ...provided,
    position: 'static',
    padding: 0,
    marginRight: '4px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
  menu: (provided, props) => ({
    ...provided,
    zIndex: 5,
    width: props.menuPosition === 'fixed' ? 280 : undefined,
  }),
};

const InternationalPhoneIcon = () => (
  <IconSVG name={IconsNames.phone} className={styles.international} />
);

const Option = ({ ...props }: OptionProps<SelectOption, false>) => {
  const Flag: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> =
    Flags[props.data.value];

  return (
    <components.Option {...props}>
      <span className={styles.labelWrapper}>
        <div className={styles.left}>
          {!!Flag ? (
            <Flag className={styles.flagIcon} />
          ) : (
            <InternationalPhoneIcon />
          )}
          <span>{props.label}</span>
        </div>
        {props.data.value && (
          <span>+{getCountryCallingCode(props.data.value)}</span>
        )}
      </span>
    </components.Option>
  );
};

const CountrySelectComponent = ({
  menuPosition,
  ...props
}: FeatureProps<any> & { menuPosition?: MenuPosition }) => {
  const { value, onChange } = props;

  return (
    <div className={styles.wrapper}>
      {value ? (
        <props.iconComponent country={value} label={value} />
      ) : (
        <InternationalPhoneIcon />
      )}
      <Select
        styles={customStyles}
        {...props}
        isSearchable={false}
        onChange={val => {
          onChange(val?.value);
        }}
        menuPosition={menuPosition}
        components={{ Option }}
      />
    </div>
  );
};

const PhoneField = ({
  control,
  name,
  isBasicStyles,
  placeholder,
  selectMenuPosition,
}: Props) => {
  const { field } = useController({ control, name });
  const [phone, setPhone] = useState(field.value);

  const SelectComponent = useCallback(
    (props: FeatureProps<any>) => (
      <CountrySelectComponent {...props} menuPosition={selectMenuPosition} />
    ),
    [selectMenuPosition]
  );

  return (
    <PhoneInput
      placeholder={placeholder}
      international
      className={isBasicStyles ? styles.basic : ''}
      internationalIcon={InternationalPhoneIcon}
      value={phone}
      onChange={phoneValue => {
        field.onChange(phoneValue);
        setPhone(phoneValue);
      }}
      countrySelectComponent={SelectComponent}
    />
  );
};

export default PhoneField;
