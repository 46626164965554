import { useTranslation } from 'react-i18next';

import type { FeatureResponse } from 'types/models';

import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  benefits?: FeatureResponse[];
  disadvantages?: FeatureResponse[];
};

const CompanyFeatures: React.FC<Props> = ({ benefits, disadvantages }) => {
  const { t } = useTranslation();

  const isFeaturesVisible = !!benefits?.length || !!disadvantages?.length;

  return (
    <>
      {isFeaturesVisible && (
        <SectionWrapper
          className={styles.companyFeatures}
          iconName={IconsNames.star_bordered}
          title={t('manage-products.features')}
        >
          <div className={styles.content}>
            {!!benefits?.length && (
              <div>
                <h4 className={styles.type}>
                  {t('manage-products.advantages')}
                </h4>
                <ul className={styles.list}>
                  {benefits.map(item => (
                    <li key={item.id} className={styles.benefits}>
                      <IconSVG
                        name={IconsNames.check_mark}
                        className={styles.icon}
                      />
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {!!disadvantages?.length && (
              <div>
                <h4 className={styles.type}>
                  {t('manage-products.disadvantages')}
                </h4>
                <ul className={styles.list}>
                  {disadvantages.map(item => (
                    <li key={item.id} className={styles.disadvantages}>
                      <IconSVG
                        name={IconsNames.horizontal_rule}
                        className={styles.icon}
                      />
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </SectionWrapper>
      )}
    </>
  );
};

export default CompanyFeatures;
