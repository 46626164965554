import { useTranslation } from 'react-i18next';

import AssetDownloadContent from 'components/Product/AssetDownloadContent';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import { useGetPublicAsset } from 'hooks/api/useGetPublicAsset';

import styles from './index.module.scss';

type Props = {
  companySlug: string;
  productSlug: string;
  assetSlug: string;
  contactShareId?: string;
};

const AssetModal = ({
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
}: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPublicAsset({
    companySlug,
    productSlug,
    assetSlug,
    contactShareId,
  });

  return (
    <>
      {data && (
        <ModalWindow
          className={styles.modal}
          closeButtonClassName={styles.close}
          primaryTitle={t('manage-products.asset-details')}
        >
          <AssetDownloadContent
            data={data}
            isModalContent
            companySlug={companySlug}
            productSlug={productSlug}
            assetSlug={assetSlug}
            contactShareId={contactShareId}
          />
        </ModalWindow>
      )}

      {isLoading && <LoaderScreen />}
    </>
  );
};

export default AssetModal;
