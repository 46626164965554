import cn from 'classnames';
import { useRef, useEffect } from 'react';
import { useClickAway, useLockBodyScroll } from 'react-use';
import { useTranslation } from 'react-i18next';

import Error from 'components/common/Error';
import Button, { ButtonProps } from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';

import useModal from 'contexts/ModalContext';
import useEscapeEvent from 'hooks/useEscapeEvent';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  primaryTitle?: string;
  title?: string | React.ReactNode;
  description?: string;
  errorMessage?: string;
  positiveButtonProps?: ButtonProps;
  negativeButtonProps?: ButtonProps;
  successMessage?: string;
  className?: string;
  closeButtonClassName?: string;
  onClose?: () => void;
  isLockBodyScroll?: boolean;
  closeOnClickAway?: boolean;
  buttonsWrapperClassName?: string;
  isNarrowButtons?: boolean;
  ariaLabel?: string;
  hasSubmitButton?: boolean;
  onSubmit?: () => void;
  topSubmitButtonName?: string;
  isNestedModal?: boolean;
};

const ModalWindow: React.FC<Props> = ({
  children,
  title,
  description,
  errorMessage,
  positiveButtonProps,
  negativeButtonProps,
  primaryTitle,
  successMessage,
  className,
  closeButtonClassName,
  onClose,
  isLockBodyScroll,
  closeOnClickAway = true,
  buttonsWrapperClassName,
  isNarrowButtons,
  ariaLabel,
  hasSubmitButton,
  topSubmitButtonName,
  isNestedModal,
}) => {
  const { t } = useTranslation();
  const { closeModal, isModalVisible } = useModal();
  const modalRef = useRef(null);
  useLockBodyScroll(isLockBodyScroll);
  const closeCallback = onClose ? onClose : () => closeModal(isNestedModal);
  useEscapeEvent(closeCallback);
  useClickAway(modalRef, () => {
    if (closeOnClickAway) closeCallback();
  });

  useEffect(() => {
    if (!onClose) {
      window.onpopstate = () => {
        if (isModalVisible) {
          closeModal();
        }
      };
    }
  });

  return (
    <div className={styles.modal}>
      <div
        ref={modalRef}
        className={cn(
          styles.window,
          {
            [styles['no-primary']]: !primaryTitle,
          },
          className
        )}
        aria-label={ariaLabel}
      >
        {!hasSubmitButton && (
          <button
            type="button"
            onClick={closeCallback}
            className={cn(styles.close, closeButtonClassName)}
          >
            <IconSVG name={IconsNames.close} />
          </button>
        )}
        {primaryTitle && (
          <h2
            className={cn(styles.primary, {
              [styles.hasSubmitButton]: hasSubmitButton,
            })}
          >
            <span className={styles.primaryText}>{primaryTitle}</span>
            {hasSubmitButton && (
              <>
                <Button type="button" onClick={closeCallback} white>
                  {t('common.button.cancel')}
                </Button>
                <Button type="submit">
                  {topSubmitButtonName || t('common.button.submit')}
                </Button>
              </>
            )}
          </h2>
        )}
        {title && (
          <>
            {typeof title === 'string' ? (
              <h3 className={styles.title}>{title}</h3>
            ) : (
              <div className={styles.title}>{title}</div>
            )}
          </>
        )}

        {description && <p className={styles.description}>{description}</p>}
        {errorMessage && <Error message={errorMessage} />}
        {successMessage && (
          <p className={styles.success}>
            <IconSVG name={IconsNames.success_checkmark} />{' '}
            <span>{successMessage}</span>
          </p>
        )}
        {children}
        {(negativeButtonProps || positiveButtonProps) && (
          <div
            className={cn(styles.buttons, buttonsWrapperClassName, {
              [styles['buttons--two']]:
                negativeButtonProps && positiveButtonProps,
              [styles['buttons--narrow']]: isNarrowButtons,
            })}
          >
            {positiveButtonProps && <Button {...positiveButtonProps} />}
            {negativeButtonProps && <Button {...negativeButtonProps} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalWindow;
