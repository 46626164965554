import { usePopperTooltip } from 'react-popper-tooltip';

import type { SetStateAction, Dispatch } from 'react';

import SimpleTooltip from 'components/UI/SimpleTooltip';

import getLongStringView from 'helpers/getLongStringView';
import { SelectOption } from 'types/models';

import styles from '../index.module.scss';

const MAX_LABEL_LENGTH = 25;

type Props = {
  handleInputChange: (item: SelectOption) => void;
  item: SelectOption;
  isChecked: boolean;
  showTooltip: boolean;
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
};

const Option = ({
  item,
  handleInputChange,
  isChecked,
  showTooltip,
  setShowTooltip,
}: Props) => {
  const tooltipProps = usePopperTooltip({
    placement: 'left',
  });

  const handleShowTooltip = () => setShowTooltip(true);

  return (
    <label
      key={item.value}
      htmlFor={item.value}
      className={styles.option}
      ref={tooltipProps.setTriggerRef}
      onMouseEnter={handleShowTooltip}
      onTouchStart={handleShowTooltip}
      onFocus={handleShowTooltip}
    >
      <input
        type="checkbox"
        value={item.value}
        checked={isChecked}
        onChange={() => handleInputChange(item)}
        id={item.value}
      />
      <div className="">
        {getLongStringView(item.label, MAX_LABEL_LENGTH)}
        {showTooltip &&
          tooltipProps.visible &&
          item.label.length >= MAX_LABEL_LENGTH && (
            <SimpleTooltip className={styles.tooltip} options={tooltipProps}>
              {item.label}
            </SimpleTooltip>
          )}
      </div>
    </label>
  );
};

export default Option;
