import { useQuery } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse, CampaignResponse } from 'types/models';
import { AssetAttachedType } from 'constants/assets';

import useDeleteAsset from 'components/CreateCampaign/Step1/useDeleteAsset';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useGetAssets = ({
  campaign,
  setFormValue,
  enabled,
  campaignCPL,
}: {
  campaign?:
    | CreateCampaignValues
    | (CampaignResponse & { isJustCreated?: boolean });
  setFormValue?: UseFormSetValue<CreateCampaignValues>;
  enabled?: boolean;
  campaignCPL?: number;
}) => {
  const { axios, user } = useAuth();
  const { convertAssetFileResponse } = useConvertCampaignData();

  const mutation = useDeleteAsset();

  const getAssetsRequestSettings = useQuery<
    CampaignAssetFileResponse[],
    AxiosError
  >(
    ['campaign', campaign?.id.toString(), 'assets', user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<CampaignAssetFileResponse[]>(
          `/campaigns/${campaign?.id}/assets/`
        );
        if (typeof campaignCPL === 'number' && !!campaign?.id) {
          const filteredAssets = data.filter(asset => {
            if (
              (campaignCPL !== 0 &&
                asset.asset_type === AssetAttachedType.product) ||
              (campaignCPL === 0 &&
                asset.asset_type !== AssetAttachedType.product)
            ) {
              mutation.mutate({
                assetId: asset.id,
                campaignId: campaign.id,
              });
            } else return asset;
          });
          return filteredAssets;
        }
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled:
        !!campaign?.id &&
        !campaign.isJustCreated &&
        !!user?.company.id &&
        enabled,
      onSuccess: assets => {
        if (setFormValue) {
          const converted = assets.map(item => convertAssetFileResponse(item));
          setFormValue('campaign_files.assets', converted);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  return getAssetsRequestSettings;
};

export default useGetAssets;
