import { useQuery } from 'react-query';

import type { FeatureResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

export const useGetFeaturesList = ({
  companySlug,
  productId,
  productSlug,
  isProductJustSubmitted,
}: {
  companySlug?: string;
  productId?: number;
  isProductJustSubmitted: boolean;
  productSlug: string | null;
}) => {
  const { axios } = useAuth();

  const query = useQuery<FeatureResponse[], AxiosError>(
    ['featuresList', companySlug, productId],
    async () => {
      try {
        const { data } = await axios.get(
          `/companies/${companySlug}/products/${productSlug}/company-features/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        !!productSlug &&
        !!companySlug &&
        !!productId &&
        !isProductJustSubmitted,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
