import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';

import type {
  CampaignAssetBaseResponse,
  ProductAssetResponse,
} from 'types/models';

import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import PreviewButton from 'components/Product/CreateProductContent/PreviewButton';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';
import { AssetAttachedType } from 'constants/assets';

import defaultLogo from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  asset: CampaignAssetBaseResponse | ProductAssetResponse;
  isDragDropEnabled: boolean;
  onDeleteAsset: () => void;
  isDragging?: boolean;
  isDragEnabled?: boolean;
  dragDropRef?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > | null;
  onEdit: () => void;
  isDeleteDisabled?: boolean;
};

const DESCRIPTION_MAX_DISPLAY_LENGTH = 110;

const EditableAssetItem: React.FC<Props> = ({
  asset,
  isDragDropEnabled,
  onDeleteAsset,
  isDragging,
  isDragEnabled,
  dragDropRef,
  onEdit,
  children,
  isDeleteDisabled,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const tooltipProps = usePopperTooltip({ placement: 'top' });

  const handleDeleteAsset = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('common.field.asset').toLowerCase()}
          itemName={asset.title}
          onDelete={() => {
            closeModal();
            onDeleteAsset();
          }}
        />
      ),
    });
  };

  const assetDescription =
    asset.description.length > DESCRIPTION_MAX_DISPLAY_LENGTH
      ? `${asset.description.slice(0, DESCRIPTION_MAX_DISPLAY_LENGTH)}...`
      : asset.description;
  const isCampaignAsset = 'product_name' in asset;
  const isProductAsAsset = asset.asset_type === AssetAttachedType.product;
  const isProductLogo =
    isCampaignAsset && isProductAsAsset && asset.product_logo;

  return (
    <li
      className={cn(styles.wrapper, {
        [styles.dragging]: isDragging,
        [styles.draggable]: isDragEnabled,
      })}
      ref={dragDropRef as React.LegacyRef<HTMLLIElement>}
    >
      {isDragDropEnabled && (
        <IconSVG name={IconsNames.drag_indicator} className={styles.drag} />
      )}
      <img
        src={isProductLogo || asset.landing_image || defaultLogo}
        className={cn(styles.thumbnail, {
          [styles.productLogo]: isProductLogo,
        })}
      />
      <div className={styles.info}>
        <h5 className={styles.title}>{asset.title}</h5>
        <p className={styles.type}>
          {t(`common.field.${asset.type}`)}
          {isCampaignAsset && (
            <>
              <span className={styles.separator} />
              {asset.product_name}
              {!isProductAsAsset && (
                <>
                  <span className={styles.separator} />
                  {t(`common.field.${asset.content_type}`)}
                </>
              )}
            </>
          )}
        </p>
        <p className={styles.description}>{assetDescription}</p>
        <PreviewButton className={styles.preview}>
          {asset.attached_file_link_name || t('common.button.download')}
        </PreviewButton>
      </div>

      {children}

      <div className={styles.buttons}>
        <Button
          iconProps={{ name: IconsNames.edit }}
          onClick={onEdit}
          type="button"
        />
        <div>
          <div ref={isDeleteDisabled ? tooltipProps.setTriggerRef : undefined}>
            <Button
              iconProps={{ name: IconsNames.close }}
              white
              type="button"
              onClick={handleDeleteAsset}
              disabled={isDeleteDisabled}
            />
          </div>
          {tooltipProps.visible && isDeleteDisabled && (
            <SimpleTooltip options={tooltipProps}>
              {t('common.button.you-cant-delete-asset')}
            </SimpleTooltip>
          )}
        </div>
      </div>
    </li>
  );
};

export default EditableAssetItem;
