import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrencyMap from 'currency-symbol-map';

import {
  ProductPricingPlanPeriods,
  ProductPricingPlanResponse,
  ProductPricingPlanMonthlyAnnuallyPeriods,
} from 'components/Product/models';

import IconSVG from 'components/UI/IconSVG';
import ToggleSwitch from 'components/common/ToggleSwitch';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  planData: ProductPricingPlanResponse;
  isBig?: boolean;
};

const PlanInfo = ({
  isBig,
  planData: {
    id,
    name,
    period,
    description,
    benefits,
    period_details: periodDetails,
    currency,
  },
}: Props) => {
  const { t } = useTranslation();
  const [isYearlyPrice, setIsYearlyPrice] = useState(true);

  const currentCurrencySymbol = getSymbolFromCurrencyMap(currency) || currency;
  const formatPrice = (receivedPrice: number) =>
    receivedPrice === 0
      ? t('auth.free')
      : `${currentCurrencySymbol}${receivedPrice} `;

  const isMonthlyAndYearly =
    period === ProductPricingPlanPeriods['monthly-or-annually'] &&
    periodDetails.length > 1;

  const monthlyPeriodDetails = periodDetails.find(
    detail => detail.name === ProductPricingPlanMonthlyAnnuallyPeriods.monthly
  );
  const yearPeriodDetails = periodDetails.find(
    detail => detail.name === ProductPricingPlanMonthlyAnnuallyPeriods.annual
  );
  const currentMonthlyOrYearlyPeriod = isYearlyPrice
    ? yearPeriodDetails
    : monthlyPeriodDetails;
  const currentPeriodDetails = isMonthlyAndYearly
    ? currentMonthlyOrYearlyPeriod || periodDetails[0]
    : periodDetails[0];
  const isPerMonthSignVisible =
    isMonthlyAndYearly && isYearlyPrice && +currentPeriodDetails.price > 0;

  return (
    <div className={cn(styles.info, { [styles.big]: isBig })}>
      <h3 className={styles.heading}>{name}</h3>
      {description && <p className={styles.description}>{description}</p>}

      <p className={styles.price}>
        {formatPrice(+currentPeriodDetails.price)}
        {isPerMonthSignVisible && (
          <span className={styles.perMonth}>
            ({currentCurrencySymbol}
            {(+currentPeriodDetails.price / 12).toFixed(2)}{' '}
            {t('common.field.per-month').toLowerCase()})
          </span>
        )}
      </p>
      {isMonthlyAndYearly ? (
        <div className={styles.toggle}>
          <span className={cn({ [styles.active]: !isYearlyPrice })}>
            {t('common.field.monthly')}
          </span>
          <ToggleSwitch
            id={`pricing-plan-period-${id}`}
            checked={isYearlyPrice}
            onChange={() => setIsYearlyPrice(prev => !prev)}
            className={styles.switch}
          />
          <span className={cn({ [styles.active]: isYearlyPrice })}>
            {t('common.field.annual')}
          </span>
        </div>
      ) : (
        <span className={styles.period}>
          {period === ProductPricingPlanPeriods.custom
            ? currentPeriodDetails.name
            : t(`common.field.${currentPeriodDetails.name}`)}
        </span>
      )}

      {!!benefits.length && (
        <ul className={styles.benefits}>
          {benefits.map(benefit => (
            <li key={benefit.id} className={styles.benefit}>
              <IconSVG
                name={IconsNames.success_checkmark}
                className={styles.icon}
              />
              {benefit.name}
            </li>
          ))}
        </ul>
      )}

      {currentPeriodDetails.link && (
        <div className={styles.footer}>
          <a
            className={styles.link}
            href={currentPeriodDetails.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {currentPeriodDetails.link_title || t('common.button.see-details')}
            <IconSVG name={IconsNames.external_link} />
          </a>
        </div>
      )}
    </div>
  );
};

export default PlanInfo;
