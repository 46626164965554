import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { ProductResponse } from 'types/models';

import TopInfo from 'components/Product/ProductPage/TopInfo';
import AboutProduct from 'components/Product/ProductPage/AboutProduct';
import CompanyFeatures from 'components/Product/ProductPage/CompanyFeatures';
import PricingPlans from 'components/Product/ProductPage/PricingPlans';
import AssetShortcut from 'components/Product/ProductPage/AssetShortcut';
import GetDemoModal from 'components/Product/ProductPage/GetDemoModal';
import AssetsList from 'components/Product/ProductPage/AssetsList';
import LoaderScreen from 'components/common/LoaderScreen';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { AssetAttachedType, AssetContentType } from 'constants/assets';
import { IconsNames } from 'constants/constants';

import defaultLogo from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  data: Partial<ProductResponse>;
  refetch?: () => Promise<{ data: ProductResponse | undefined }>;
  isPreviewMode?: boolean;
};

const ProductPage = ({ data: productData, refetch, isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { companySlug, productSlug } = useParams();
  const { openModal } = useModal();
  const { user } = useAuth();
  const [isLoaderScreenVisible, setIsLoaderScreenVisible] = useState(false);

  const contactData =
    'share_contact_data' in productData
      ? productData.share_contact_data
      : undefined;

  const handleOpenGetDemoModal = useCallback(async () => {
    if (!contactData && !isPreviewMode) return;

    const openGetDemoModal = () =>
      openModal({
        Content: (
          <GetDemoModal
            productSlug={productSlug}
            companySlug={companySlug}
            companyName={productData?.company_name}
            isPreviewMode={isPreviewMode}
            defaultValues={
              contactData
                ? {
                    ...contactData,
                    job_title: {
                      value: contactData.job_title,
                      label: contactData.job_title,
                    },
                  }
                : { email: user?.email || '' }
            }
          />
        ),
      });

    if (productData.schedule_demo_link) {
      let link = productData.schedule_demo_link;

      if (!isPreviewMode && refetch) {
        setIsLoaderScreenVisible(true);
        const { data } = await refetch();

        if (data?.schedule_demo_link) {
          link = data?.schedule_demo_link;
        } else {
          return openGetDemoModal();
        }
        setIsLoaderScreenVisible(false);
      }

      window.open(link, '_blank')?.focus();
    } else {
      openGetDemoModal();
    }
  }, [productData]);

  const assetSlug =
    contactData?.asset_type !== AssetAttachedType.product &&
    contactData?.asset_slug;
  const isGetADemoAvailable =
    (!!contactData || !!isPreviewMode) && !!productData;

  return (
    <>
      <div className={styles.container}>
        <img
          src={productData?.background_image || defaultLogo}
          alt="bgImage"
          className={cn(styles.bgImage, {
            [styles.default]: productData && !productData?.background_image,
          })}
        />

        <TopInfo
          name={productData?.name}
          productPhoto={productData?.product_photo}
          isGetADemoAvailable={isGetADemoAvailable}
          onOpenGetDemo={handleOpenGetDemoModal}
          buttonName={productData?.schedule_demo_button_name}
          productId={productData?.id}
          companySlug={companySlug}
          shareContactData={contactData}
        />

        <main className={styles.flex}>
          {(!!productData?.description ||
            !!productData?.email ||
            !!productData?.website ||
            !!productData?.languages) && (
            <AboutProduct
              name={productData?.name}
              description={productData?.description}
              email={productData?.email}
              website={productData?.website}
              languages={productData?.languages}
              instagram={productData.instagram_link}
              facebook={productData.facebook_link}
              linkedin={productData.linkedin_link}
              twitter={productData.twitter_link}
            />
          )}

          <div className={styles.mainWidth}>
            {!!productData?.pricing_plans?.length && (
              <PricingPlans
                pricingPlans={productData?.pricing_plans}
                isAssetsExists={
                  Number(productData?.product_assets?.length || 0) > 0
                }
              />
            )}
            <AssetsList
              companySlug={companySlug || user?.company.slug}
              contentType={AssetContentType.productVideo}
              title={t('manage-products.product-videos')}
              iconName={IconsNames.play_arrow}
              productData={productData}
              isPreviewMode={isPreviewMode}
            />
            <AssetsList
              companySlug={companySlug || user?.company.slug}
              contentType={AssetContentType.advertiseVideo}
              title={t('manage-products.advertise-videos')}
              iconName={IconsNames.play_arrow}
              productData={productData}
              isPreviewMode={isPreviewMode}
            />
            <AssetsList
              companySlug={companySlug || user?.company.slug}
              contentType={AssetContentType.whitepaperEbook}
              title={t('manage-products.whitepaper')}
              iconName={IconsNames.book}
              productData={productData}
              isPreviewMode={isPreviewMode}
            />
            <AssetsList
              companySlug={companySlug || user?.company.slug}
              contentType={AssetContentType.caseStudy}
              title={t('manage-products.case-studies')}
              iconName={IconsNames.case_study}
              productData={productData}
              isPreviewMode={isPreviewMode}
            />
            <AssetsList
              companySlug={companySlug || user?.company.slug}
              contentType={AssetContentType.webinarOnDemand}
              title={t('manage-products.webinars-on-demand')}
              iconName={IconsNames.on_demand_video}
              productData={productData}
              isPreviewMode={isPreviewMode}
            />
          </div>

          <div className={styles.features}>
            <CompanyFeatures
              benefits={productData?.benefits}
              disadvantages={productData?.disadvantages}
            />

            {assetSlug && (
              <AssetShortcut
                assetTitle={contactData.asset_title}
                assetLogo={contactData.html_template_logo}
                assetSlug={assetSlug}
              />
            )}
          </div>
        </main>
        {productData?.privacy_policy_url && (
          <footer className={styles.footer}>
            <a
              href={productData?.privacy_policy_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('product.privacy-policy')}
            </a>
          </footer>
        )}
      </div>
      {isLoaderScreenVisible && <LoaderScreen />}
    </>
  );
};

export default ProductPage;
