import { useTable } from 'react-table';

import TableLayout from 'components/common/Table/TableLayout';

import type { TableOptions, PluginHook, Row } from 'react-table';

type Props = {
  wrapperClassName?: string;
  headClassName?: string;
  rowClassName?: string;
  tableOptions: TableOptions<{}>;
  plugins?: Array<PluginHook<{}>>;
  renderRowSubComponent?: ({ row }: { row: Row<{}> }) => JSX.Element;
  noDataComponent?: React.ReactNode;
  isFirstColumnFixed?: boolean;
  isLastColumnFixed?: boolean;
  isEvenRowGrey?: boolean;
  setSelectedRows?: React.Dispatch<React.SetStateAction<any>>;
};

const TableCommon = ({
  wrapperClassName,
  headClassName,
  rowClassName,
  tableOptions,
  plugins,
  renderRowSubComponent,
  noDataComponent,
  isFirstColumnFixed = false,
  isLastColumnFixed = false,
  isEvenRowGrey,
}: Props) => {
  const tableProps = useTable(tableOptions, ...(plugins || []));

  return (
    <TableLayout
      tableProps={tableProps}
      wrapperClassName={wrapperClassName}
      headClassName={headClassName}
      rowClassName={rowClassName}
      plugins={plugins}
      renderRowSubComponent={renderRowSubComponent}
      noDataComponent={noDataComponent}
      isFirstColumnFixed={isFirstColumnFixed}
      isLastColumnFixed={isLastColumnFixed}
      isEvenRowGrey={isEvenRowGrey}
    />
  );
};

export default TableCommon;
