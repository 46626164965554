import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TwoFaComponentProps } from 'components/Authorization/TwoFA/models';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import TwoFADescription from 'components/Authorization/TwoFA/TwoFADescription';
import TwoFAInput from 'components/Authorization/TwoFA/TwoFAInput';
import TwoFATitle from 'components/Authorization/TwoFA/TwoFATitle';
import TwoFABackLink from 'components/Authorization/TwoFA/TwoFABackLink';
import ResendCode from 'components/Authorization/TwoFA/ResendCode';

import useVerifyOTPCode from 'hooks/api/useVerifyOTPCode';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const TwoFAPhone = ({ device, onDeviceChange }: TwoFaComponentProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const { mutate: verify, isLoading, error } = useVerifyOTPCode();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    verify({
      device_id: device.persistent_id,
      token: value.replace(/ /g, ''),
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  return (
    <div className={styles.wrapper}>
      {onDeviceChange && <TwoFABackLink onDeviceChange={onDeviceChange} />}
      <TwoFATitle>{t('auth.we-sent-code-to-phone')}</TwoFATitle>
      <TwoFADescription text="auth.please-enter-2-factor-code" />
      <form onSubmit={handleSubmit}>
        <TwoFAInput value={value} onChange={handleInputChange} />
        <Button className={styles.button} darkBlue isBig>
          {t('common.button.confirm')}
        </Button>
        <Error message={error ? getResponseError(error) : ''} />
      </form>
      <ResendCode className={styles.button} device={device} isBigButton />

      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default TwoFAPhone;
