import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  isEnabled: boolean;
  isFailed: boolean;
};

const Status = ({ isEnabled, isFailed }: Props) => {
  const { t } = useTranslation();

  return (
    <p
      className={cn(styles.value, styles.status, {
        [styles.success]: isEnabled,
        [styles.failed]: isFailed,
      })}
    >
      {isFailed && t('integrations.status.failed')}
      {isEnabled && t('integrations.status.connected')}
    </p>
  );
};

export default Status;
