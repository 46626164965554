import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { SetStateAction, Dispatch } from 'react';

import { IconsNames, LogsTableAccessors } from 'constants/constants';
import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import styles from './index.module.scss';

type Props = {
  label: string;
  value: LogsTableAccessors;
  setOrderingList: Dispatch<SetStateAction<string[]>>;
  orderingList: string[];
};

const SortingHeader = ({
  label,
  value,
  setOrderingList,
  orderingList,
}: Props) => {
  const { t } = useTranslation();
  const tooltipProps = usePopperTooltip({ placement: 'top' });

  const currentValue = orderingList.find(
    item => item === value || item === `-${value}`
  );

  const isInOrderingList = !!currentValue;

  const index = currentValue ? orderingList.indexOf(currentValue) : -1;
  const isDescending = currentValue?.[0] === '-';

  const addToList = () => {
    if (isInOrderingList) {
      const sorted = orderingList.sort(a => (a === currentValue ? -1 : 1));
      setOrderingList([...sorted]);
    } else {
      setOrderingList(list => {
        return [value, ...list];
      });
    }
  };

  const toggleOrdering = () => {
    if (!isDescending) {
      const sorted = orderingList.map(item =>
        item === currentValue ? `-${item}` : item
      );

      setOrderingList(sorted);
    } else {
      const sorted = orderingList.map(item =>
        item === currentValue ? item.slice(1) : item
      );
      setOrderingList(sorted);
    }
  };

  const removeFromList = () => {
    if (index > -1) {
      setOrderingList(list => list.filter((item, ind) => ind !== index));
    }
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.label} onClick={addToList}>
        {label}
      </span>
      {!isInOrderingList && (
        <IconSVG name={IconsNames.sorting_arrows} onClick={addToList} />
      )}
      {isInOrderingList && (
        <>
          <span className={styles.index} onClick={addToList}>
            {index + 1}
          </span>
          <IconSVG
            name={IconsNames.long_arrow}
            onClick={toggleOrdering}
            className={cn(styles.arrow, {
              [styles.toBottom]: isDescending,
            })}
          />
          <IconSVG
            name={IconsNames.close}
            className={styles.remove}
            onClick={removeFromList}
            ref={tooltipProps.setTriggerRef}
          />

          {tooltipProps.visible && (
            <SimpleTooltip options={tooltipProps}>
              {t('hints.remove-from-sorting')}
            </SimpleTooltip>
          )}
        </>
      )}
    </div>
  );
};

export default SortingHeader;
