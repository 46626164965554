import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  UnsubscribeFormValues,
  UnsubscribeOption,
} from 'components/UnsubscribeContent/models';

import InputWrapper from 'components/common/InputWrapper';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';
import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

export const unsubscribeReasonsOptions = [
  {
    value: 'You send too many emails',
    label: 'unsubscribe.reason.too-many-emails',
  },
  {
    value: 'The email looks like spam',
    label: 'unsubscribe.reason.looks-like-spam',
  },
  {
    value: 'You send irrelevant content',
    label: 'unsubscribe.reason.irrelevant-content',
  },
  {
    value: '',
    label: 'common.field.other',
    isOther: true,
  },
];

type Props = { resetMutationState: () => void };

const Step2 = ({ resetMutationState }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    register,
    formState: { isValid },
    reset,
    getValues,
  } = useFormContext<UnsubscribeFormValues>();

  const handleGoBack = () => {
    resetMutationState();
    setValue('step', 0, { shouldValidate: true });
  };

  const isSnoozeOptionSelected =
    useWatch({
      control,
      name: 'unsubscribe_option',
    })?.value === UnsubscribeOption.snooze_receiving_emails;
  const isOtherReason = !!useWatch({
    control,
    name: 'isCustomReason',
  });
  const reason = useWatch({ control, name: 'reason' });

  return (
    <>
      <Button
        type="button"
        white
        onClick={handleGoBack}
        className={styles.back}
      >
        <IconSVG name={IconsNames.arrow} />
      </Button>
      <h2 className={styles.title}>{t('unsubscribe.last-action')}</h2>
      <p className={styles.description}>
        {t(
          isSnoozeOptionSelected
            ? 'unsubscribe.why-snoozed'
            : 'unsubscribe.why-unsubscribe'
        )}
        {isSnoozeOptionSelected && <span> ({t('common.field.optional')})</span>}
      </p>

      {!isSnoozeOptionSelected &&
        unsubscribeReasonsOptions.map(({ value, label, isOther }) => (
          <InputWrapper
            label={label}
            key={label}
            wrapperClasses={styles.option}
          >
            <input
              type="radio"
              value={value || ''}
              checked={isOther ? isOtherReason : reason === value}
              onChange={() => {
                reset({
                  ...getValues(),
                  reason: value,
                  isCustomReason: !!isOther,
                });
              }}
            />
          </InputWrapper>
        ))}
      {(isOtherReason || isSnoozeOptionSelected) && (
        <InputWrapper>
          <TextareaAutoHeight
            methods={register('reason')}
            control={control}
            placeholder={t('unsubscribe.enter-a-reason')}
          />
        </InputWrapper>
      )}

      <Button type="submit" className={styles.button} disabled={!isValid}>
        {t(
          isSnoozeOptionSelected
            ? 'common.button.snooze-newsletter'
            : 'common.button.unsubscribe'
        )}
      </Button>
    </>
  );
};

export default Step2;
