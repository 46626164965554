import cn from 'classnames';

import type { ProductAssetResponse } from 'types/models';

import DownloadForm from 'components/Product/AssetDownloadContent/DownloadForm';

import defaultImage from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  isModalContent?: boolean;
  setIsVideoPreview: React.Dispatch<React.SetStateAction<boolean>>;
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
};

const AssetContentPreview = ({
  data,
  isModalContent,
  setIsVideoPreview,
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
}: Props) => {
  const isAssetAvailable = data.attached_file || data.attached_file_link;

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.skipShadow]: isModalContent,
        })}
      >
        <div className={styles.row}>
          {!!data.html_template_logo && (
            <img src={data.html_template_logo} className={cn(styles.logo)} />
          )}
          <div className={styles.saved}>
            <img
              src={data.landing_image || defaultImage}
              alt={data.title}
              className={cn(styles.preview, {
                [styles.default]: !data.landing_image,
              })}
            />
          </div>
        </div>

        {((Array.isArray(data.script_builder_questions) && !isAssetAvailable) ||
          isAssetAvailable) && (
          <DownloadForm
            data={data}
            setIsVideoPreview={setIsVideoPreview}
            isModalContent={isModalContent}
            companySlug={companySlug}
            productSlug={productSlug}
            assetSlug={assetSlug}
            contactShareId={contactShareId}
            isAssetAvailable={!!isAssetAvailable}
          />
        )}
      </div>
    </>
  );
};

export default AssetContentPreview;
