import { useTranslation } from 'react-i18next';

import type { CampaignAssetFileResponse } from 'types/models';

import Button from 'components/common/Button';

import ScriptItem from 'components/CampaignManage/AssetsSection/ScriptItem';

import useAuth from 'contexts/AuthContext';

import { downloadFile } from 'helpers/files';
import getLongStringView from 'helpers/getLongStringView';
import { getAssetLink } from 'helpers/assets';

import generateHtmlTemplate from 'utils/generateHtmlTemplate';

import styles from './index.module.scss';

type Props = {
  data: CampaignAssetFileResponse;
  campaignId?: number;
  campaignName?: string;
};

const AssetsItem = ({ data, campaignId, campaignName }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const templateHTML =
    user &&
    typeof data.html_template_logo === 'string' &&
    typeof data.html_template_image === 'string' &&
    generateHtmlTemplate({
      logoSrc: data.html_template_logo,
      imageSrc: data.html_template_image,
      content: data.html_template_content_para,
      footerContent: data.html_template_footer_content,
      heading: data.html_template_heading,
      subHeading: data.html_template_sub_heading,
      privacyLink: data.html_template_privacy_value || '',
      companySlug: data.company_slug,
      assetSlug: data.slug,
      productSlug: data.product_slug,
      assetType: data.asset_type,
      hasDefaultUnsubscribeMessage: true,
      campaignId,
      campaignName,
    });

  const handleCopyScript = () => {
    if (templateHTML) {
      downloadFile(
        templateHTML,
        'text/html',
        `${data.html_template_company_name}.html`
      );
    }
  };

  const assetLink = getAssetLink(data);

  return (
    <li className={styles.wrapper}>
      <p className={styles.name}>{data.title}</p>
      <div className={styles.buttons}>
        <a
          className={styles.link}
          target="_blank"
          rel="noreferrer noreferrer"
          href={assetLink}
          download={data.attached_file_name}
        >
          <Button type="button">
            {getLongStringView(
              data.attached_file_name ||
                data.attached_file_link ||
                data.product_name ||
                '',
              20
            )}
          </Button>
        </a>
        <Button type="button" onClick={handleCopyScript}>
          {t('campaign.download-html')}
        </Button>

        <ScriptItem assetId={data.id} />
      </div>
    </li>
  );
};

export default AssetsItem;
