import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';
import { SelectOption } from 'types/models';

import Button from 'components/common/Button';

import styles from './index.module.scss';

type Props = {
  options: string[];
  setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
  category: string;
  searchParams: SelectOption[];
  setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
  translationPrefix?: string;
};

const StaticOptions = ({
  options,
  setSearchParams,
  category,
  searchParams,
  setIsOptionsListVisible,
  translationPrefix,
}: Props) => {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
    searchParams || []
  );

  const handleInputChange = (value: SelectOption) => {
    if (selectedOptions.some(item => item.value === value.value)) {
      setSelectedOptions(
        selectedOptions.filter(item => item.value !== value.value)
      );
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const onClickApply = () => {
    if (selectedOptions.length) {
      setSearchParams(prev => {
        return {
          ...prev,
          [category]: selectedOptions,
        };
      });
    } else {
      setSearchParams(prev => {
        const updatedParams = { ...prev };
        delete updatedParams[category];
        return updatedParams;
      });
    }
    setIsOptionsListVisible(false);
  };

  return (
    <>
      <ul>
        {options.map(item => {
          const isChecked = selectedOptions.some(
            selectedItem => selectedItem.value === item
          );
          return (
            <label key={item} htmlFor={item} className={styles.option}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => handleInputChange({ label: item, value: item })}
                id={item}
              />
              {translationPrefix ? t(`${translationPrefix}${item}`) : item}
            </label>
          );
        })}
      </ul>
      <Button className={styles.apply} white onClick={onClickApply}>
        {t('common.button.apply')}
        {!!selectedOptions.length && <span>({selectedOptions.length})</span>}
      </Button>
    </>
  );
};

export default StaticOptions;
