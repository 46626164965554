import { useTranslation } from 'react-i18next';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';
import { UnsubscribeOption } from 'components/UnsubscribeContent/models';

import styles from './index.module.scss';

type Props = {
  unsubscribeOption?: UnsubscribeOption;
};

const SuccessScreen = ({ unsubscribeOption }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <IconSVG
        name={IconsNames.success_checkmark}
        className={styles.checkmark}
      />
      <h2 className={styles.message}>
        {!unsubscribeOption && t('unsubscribe.already-unsubscribed-message')}
        {unsubscribeOption &&
          t(
            unsubscribeOption === UnsubscribeOption.snooze_receiving_emails
              ? 'unsubscribe.snooze-success-message'
              : 'unsubscribe.unsubscribe-success-message'
          )}
      </h2>
    </>
  );
};

export default SuccessScreen;
