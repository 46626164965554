import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import type { AxiosError } from 'axios';
import type { ProductAssetResponse } from 'types/models';

import { CommonPaths } from 'constants/routes';

export const useGetPublicAsset = ({
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
}: {
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
}) => {
  const navigate = useNavigate();

  const query = useQuery<ProductAssetResponse, AxiosError>(
    ['asset-public', companySlug, productSlug, assetSlug],
    async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}${
            contactShareId ? `/shares/${contactShareId}` : ''
          }/companies/${companySlug}/products/${productSlug}/assets/${assetSlug}/`
        );

        return response.data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: Boolean(companySlug && productSlug && assetSlug),
      onError: () => {
        const pathPrefix = contactShareId
          ? `/${CommonPaths.SHARE}/${contactShareId}`
          : '';
        navigate(`${pathPrefix}/${companySlug}/${productSlug}`);
      },
    }
  );

  return query;
};
