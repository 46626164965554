import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { ProductAssetResponse } from 'types/models';

import AssetModal from 'components/Product/ProductPage/AssetModal';

import useModal from 'contexts/ModalContext';

import defaultImage from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  companySlug?: string;
  productSlug?: string;
};

const DESCRIPTION_MAX_DISPLAY_LENGTH = 157;

const AssetItem = ({ data, companySlug, productSlug }: Props) => {
  const { t } = useTranslation();
  const { contactShareId } = useParams();
  const { openModal } = useModal();
  const { landing_image: thumbnail, title, type, description, slug } = data;

  const handleOpenAssetModal = () => {
    if (!companySlug || !productSlug) return;
    openModal({
      Content: (
        <AssetModal
          companySlug={companySlug}
          productSlug={productSlug}
          assetSlug={slug}
          contactShareId={contactShareId}
        />
      ),
    });
  };

  const assetDescription =
    description.length > DESCRIPTION_MAX_DISPLAY_LENGTH
      ? `${description.slice(0, DESCRIPTION_MAX_DISPLAY_LENGTH)}...`
      : description;

  return (
    <li
      className={styles.wrapper}
      role="presentation"
      onClick={handleOpenAssetModal}
    >
      <img src={thumbnail || defaultImage} />
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.type}>{t(`common.field.${type}`)}</p>
      <p className={styles.description}>{assetDescription}</p>
    </li>
  );
};

export default AssetItem;
