import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import { IconsNames, IntegrationsLogStatuses } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  status: IntegrationsLogStatuses;
  failReason?: string;
  className?: string;
};

const LogStatus = ({ status, failReason, className }: Props) => {
  const { t } = useTranslation();

  const tooltipProps = usePopperTooltip({ placement: 'top' });

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.wrapper}>
        <span
          className={cn(styles.label, {
            [styles.inProgress]:
              status === IntegrationsLogStatuses['in-progress'],
            [styles.failed]: status === IntegrationsLogStatuses.failed,
            [styles.queued]: status === IntegrationsLogStatuses.created,
            [styles.succeed]: status === IntegrationsLogStatuses.succeed,
          })}
        >
          {t(`integrations.statuses.${status}`)}
        </span>
        {failReason && (
          <div>
            <IconSVG
              name={IconsNames.help}
              className={styles.help}
              ref={tooltipProps.setTriggerRef}
            />
            {tooltipProps.visible && (
              <SimpleTooltip className={styles.tooltip} options={tooltipProps}>
                {failReason}
              </SimpleTooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LogStatus;
