import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';
import type { MutateOptions } from 'react-query';
import {
  CreatePaymentPayload,
  TransactionData,
  CheckoutOptions,
  PaymentMethodName,
} from 'components/PaymentsComponents/models';

import InvoiceEmailModal from 'components/PaymentsComponents/InvoiceEmailsModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

type Options = {
  isSkipBalanceModal?: boolean;
  checkoutOptions?: Partial<CheckoutOptions>;
  payload?: CreatePaymentPayload;
};

const defaultCheckoutOptions = {
  onClose: () => {},
  onCancel: () => {},
  onSuccess: () => {},
  onError: () => {},
};

const useCreatePayment = () => {
  const { axios } = useAuth();
  const { closeModal, openModal } = useModal();

  const createPaymentMutation = useMutation<
    TransactionData & Options,
    AxiosError,
    CreatePaymentPayload & Options
  >(
    async payload => {
      try {
        const paymentData = {
          ...payload,
          line_items: payload.line_items || [
            { title: 'Deposit funds', quantity: 1, price: payload.amount },
          ],
        };
        delete paymentData.isSkipBalanceModal;
        delete paymentData.checkoutOptions;
        if (!Number(paymentData.discount)) {
          delete paymentData.discount;
        }

        const { data } = await axios.post<TransactionData>(
          '/payments/create-payment/',
          paymentData
        );

        return {
          ...data,
          isSkipBalanceModal: payload.isSkipBalanceModal,
          checkoutOptions: payload.checkoutOptions,
          payload,
        };
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        closeModal();
        if (!data.isSkipBalanceModal) {
          const allowedPaymentMethods = [];
          if (data.payload?.method) {
            allowedPaymentMethods.push(data.payload.method);
          }
          if (data.payload?.terms_method) {
            allowedPaymentMethods.push(data.payload?.terms_method);
          }

          window.balanceSDK?.checkout.init({
            ...defaultCheckoutOptions,
            ...data.checkoutOptions,
            allowedPaymentMethods,
          });
          window.balanceSDK?.checkout.render(data.token, '#balance-checkout');
        }
      },
    }
  );

  const handleCreatePayment = (
    payload: CreatePaymentPayload & Options,
    mutationOptions?: MutateOptions<
      TransactionData & Options,
      AxiosError,
      CreatePaymentPayload & Options
    >
  ) => {
    if (payload.terms_method === PaymentMethodName.invoice) {
      return openModal({
        Content: (
          <InvoiceEmailModal
            onSubmit={emails => {
              closeModal();
              createPaymentMutation.mutate(
                { ...payload, emails_cc: emails },
                mutationOptions
              );
            }}
          />
        ),
      });
    }

    createPaymentMutation.mutate(payload, mutationOptions);
  };

  return {
    ...createPaymentMutation,
    mutate: handleCreatePayment,
  };
};

export default useCreatePayment;
