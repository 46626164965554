import { useTranslation } from 'react-i18next';
import { useFormContext, useController, useFieldArray } from 'react-hook-form';
import { useRef } from 'react';
import { ReactEditor, withReact } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { useParams } from 'react-router-dom';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import BlockHeading from 'components/CreateCampaign/Step1/ScriptBuilder/BlockHeading';
import FocusedWrapper from 'components/RichText/FocusedWrapper';
import Introduction from 'components/CreateCampaign/Step1/ScriptBuilder/Introduction';
import Asset from 'components/CreateCampaign/Step1/ScriptBuilder/Asset';
import CASL from 'components/CreateCampaign/Step1/ScriptBuilder/CASL';
import GDPR from 'components/CreateCampaign/Step1/ScriptBuilder/GDPR';
import Closing from 'components/CreateCampaign/Step1/ScriptBuilder/Closing';
import Question from 'components/CreateCampaign/Step1/ScriptBuilder/Question';
import SelectCompliance from 'components/CreateCampaign/Step1/ScriptBuilder/SelectCompliance';
import LoaderScreen from 'components/common/LoaderScreen';

import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';
import RichText from 'components/RichText';

import { useGetCampaignScript } from 'hooks/api/useGetCampaignScript';
import useConvertCampaignData from 'hooks/useConvertCampaignData';
import { useDeleteQuestion } from 'components/CreateCampaign/Step1/ScriptBuilder/useDeleteQuestion';

import { withCustomElements } from 'utils/richTextEditor';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';
import { emptyTemplate, emptyQuestion } from 'constants/scriptTemplates';

import styles from './index.module.scss';

type Props = {
  isPreviewMode?: boolean;
};

const ScriptBuilder = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { campaignId: id } = useParams();

  const { control, setValue, getValues, formState } = formSettings || {};

  const { convertScriptResponse } = useConvertCampaignData();

  const { mutate: deleteQustion, isLoading: isDeletingQuestion } =
    useDeleteQuestion();

  const assetEditorRef = useRef<ReactEditor>();
  const noteEditorRef = useRef<ReactEditor>(
    withCustomElements(withHistory(withReact(createEditor())))
  );

  const campaignId = getValues ? getValues().id : id;
  const { isLoading, isSuccess, error, data, isRefetching } =
    useGetCampaignScript({
      campaignId,
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onSettled: response => {
        if (isPreviewMode) return;
        if (response) {
          const convertedResponse = convertScriptResponse(response);
          setValue('script_builder', convertedResponse);
          setValue('script_builder.questions', convertedResponse.questions);
          setValue('script_builder.notes_block', convertedResponse.notes_block);
        }
      },
    });

  const { field: noteField } = !isPreviewMode
    ? useController({
        name: 'script_builder.notes_block',
        control,
      })
    : { field: null };

  const {
    append,
    fields: questions,
    remove,
  } = control
    ? useFieldArray({
        name: 'script_builder.questions',
        control: control || {},
        keyName: 'key',
      })
    : { fields: null, append: null, remove: null };
  const handleDeleteNotes = () =>
    setValue('script_builder.notes_block', undefined);

  const handleAddQuestion = () => {
    append?.(emptyQuestion);
  };

  const handleAddNotes = () => {
    setValue('script_builder.notes_block', emptyTemplate);
    setTimeout(() => {
      ReactEditor.focus(noteEditorRef.current);
    }, 0);
  };

  const submitError = formState?.errors?.script_builder?.message;
  const questionsData = isPreviewMode ? data?.questions : questions;
  const errorMessage = error ? getResponseError(error) : submitError;
  const isNotesVisible = isPreviewMode ? !!data?.notes_block : noteField?.value;

  const handleDeleteQuestions = () => {
    remove?.();
    questionsData?.forEach(question => {
      if (question.id && campaignId) {
        deleteQustion({ campaignId, questionId: question.id });
      }
    });
  };

  return (
    <>
      {!isPreviewMode && (
        <BlockHeader
          heading={t('campaign.script-builder')}
          description={t('campaign.manage-your-script')}
          iconClassName={styles.icon}
          iconName={IconsNames.text_snippet}
        />
      )}
      {errorMessage && (
        <Error message={errorMessage} className={styles.error} />
      )}
      {isSuccess && !isRefetching && (
        <>
          <BlockWrapper
            iconName={IconsNames.settings}
            title={t('profile.settings')}
            className={styles.block}
          >
            <div className={styles.settings}>
              <div className={styles.type}>
                <p>{t('script.compliance')}</p>
                <SelectCompliance
                  isPreviewMode={isPreviewMode}
                  isCASLCompliant={data.is_casl_compliant}
                  isGDPRCompliant={data.is_gdpr_compliant}
                />
              </div>
            </div>
          </BlockWrapper>
          <BlockWrapper className={styles.script}>
            <Introduction
              htmlString={
                isPreviewMode ? data.introduction_block || ' ' : undefined
              }
            />
            <Asset
              assetEditorRef={assetEditorRef}
              htmlString={isPreviewMode ? data.asset_block || ' ' : undefined}
            />
            <CASL
              htmlString={
                isPreviewMode ? data.compliance_casl_block || '' : undefined
              }
            />
            <GDPR
              htmlString={
                isPreviewMode ? data.compliance_gdpr_block || '' : undefined
              }
            />

            {!!questionsData?.length && (
              <>
                <BlockHeading
                  iconName={IconsNames.help_outline}
                  onDelete={!isPreviewMode ? handleDeleteQuestions : undefined}
                  sectionName={t('script.questions')}
                >
                  {t('script.qualifying-questions')}
                </BlockHeading>
                <ul>
                  {questionsData?.map((question, index) => {
                    const isFormField = 'key' in question;

                    return (
                      <FocusedWrapper
                        key={isFormField ? question.key : question.id}
                        defaultFocused={
                          'shouldFocus' in question
                            ? question.shouldFocus
                            : false
                        }
                        aria-label="script question"
                        focusClassName={styles.question}
                        isPreviewMode={isPreviewMode}
                      >
                        {props => (
                          <Question
                            key={question.id}
                            id={question.id}
                            index={index}
                            remove={remove}
                            append={append}
                            dataForPreview={isFormField ? undefined : question}
                            {...props}
                          />
                        )}
                      </FocusedWrapper>
                    );
                  })}
                </ul>
              </>
            )}

            {isNotesVisible && (
              <>
                <BlockHeading
                  iconName={IconsNames.notes}
                  onDelete={isPreviewMode ? undefined : handleDeleteNotes}
                  sectionName={t('script.notes')}
                >
                  {t('script.note')}
                </BlockHeading>
                <FocusedWrapper isPreviewMode={isPreviewMode}>
                  {props =>
                    isPreviewMode ? (
                      <RichText htmlString={data.notes_block || ''} />
                    ) : (
                      <RichText
                        value={noteField?.value}
                        onChange={val => noteField?.onChange(val)}
                        editorRef={noteEditorRef}
                        placeholder={t('script.note')}
                        {...props}
                      />
                    )
                  }
                </FocusedWrapper>
              </>
            )}

            <Closing
              htmlString={isPreviewMode ? data.closing_block || ' ' : undefined}
            />

            {!isPreviewMode && (
              <div className={styles.actions}>
                {!questions?.length && (
                  <Button
                    iconProps={{ name: IconsNames.plus }}
                    white
                    onClick={handleAddQuestion}
                    aria-label="add qualifying question"
                    type="button"
                  >
                    {t('script.qualifying-questions')}
                  </Button>
                )}
                {!noteField?.value && (
                  <Button
                    iconProps={{ name: IconsNames.plus }}
                    white
                    onClick={handleAddNotes}
                    aria-label="add note"
                    type="button"
                  >
                    {t('script.note')}
                  </Button>
                )}
              </div>
            )}
          </BlockWrapper>
        </>
      )}

      {(isLoading || isRefetching) && <Loader className={styles.loading} />}
      {isDeletingQuestion && <LoaderScreen />}
    </>
  );
};

export default ScriptBuilder;
