import type { DropTargetMonitor } from 'react-dnd';

export const moveSortedItems = <ItemType extends { index: number }>({
  item,
  monitor,
  currentIndex,
  itemRef,
  onMove,
  isSkipYCheck,
}: {
  item: ItemType;
  monitor: DropTargetMonitor<ItemType>;
  currentIndex: number;
  itemRef: React.RefObject<HTMLElement>;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  isSkipYCheck?: boolean;
}) => {
  if (!monitor?.getClientOffset()) return;

  const dragIndex = item.index;
  const hoverIndex = currentIndex;
  if (dragIndex === hoverIndex) {
    return;
  }
  const hoverBoundingRect = itemRef.current?.getBoundingClientRect();
  if (!hoverBoundingRect) return;
  if (!isSkipYCheck) {
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverActualY =
      Number(monitor?.getClientOffset()?.y) - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
    if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
  }

  onMove(dragIndex, hoverIndex);
  item.index = hoverIndex;
};
