import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import cn from 'classnames';

import type { TwoFaComponentProps } from 'components/Authorization/TwoFA/models';

import TwoFATitle from 'components/Authorization/TwoFA/TwoFATitle';
import TwoFABackLink from 'components/Authorization/TwoFA/TwoFABackLink';
import TwoFAInput from 'components/Authorization/TwoFA/TwoFAInput';
import TwoFADescription from 'components/Authorization/TwoFA/TwoFADescription';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import useVerifyOTPCode from 'hooks/api/useVerifyOTPCode';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const TwoFAApp = ({ device, onDeviceChange }: TwoFaComponentProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const { mutate: verify, isLoading, error } = useVerifyOTPCode();
  const [, copyToClipboard] = useCopyToClipboard();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    verify({
      device_id: device.persistent_id,
      token: value.replace(/ /g, ''),
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  const configURL = 'config_url' in device && new URL(device.config_url);
  const secretKey = configURL && configURL.searchParams.get('secret');

  const handleCopyClick = () => {
    if (secretKey) {
      copyToClipboard(secretKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <div className={styles.wrapper}>
      {onDeviceChange && <TwoFABackLink onDeviceChange={onDeviceChange} />}
      {'config_url' in device ? (
        <TwoFATitle>{t('auth.scan-qr-code')}</TwoFATitle>
      ) : (
        <TwoFADescription text="auth.please-enter-2-factor-code" />
      )}
      {'config_url' in device && (
        <>
          <div className={styles.qrcode}>
            <QRCodeSVG
              value={device.config_url}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
              includeMargin={false}
            />
          </div>
          <p className={styles.link}>
            <span>{secretKey}</span>
            <button
              type="button"
              className={cn(styles.copy, { [styles.success]: isCopied })}
              onClick={handleCopyClick}
            >
              <IconSVG name={IconsNames.copy_success} />
              {!!isCopied && (
                <span className={styles.copied}>
                  {t('common.button.copied')}
                </span>
              )}
            </button>
          </p>
        </>
      )}
      <form>
        <TwoFAInput value={value} onChange={handleInputChange} />
        <Button
          className={styles.button}
          type="submit"
          onClick={handleSubmit}
          darkBlue
          isBig
        >
          {t('common.button.confirm')}
        </Button>
        <Error message={error ? getResponseError(error) : ''} />
      </form>

      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default TwoFAApp;
