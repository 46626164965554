import Select from 'react-select';
import { useFormContext, useController, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { SingleValue } from 'react-select';
import type { AxiosResponse } from 'axios';
import {
  ProductPricingPlanPeriods,
  ProductPricingPlanResponse,
  ProductPricingPlanMonthlyAnnuallyPeriods,
} from 'components/Product/models';
import { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';

import InputWrapper from 'components/common/InputWrapper';
import CheckmarkOption from 'components/UI/ReactSelect/CheckmarkOption';

import PriceDetails from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/Pricing/PriceDetails';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { CURRENCY_LIST, POPULAR_CURRENCIES } from 'constants/currencies';

import styles from './index.module.scss';

type Props = {
  submitErrors?: AxiosResponse<ProductPricingPlanResponse>;
};

const currencyOptions = [
  ...new Set([...POPULAR_CURRENCIES, ...CURRENCY_LIST]),
].map(code => ({
  value: code,
  label: code,
}));

const Pricing = ({ submitErrors }: Props) => {
  const { t } = useTranslation();
  const translateOptions = useTranslateSelectOptions();
  const { control, getValues } = useFormContext<ProductPricingPlanFormValues>();
  const { fields: periodDetails, replace } = useFieldArray({
    control,
    name: 'period_details',
  });

  const periodOptions = translateOptions<ProductPricingPlanPeriods>(
    Object.values(ProductPricingPlanPeriods)
  );
  const { field: currencyField } = useController({
    control,
    name: 'currency',
    defaultValue: currencyOptions[0],
  });
  const { field: periodField } = useController({
    control,
    name: 'period',
    defaultValue: periodOptions[0],
  });

  const handleChangePeriod = (
    newValue: SingleValue<ProductPricingPlanFormValues['period']>
  ) => {
    if (getValues('period').value === newValue?.value) return;

    const periodDefaultValues = {
      price: '',
      link: '',
      link_title: '',
      isFree: false,
      isChecked: true,
    };

    if (newValue?.value === ProductPricingPlanPeriods['monthly-or-annually']) {
      replace([
        {
          name: ProductPricingPlanMonthlyAnnuallyPeriods.monthly,
          ...periodDefaultValues,
        },
        {
          name: ProductPricingPlanMonthlyAnnuallyPeriods.annual,
          ...periodDefaultValues,
        },
      ]);
    } else {
      replace({
        name:
          newValue?.value === ProductPricingPlanPeriods.custom
            ? ''
            : newValue?.value || '',
        ...periodDefaultValues,
      });
    }

    periodField.onChange(newValue);
  };

  return (
    <fieldset className={styles.pricing}>
      <legend>{t('manage-products.pricing')}</legend>
      <Select
        options={currencyOptions}
        {...currencyField}
        styles={getDefaultSelectStylesWithError({ isSmall: true })}
        className={styles.currency}
      />

      <InputWrapper label="common.field.option">
        <Select<ProductPricingPlanFormValues['period']>
          components={{ Option: CheckmarkOption }}
          options={periodOptions}
          {...periodField}
          onChange={handleChangePeriod}
          styles={getDefaultSelectStylesWithError({ isSmall: true })}
          isSearchable={false}
        />
      </InputWrapper>
      {periodDetails.map((period, index) => (
        <PriceDetails
          index={index}
          key={period.id}
          name={period.name}
          submitErrors={submitErrors}
        />
      ))}
    </fieldset>
  );
};

export default Pricing;
