import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

import { timezones } from 'constants/selectOptions';

dayjs.extend(dayjsUTC);

export const createAddToCalendarLink = ({
  timeslot,
  timezone,
  leadCompanyName,
  companyName,
}: {
  timeslot: string;
  timezone: string;
  leadCompanyName: string;
  companyName: string;
}) => {
  const eventStartDate = dayjs.utc(timeslot).format('YYYYMMDDTHHmmss');
  const eventEndDate = dayjs
    .utc(timeslot)
    .add(30, 'm')
    .format('YYYYMMDDTHHmmss');

  const timezoneCode = timezones.find(zone => zone.value === timezone)?.code;

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    `${companyName} <> ${leadCompanyName}`
  )}&dates=${eventStartDate}%2F${eventEndDate}&ctz=${timezoneCode}`;
};
