import PageContainer from 'components/common/PageContainer';
import ManageProductsContent from 'components/Product/ManageProductsContent';

const ManageProducts = () => {
  return (
    <PageContainer path={['manage-products.heading']}>
      <ManageProductsContent />
    </PageContainer>
  );
};

export default ManageProducts;
