import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';

import type { DepositFormValues } from 'components/PaymentsComponents/models';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import SelectPaymentMethod from 'components/PaymentsComponents/SelectPaymentMethod';

import useCreatePayment from 'hooks/api/useCreatePayment';
import useGetExpectedPrice from 'components/PaymentsComponents/useGetExpectedPrice';

import getResponseError from 'helpers/getResponseError';
import { getNumberWithCommas } from 'helpers/formatNumbers';

import styles from './index.module.scss';

type Props = {
  amount?: string | number;
};

const DepositCountModal = ({ amount }: Props) => {
  const { t } = useTranslation();
  const { isLoading, error, mutate } = useCreatePayment();

  const {
    mutation: {
      isLoading: isLoadingPrice,
      error: getPriceError,
      data: expectedAmount,
    },
    formMethods: {
      control,
      watch,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
    },
    formatPayload,
  } = useGetExpectedPrice(amount);
  const { field } = useController({ control, name: 'amount' });

  const handleFormSubmit = (data: DepositFormValues) =>
    mutate(formatPayload(data));

  return (
    <ModalWindow
      primaryTitle={t('payments.deposit-funds')}
      className={styles.wrapper}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputWrapper
          label={t('payments.how-much-deposit')}
          validationError={errors?.amount?.message}
          submitError={error ? getResponseError(error) : undefined}
        >
          <NumberField
            field={field}
            placeholder={t('common.field.deposit-amount')}
            hasCommas
            prefix="$"
            className={styles.amount}
            disabled={!!amount}
          />
        </InputWrapper>
        <SelectPaymentMethod
          control={control}
          watch={watch}
          setValue={setValue}
          methodError={errors.method?.message}
          daysCountError={errors.term?.message}
        />
        <div className={styles.expected}>
          <p>
            {t('common.field.total')}
            <span>
              {expectedAmount
                ? `$${getNumberWithCommas(expectedAmount.amount)}`
                : '-'}
            </span>
          </p>
          <p>
            {t('common.field.processing-fee')}
            <span>
              {expectedAmount && !isLoading
                ? `$${getNumberWithCommas(expectedAmount.processing_fee)}`
                : '-'}
            </span>
          </p>
          {isLoadingPrice && <Loader size={24} />}
        </div>
        {getPriceError && <Error message={getResponseError(getPriceError)} />}

        <Button type="submit" className={styles.submit} disabled={!isValid}>
          {t('common.button.checkout')}
        </Button>
      </form>
      {isLoading && <LoaderScreen />}
    </ModalWindow>
  );
};

export default DepositCountModal;
