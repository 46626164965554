import { useNavigate } from 'react-router-dom';

import SideMenu from 'components/DocumentsContent/PrivacyNotice/SideMenu';
import ScrollToTopButton from 'components/DocumentsContent/ScrollToTopButton';

import { CommonPaths } from 'constants/routes';
import { PrivacyNoticeIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const PrivacyNotice = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.main}>
      <SideMenu />
      <div className={styles.content_container}>
        <h1 className={styles.content_header}>BeeLeads Privacy Notice</h1>
        <div className={styles.noteWrapper}>
          Effective Date:
          <span>August 2, 2022</span>
        </div>

        <div className={styles.chapter} id={PrivacyNoticeIDs.introduction}>
          <h2 className={styles.subheading}>I. Introduction</h2>
          <p>
            The BeeLeads platform available at{' '}
            <a href="https://dashboard.beeleads.ai" className={styles.link}>
              https://dashboard.beeleads.ai
            </a>
            , including any of its subdomains (the
            <span className={styles.semibold}> “Platform”</span>) is operated by
            Leadforce AI Inc. (<span className={styles.semibold}> “we”</span>,
            <span className={styles.semibold}> “our”</span>, or
            <span className={styles.semibold}> “BeeLeads”</span>). With respect
            to personal data collected when you access and use the Platform, we
            act as a data controller, meaning that we determine the purposes and
            means of processing your personal data.
          </p>
          <p>
            This Privacy Notice sets out the types of personal data that we hold
            on you, how we collect and process such data, how long we keep it,
            and other relevant information about your personal data being
            processed in connection with your access to and use of the Platform.
            Personal data or personal information means any information directly
            or indirectly identifies you as an individual. In this Privacy
            Notice we use “personal data” and “personal information” as
            synonyms.
          </p>
          <p>
            We process personal data in accordance with this Privacy Notice and
            we endeavor to comply with the applicable data protection
            legislation.
          </p>
          <p>
            If you have any questions regarding the processing of your personal
            data, do not hesitate to contact us via the contact details provided
            below.
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.contact_details}>
          <h2 className={styles.subheading}>II. Contact Details</h2>
          <p>Name: Leadforce AI Inc., a Delaware corporation.</p>
          <p>
            Address: 2055 Limestone Rd STE 200-C, Wilmington, in the County of
            New Castle, Delaware, USA.
          </p>
          <p>
            Email:{' '}
            <a href="mailto: legal@leadforce.ai" className={styles.link}>
              legal@leadforce.ai
            </a>
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.our_principles}>
          <h2 className={styles.subheading}>III. Our Principles</h2>
          <p>
            <span className={styles.italic}>Lawfulness: </span>
            we endeavor to process personal data in accordance with the
            applicable data protection legislation.
          </p>
          <p>
            <span className={styles.italic}>Transparency: </span>
            we do our best to make the processing activities transparent and
            understandable for you, including by providing you with all
            reasonably necessary information regarding the processing.
          </p>
          <p>
            <span className={styles.italic}> Purpose Limitation: </span>
            we process your data only for the purposes it was collected. If we
            establish any other purpose, we will inform you reasonably in
            advance.
          </p>
          <p>
            <span className={styles.italic}>Control: </span>
            we try to give you as much control over your data as reasonably
            feasible taking into account the available functionality.
          </p>
          <p>
            <span className={styles.italic}>
              Confidentiality, Integrity, and Availability:{' '}
            </span>
            we try to comply with the best practices applicable to the
            development and maintenance of the security systems.
          </p>
          <p>
            <span className={styles.italic}>Disclosure Accountability: </span>
            if we disclose personal data to any person, we will do our best to
            ensure that such person will comply with the terms of the applicable
            legislation and this Privacy Notice.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={PrivacyNoticeIDs.what_data_we_collect}
        >
          <h2 className={styles.subheading}>IV. What Data We Collect</h2>
          <p>
            The categories of personal data we collect depend on how you
            interact with us, use the Platform, and the requirements of
            applicable legislation. Within the Platform we collect and process
            the following types of personal data:
          </p>
          <p className={styles.semibold}>A. Account Data</p>
          <p>
            In order to register an account with the Platform, we collect the
            following data:
          </p>
          <p>
            <span className={styles.semibold}>a&#041; </span>
            your full name;
          </p>
          <p>
            <span className={styles.semibold}>b&#041; </span>
            email address;
          </p>
          <p>
            <span className={styles.semibold}>c&#041; </span>
            phone number;
          </p>
          <p>
            <span className={styles.semibold}>d&#041; </span>
            company name and address information, including country of
            residence, city, ZIP code, name of the street, number of building;
          </p>
          <p>
            <span className={styles.semibold}>e&#041; </span>
            job title and department;
          </p>
          <p>
            <span className={styles.semibold}>f&#041; </span>
            password.
          </p>
          <p>
            When an account is created, we assign a unique ID to each user
            account. The ID is technically necessary to operate the Platform.
            The unique ID is a random internal identification number
            automatically assigned to each user account. The ID is used for
            internal purposes to count the users. Without any other pieces of
            data, the ID does not identify you as an individual.
          </p>
          <p>
            Platform users are able to invite new users to register an account
            with the Platform. In this case, a registered user may provide the
            following personal data of an invited user:
          </p>
          <p>
            <span className={styles.semibold}>a&#041; </span>
            email address; or
          </p>
          <p>
            <span className={styles.semibold}>b&#041; </span>
            full name;
          </p>
          <p>
            <span className={styles.semibold}>c&#041; </span>
            country of residence;
          </p>
          <p>
            <span className={styles.semibold}>d&#041; </span>
            email address; and
          </p>
          <p>
            <span className={styles.semibold}>e&#041; </span>
            phone number.
          </p>
          <p>
            We will use the above data in order to (I) invite the user to create
            an account with the Platform and (II) create an account of the
            invited user with the Platform.
          </p>
          <p className={styles.semibold}>B. Payment Data</p>
          <p>
            When you make payments to us or receive payments from us in relation
            to the Platform, the respective payment service provider will
            collect certain financial information provided by you and further
            obtained in connection with conducting the respective payments, such
            as (a) account details or card number, including Card Verification
            Value (generally known as CVV), (b) sender and recipient details,
            (c) billing address, (d) transaction ID, type and amount, (e)
            transaction date and time, and (f) transaction status. We do not
            store all of the Payment Data, however, we store the information
            regarding the payment history, sender or recipient of the payment,
            transaction amount, date, and time.
          </p>
          <p className={styles.semibold}>C. Contact Data</p>
          <p>
            We may also collect certain data if (I) you reach us via the contact
            details indicated on the Platform, and/or (II) we communicate with
            you regarding the use of the Platform. In this case, we may collect
            and process certain information related to your request and/or our
            communications, such as email address, name, or any other data you
            choose to provide us. Please do not share with us the personal
            information which is not related to your request and our
            communications.
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.how_we_use_data}>
          <h2 className={styles.subheading}>V. How We Use Data</h2>
          <p className={styles.semibold}>A. General</p>
          <p>
            In addition to the use cases described below, we may use your
            personal data for the following purposes (if and where applicable):
          </p>
          <p>
            <span className={styles.semibold}>a&#041; </span>
            detecting security incidents, protecting against malicious,
            deceptive, fraudulent or illegal activity, and prosecuting those
            responsible for that activity;
          </p>
          <p>
            <span className={styles.semibold}>b&#041; </span>
            debugging and repairing errors with the Platform;
          </p>
          <p>
            <span className={styles.semibold}>c&#041; </span>
            ensuring security, integrity, and availability of the Platform;
          </p>
          <p>
            <span className={styles.semibold}>d&#041; </span>
            improving, upgrading, or enhancing the Platform;
          </p>
          <p>
            <span className={styles.semibold}>e&#041; </span>
            auditing relating to interactions, transactions, and other
            compliance activities;
          </p>
          <p>
            <span className={styles.semibold}>f&#041; </span>
            enforcing our agreements and policies;
          </p>
          <p>
            <span className={styles.semibold}>g&#041; </span>
            managing legal issues; and
          </p>
          <p>
            <span className={styles.semibold}>h&#041; </span>
            complying with the applicable laws.
          </p>
          <p className={styles.semibold}>B. Account Data</p>
          <p>
            We use the Account Data in order to (I) create an account with the
            Platform, count you as a Platform user, and enable you to access and
            use the Platform; and/or (II) enable you to invite others to use the
            Platform. This data is mandatory and necessary (I) for the
            performance of the contract between you and us, i.e., to provide you
            access to the Platform; and (II) in order to enable the invited
            users to create an account with the Platform.
          </p>
          <p className={styles.semibold}>C. Payment Data</p>
          <p>
            We collect the Payment Data in order to enable you, depending on the
            type of your Platform account, (I) to top up your balance on the
            Platform or (II) to withdraw funds from the Platform. In connection
            with the foregoing, we may engage a third-party payment service
            provider, which will process personal data on our behalf. However,
            the service provider may collect and process certain data on its own
            behalf and without our engagement. You may review the privacy
            practices of the third-party payment service provider in the privacy
            documentation provided at the point of data collection.
          </p>
          <p>
            The Payment Data is mandatory and necessary for the performance of
            the contract between you and us, i.e., enabling you to make or
            receive payments in connection with the Platform. Further storage is
            necessary to (I) comply with the applicable legislation, (II)
            protect the property and rights of BeeLeads or a third party, (III)
            protect the safety of the public or any person, including preventing
            and detecting fraud and abuse.
          </p>
          <p className={styles.semibold}>D. Contact Data</p>
          <p>
            We process the Contact Data to respond to your inquiry and
            communicate with you in connection thereof or other matters.
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.how_we_share_data}>
          <h2 className={styles.subheading}>VI. How We Share Data</h2>
          <p>
            We do not “sell” your personal data, meaning that we also do not
            rent, disclose, release, transfer, make available or otherwise
            communicate that personal information to a third party for monetary
            or other valuable consideration. However, we may share your personal
            data if it is reasonably necessary for (I) the performance of our
            undertakings with you, (II) compliance with the applicable law, and
            (III) our legitimate interest to maintain and develop the Platform.
          </p>
          <p>We share your data with the following categories of recipients:</p>
          <p>
            <span className={styles.semibold}>a&#041; </span>
            our team members, contractors, and suppliers who need to have access
            to your data in order to allow you to use the Platform (Account Data
            and Contact Data);
          </p>
          <p>
            <span className={styles.semibold}>b&#041; </span>
            technical teams, contractors and other partners who provide
            technical and customer support assistance (Contact Data, Payment
            Data and other data relevant to the technical issues);
          </p>
          <p>
            <span className={styles.semibold}>c&#041; </span>
            payment solution providers, who may process the Payment Data and
            other data relevant to the payment issues to conduct the payments in
            relation to the Platform;
          </p>
          <p>
            <span className={styles.semibold}>d&#041; </span>
            hosting service provider, which stores all the data collected within
            the Platform and stored on our servers;
          </p>
          <p>
            <span className={styles.semibold}>e&#041; </span>
            our affiliated companies, if necessary due to our corporate
            structure;
          </p>
          <p>
            <span className={styles.semibold}>f&#041; </span>
            competent governmental authorities, if the data is necessary for
            legal or investigative purposes, including if the data is necessary
            to (I) protect the property and rights of BeeLeads or a third party,
            (II) protect the safety of the public or any person, or (III)
            prevent or stop activity we may consider to be, or pose a risk of
            being, illegal, fraudulent, unethical, or legally actionable
            activity;
          </p>
          <p>
            <span className={styles.semibold}>g&#041; </span>
            new operator of the Platform, if we sell, transfer, or otherwise
            share the Platform or its part, including your personal information,
            in connection with a merger, acquisition, reorganization, or sale of
            assets (including, in each case, as part of the due-diligence
            process with any potential acquiring person) or in the event of
            bankruptcy.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={PrivacyNoticeIDs.how_long_we_retain_data}
        >
          <h2 className={styles.subheading}>VII. How Long We Retain Data</h2>
          <p className={styles.semibold}>A. General</p>
          <p>
            As a general rule, we endeavor to process your personal data as long
            as it is necessary for the purpose it was collected. However, we
            retain certain personal information for a longer period in order to
            (I) comply with the applicable legislation, (II) protect the
            property and rights of BeeLeads or a third party, (III) protect the
            safety of the public or any person, or (IV) investigate claims or
            offenses (collectively, the
            <span className={styles.semibold}> “Limited Purposes”</span>).
          </p>
          <p>
            Please note that in case of pending legal actions or if required
            under the applicable law, we may retain the personal information
            longer than outlined below.
          </p>
          <p>
            Considering the above, we establish and maintain the following data
            retention periods:
          </p>
          <p className={styles.semibold}>B. Account Data</p>
          <p>
            We process your Account Data as long as you remain a user of the
            Platform. If you delete your account or request us to delete it, as
            the case may be, we will erase your Account Data, however, we will
            keep your email address for six (6) years following the date of
            deletion in order to contact you for the Limited Purposes.
          </p>
          <p className={styles.semibold}>C. Payment Data</p>
          <p>
            We process your Payment Data as long as you have an account with the
            Platform, however, we store the Payment Data at least for six (6)
            years from the date we filed the respective tax return associated
            with the payment to you.
          </p>
          <p className={styles.semibold}>D. Contact Data</p>
          <p>
            We store the Contact Data for one (1) year following the last date
            of our communication with you regarding the same matter. We
            established this retention period during the statutes of limitations
            established in our Legalforce
            <span
              className={styles.linkToPage}
              onClick={() =>
                navigate(`/${CommonPaths.DOCUMENTS}/${CommonPaths.TERMS}`)
              }
            >
              Terms of Service
            </span>
            . In case of pending legal actions, we may process the Contact Data
            longer.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={PrivacyNoticeIDs.what_are_your_rights}
        >
          <h2 className={styles.subheading}>VIII. What Are Your Rights</h2>
          <p>
            According to the applicable data protection legislation, you may
            have the following rights:
          </p>
          <p>
            <span className={styles.semibold}>a&#041; </span>
            to request <span className={styles.semibold}>access </span> to your
            personal data (commonly known as a “data subject access request”).
            This enables you to ask us whether we process your personal data
            and, if we do process your data, you may request certain information
            about the processing activity and/or a copy of the personal data we
            hold about you;
          </p>
          <p>
            <span className={styles.semibold}>b&#041; </span>
            to request <span className={styles.semibold}>correction </span>
            (rectification) of the personal data that we hold about you. This
            enables you to have any incomplete or inaccurate data we hold about
            you corrected, though we may need to verify the accuracy of the new
            data you provide to us;
          </p>
          <p>
            <span className={styles.semibold}>c&#041; </span>
            to request <span className={styles.semibold}>erasure </span> of your
            personal data (commonly known as the “right to be forgotten”). This
            enables you to ask us to delete or remove personal data where there
            is no good reason for us continuing to process it. You also have the
            right to ask us to delete or remove your personal data where you
            have successfully exercised your right to object to processing (as
            described below), where we may have processed your information
            unlawfully or where we are required to erase your personal data to
            comply with local law; Note, however, that we may not always be able
            to comply with your request of erasure for specific legal or
            technical reasons which will be notified to you, if applicable, at
            the time of your request
          </p>
          <p>
            <span className={styles.semibold}>d&#041; </span>
            to request <span className={styles.semibold}>
              restriction{' '}
            </span> or <span className={styles.semibold}>object </span> to
            processing of your personal data when the processing is noncompliant
            with the applicable data protection legislation;
          </p>
          <p>
            <span className={styles.semibold}>e&#041; </span>
            to <span className={styles.semibold}>manage </span> your marketing
            preferences. If we send or intend to send marketing materials to
            you, you may ask us stop such processing at any time;
          </p>
          <p>
            <span className={styles.semibold}>f&#041; </span>
            to <span className={styles.semibold}>withdraw consent </span> at any
            time where we are relying on consent to process your personal data;
            however, this will not affect the lawfulness of any processing
            carried out before you withdraw your consent. Note that currently we
            do not rely on your consent to process your personal data;
          </p>
          <p>
            <span className={styles.semibold}>g&#041; </span>
            to request the <span className={styles.semibold}>review </span> by a
            human of decisions taken exclusively based on automated processing
            if that could affect your rights;
          </p>
          <p>
            <span className={styles.semibold}>h&#041; </span>
            to <span className={styles.semibold}>file a complaint </span> with a
            relevant data protection or privacy governmental authority in case
            we violate your rights or obligations imposed on us under the
            applicable data protection legislation. Relevant supervisory
            authority may depend on where (I) you are located, (II) your rights
            were violated, or (III) we are located.
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.third_party_links}>
          <h2 className={styles.subheading}>IX. Third-party links</h2>
          <p>
            The Platform may include links and social media plugins to
            third-party websites and applications. Clicking on those links or
            enabling those connections may allow third parties to collect or
            share data about you. The fact that the Platform may include links
            and social media plugins to third-party websites and applications is
            not intended to be considered as our endorsement, authorization or
            representation of our affiliation with that third party, nor is it
            an endorsement of their privacy or information security policies or
            practices. We do not control these third-party websites and
            applications, and are not responsible for their privacy statements.
            When you leave the Platform, we encourage you to read the privacy
            policy/notice/statement of every website or application you visit.
          </p>
        </div>
        <div className={styles.chapter} id={PrivacyNoticeIDs.data_of_minors}>
          <h2 className={styles.subheading}>X. Data of Minors</h2>
          <p>
            The Platform is not intended for the use of children (persons under
            18 years old or older, if the country of residence determines a
            higher age restriction). We do not knowingly collect, market to, or
            solicit data from children. We do not knowingly process, collect, or
            use personal data of children.
          </p>
          <p>
            If we become aware that a child has provided us with personal
            information, we will use commercially reasonable efforts to delete
            such information from our database. If you are the parent or legal
            guardian of a child and believe that we have collected personal
            information from your child, please contact us.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={PrivacyNoticeIDs.changes_to_this_privacy_notice}
        >
          <h2 className={styles.subheading}>
            XI. Changes to This Privacy Notice
          </h2>
          <p>
            We keep our Privacy Notice under regular review and we may update it
            at any time. If we make any changes to this document, we will change
            the Effective Date above. Please review this Privacy Notice
            regularly
          </p>
          <p>
            If we make substantial changes to the way we treat your personal
            information, we will either (a) display a notice on the Platform or
            (b) notify you by email prior to the change becoming effective.
          </p>
        </div>
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default PrivacyNotice;
