export enum LocalStorageKeys {
  TOKEN = 'token',
  OTP_DEVICE_ID = 'device',
  PRODUCT_PAGE = 'product-page:',
  CAMPAIGN_ID = 'campaign-id:',
  UNSUBSCRIBE_ID = 'unsubscribe-id: ',
  IS_PRODUCT_PAGE_PREVIEW_WARNING_HIDDEN = 'is-product-page-preview-warning-hidden',
}

export enum StepNavigation {
  next = 'next',
  prev = 'prev',
}

export enum CustomDefaultValue {
  custom = 'custom',
  default = 'default',
}

export enum Regions {
  'us-states' = 'us-states',
  'ca-provinces' = 'ca-provinces',
  africa = 'africa',
  asia = 'asia',
  europe = 'europe',
  'north-america' = 'north-america',
  oceania = 'oceania',
  'south-america' = 'south-america',
}

export enum UserStatuses {
  active = 'active',
  invited = 'invited',
  suspended = 'suspended',
  awaiting_approval = 'awaiting_approval',
  declined = 'declined',
}

export enum CampaignStatuses {
  draft = 'draft',
  finished = 'finished',
  inProgress = 'in-progress',
  paused = 'paused',
  forcePaused = 'force-paused',
  paymentProcessing = 'payment-processing',
}

export enum UserTypes {
  admin = 'admin',
  vendor = 'vendor',
  vendorManager = 'vendor-manager',
}

export enum CampaignCreatingStep {
  'first-step' = 'first-step',
  'second-step' = 'second-step',
  'third-step' = 'third-step',
  'fourth-step' = 'fourth-step',
  completed = 'completed',
}

export enum ManagementLevel {
  owner = 'owner',
  founder = 'founder',
  'c-suite' = 'c-suite',
  partner = 'partner',
  vp = 'vp',
  head = 'head',
  director = 'director',
  manager = 'manager',
  senior = 'senior',
  entry = 'entry',
  intern = 'intern',
}

export enum MilestoneType {
  monthly = 'monthly',
  custom = 'custom',
}

export enum ScriptType {
  webinar = 'webinar',
  whitepaper = 'whitepaper',
}

export const leadOptions = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'extension',
  'location',
  'person_country',
  'person_zip_code',
  'job_title',
  'company',
  'asset_name',
  'date_of_scoring',
  'middle_name',
  'salutation',
  'suffix',
  'job_function',
  'management_level',
  'company_division_name',
  'email_domain',
  'department',
  'contact_accuracy_score',
  'contact_accuracy_grade',
  'z_contact_profile_url',
  'linkedin_prospect_profile_link',
  'notice_provided_date',
  'person_street',
  'person_city',
  'person_state',
];

export const companyOptions = [
  'z_company_id',
  'website',
  'company_hq_phone',
  'fax',
  'ticker',
  'revenue_thousands',
  'revenue_range',
  'employees',
  'employee_range',
  'sic_code_one',
  'sic_code_two',
  'sic_codes',
  'naics_code_one',
  'naics_code_two',
  'naics_codes',
  'primary_industry',
  'primary_sub_industry',
  'all_industries',
  'all_sub_industries',
  'industry_hierarchical_category',
  'secondary_industry_hierarchical_category',
  'z_company_profile_url',
  'ownership_type',
  'business_model',
  'certified_active_company',
  'company_street_address',
  'company_city',
  'company_state',
  'company_zip_code',
  'company_country',
  'query_name',
  'linkedin_company_profile_link',
];

export const allReportMappingOptions = [...leadOptions, ...companyOptions];

export enum VendorCampaignListTableAccessors {
  name = 'name',
  start_date = 'start_date',
  end_date = 'end_date',
  cpl = 'cpl',
  total_leads = 'total_leads',
  status = 'status',
  manage = 'manage',
  company_name = 'company_name',
  offer_status = 'offer_status',
}

export enum OfferStatuses {
  'wait-vendor-approve' = 'wait-vendor-approve',
  counterbid = 'counterbid',
  accepted = 'accepted',
  declined = 'declined',
  canceled = 'canceled',
  finished = 'finished',
}

export enum IconsNames {
  demo = 'demo',
  calendar = 'calendar',
  arrow = 'arrow',
  check_mark = 'check_mark',
  copy = 'copy',
  download = 'download',
  dropdown_indicator = 'dropdown_indicator',
  delete = 'delete',
  edit = 'edit',
  facebook = 'facebook',
  full_screen = 'full_screen',
  location = 'location',
  error = 'error',
  phone = 'phone',
  plus = 'plus',
  rocket_man = 'rocket_man',
  rocket = 'rocket',
  select_arrow = 'select_arrow',
  star = 'star',
  success_checkmark = 'success_checkmark',
  trash = 'trash',
  upload_file = 'upload_file',
  right_arrow = 'right_arrow',
  eye = 'eye',
  copy_success = 'copy_success',
  logotype = 'logotype',
  round_plus = 'round_plus',
  close = 'close',
  document_download = 'document_download',
  spinner = 'spinner',
  pause = 'pause',
  dots = 'dots',
  website = 'website',
  wallet = 'wallet',
  payments = 'payments',
  servers = 'servers',
  campaignDeliveries = 'campaign_deliveries',
  file = 'file',
  email = 'email',
  mobile = 'mobile',
  transfer = 'transfer',
  twitter = 'twitter',
  instagram = 'instagram',
  dollar = 'dollar',
  card = 'card',
  terms = 'terms',
  people = 'people',
  person_money = 'person_money',
  link = 'link',
  linkedin = 'linkedin',
  log_out = 'log_out',
  person = 'person',
  company = 'company',
  magnifier = 'magnifier',
  arrow_select_right = 'select_arrow_right',
  arrow_open = 'arrow_open',
  person_card = 'person_card',
  thumb_up = 'thumb_up',
  hourglass = 'hourglass',
  bulb = 'bulb',
  history_watch = 'history_watch',
  thumb_up_filled = 'thumb_up_filled',
  info = 'info',
  warning = 'warning',
  outlined_flag = 'outlined_flag',
  shield = 'shield',
  lock = 'lock',
  star_bordered = 'star_bordered',
  star_half = 'star_half',
  product = 'product',
  settings = 'settings',
  list = 'list',
  text_snippet = 'text_snippet',
  user_groups = 'user_groups',
  warning_filled = 'warning_filled',
  hello_hand = 'hello_hand',
  file_stroke = 'file_stroke',
  help_outline = 'help_outline',
  notes = 'notes',
  compliance_lock_line = 'compliance_lock_line',
  fee_dollar = 'fee_dollar',
  book = 'book',
  play_arrow = 'play_arrow',
  case_study = 'case_study',
  view_carousel = 'view_carousel',
  drag_indicator = 'drag_indicator',
  horizontal_rule = 'horizontal_rule',
  folder_open = 'folder_open',
  on_demand_video = 'on_demand_video',
  filter = 'filter',
  sorting_arrows = 'sorting_arrows',
  long_arrow = 'long_arrow',
  help = 'help',
  external_link = 'external_link',
}

export const MILESTONE_END_DATE_DIFFERENCE = 7;
export const MIN_CAMPAIGN_DURATION_DAYS = 14;

export enum LeadsTableAccessors {
  firstName = 'first-name',
  lastName = 'last-name',
  email = 'email',
  phone = 'phone',
  extension = 'extension',
  location = 'location',
  country = 'country',
  zip = 'person-zip-code',
  company = 'company',
  jobTitle = 'job-title',
  asset = 'asset-name',
  date = 'date-of-scoring',
  qc = 'qc',
  milestone = 'milestone',
  vendor = 'vendor',
  companyPhone = 'company-hq-phone',
}

export enum LogsTableAccessors {
  leadsEmail = 'campaign_lead_email',
  campaign = 'campaign_name',
  status = 'status',
  dateTime = 'created_at',
  syncData = 'sync_data',
}

export enum LeadStatus {
  'wait-qualification' = 'wait-qualification',
  qualified = 'qualified',
  disqualified = 'disqualified',
  'qc-qualification' = 'qc-qualification',
  'qc-disqualified' = 'qc-disqualified',
  'auto-qc-disqualified' = 'auto-qc-disqualified',
  reserved = 'reserved',
  'demo-qc-qualification' = 'demo-qc-qualification',
}

export const DEFAULT_POSSIBLE_YEARS = 10;

export const selectAllOptionValue = 'ALL';

export const MAX = 'max';

export const FILE_NAME_MAX_LENGTH = 100;

export enum SALFileStatus {
  draft = 'draft',
  finished = 'finished',
}

export enum TALFileStatus {
  draft = 'draft',
  finished = 'finished',
}

export enum ServerVariants {
  smtp = 'smtp',
  esp = 'esp',
}

export enum ReportUploadStatus {
  created = 'created',
  'in-progress' = 'in-progress',
  done = 'done',
  failed = 'failed',
}

export enum ExecutionErrorCodes {
  field_required = 'field_required',
  unknown_error = 'unknown_error',
  milestone_fulfilled = 'milestone_fulfilled',
  campaign_lead_exists = 'campaign_lead_exists',
  campaign_lead_qualified = 'campaign_lead_qualified',
  asset_name_required = 'asset_name_required',
  asset_name_invalid = 'asset_name_invalid',
}

export enum ScriptQuestionType {
  checkbox = 'checkbox',
  radiobutton = 'radiobutton',
}

export enum ConnectionType {
  oauth = 'oauth',
  form = 'form',
}

export enum IntegrationsLogStatuses {
  created = 'created',
  'in-progress' = 'in-progress',
  succeed = 'succeed',
  failed = 'failed',
}

export enum CRMPlatformNames {
  hubspot = 'HubSpot',
  marketo = 'Marketo',
}
