import {
  TwoFADevice,
  DevicesDictionary,
  TwoFATypes,
} from 'components/Authorization/TwoFA/models';

export const getDevicesDictionary = (devices?: TwoFADevice[]) =>
  devices?.reduce<DevicesDictionary>(
    (acc, val) => {
      if (acc[val.type]?.length) {
        acc[val.type].push(val);
      } else {
        acc[val.type] = [val];
      }

      return acc;
    },
    {
      [TwoFATypes.email]: [],
      [TwoFATypes.sms]: [],
      [TwoFATypes.totp]: [],
    }
  );
