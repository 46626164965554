import { useQueryClient } from 'react-query';

import type { ProductFormValues } from 'components/Product/CreateProductContent/models';
import type { ProductResponse, FeatureResponse } from 'types/models';
import type { ProductPricingPlanResponse } from 'components/Product/models';

import ProductPage from 'components/Product/ProductPage';
import DemoMessage from 'components/Product/CreateProductContent/ProductPagePreview/DemoMessage';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

type Props = {
  values: ProductFormValues;
  productResponse?: ProductResponse;
};

const ProductPagePreview = ({ values, productResponse }: Props) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const productFeatures = queryClient.getQueryData<FeatureResponse[]>([
    'featuresList',
    user?.company.slug,
    productResponse?.id,
  ]);
  const convertedValues = {
    ...values,
    background_image:
      typeof values.background_image === 'string'
        ? values.background_image
        : values.background_image?.renderLink,
    background_image_original: '',
    product_photo:
      typeof values.product_photo === 'string'
        ? values.product_photo
        : values.product_photo?.renderLink,
    product_photo_original: '',
    languages: values.languages.map(lang => lang.value),
    pricing_plans: queryClient.getQueryData<ProductPricingPlanResponse[]>([
      'get-pricing-plans',
      user?.company.slug,
      productResponse?.id.toString(),
    ]),
    benefits: productFeatures?.filter(item => item.is_benefit),
    disadvantages: productFeatures?.filter(item => !item.is_benefit),
  };
  const productData = { ...(productResponse || {}), ...convertedValues };

  return (
    <div className={styles.wrapper}>
      <DemoMessage isHidable className={styles.demo} />
      <ProductPage data={productData} isPreviewMode />
    </div>
  );
};

export default ProductPagePreview;
