import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { ProductPricingPlanResponse } from 'components/Product/models';

import ModalWindow from 'components/common/ModalWindow';

import PlanInfo from 'components/Product/ProductPage/PricingPlans/PlanInfo';
import PlanImagesCarousel from 'components/Product/ProductPage/PricingPlans/PlanImagesCarousel';

import styles from './index.module.scss';

type Props = {
  plan: ProductPricingPlanResponse;
};

const PlanDetailsModal = ({ plan }: Props) => {
  const { t } = useTranslation();

  const isCarouselVisible = !!plan.images.length;

  return (
    <ModalWindow
      primaryTitle={t('manage-products.plan-details')}
      className={styles.modal}
    >
      <div
        className={cn(styles.content, {
          [styles.skipCarousel]: !isCarouselVisible,
        })}
      >
        {isCarouselVisible && <PlanImagesCarousel images={plan.images} isBig />}
        <PlanInfo planData={plan} isBig />
      </div>
    </ModalWindow>
  );
};

export default PlanDetailsModal;
