export enum ProductPricingPlanPeriods {
  'monthly-or-annually' = 'monthly-or-annually',
  'per-item' = 'per-item',
  'per-pair' = 'per-pair',
  'per-hour' = 'per-hour',
  'custom' = 'custom',
}

export enum ProductPricingPlanMonthlyAnnuallyPeriods {
  monthly = 'monthly',
  annual = 'annual',
}

export type ProductPricingPlanPeriod = {
  name: string;
  price: string;
  link: string;
  link_title: string;
};

export type ProductPricingPlanImage = {
  id: number;
  image: string;
  image_original: string;
  order: number;
};

export type ProductPricingPlanResponse = {
  id: number;
  product_id: string;
  name: string;
  period: ProductPricingPlanPeriods;
  benefits: { id: number; name: string }[];
  description: string;
  period_details: ProductPricingPlanPeriod[];
  currency: string;
  images: ProductPricingPlanImage[];
};
