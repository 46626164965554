export enum UnsubscribeOption {
  snooze_receiving_emails = 'snooze_receiving_emails',
  unsubscribe_from_asset = 'unsubscribe_from_asset',
  unsubscribe_from_sender = 'unsubscribe_from_sender',
}

export type UnsubscribeSelectOption = {
  value: UnsubscribeOption;
  label: string;
};

export type UnsubscribeFormValues = {
  unsubscribe_option: UnsubscribeSelectOption;
  until_date?: { value: string; label: string; customValue?: string };
  reason?: string;
  isCustomReason?: boolean;
  step: number;
};

export type UnsubscribePayload = {
  unsubscribe_option: UnsubscribeOption;
  until_date?: string;
  reason?: string;
};

export type UnsubscribeStatusResponse = {
  is_submitted: boolean;
};
