import type { Dispatch, SetStateAction } from 'react';

import { LogsFilterCategoryTypes } from 'components/common/Table/TableFilter/models';

import IconSVG from 'components/UI/IconSVG';

import { SelectOption, DateRangeOption } from 'types/models';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  category: { label: string; options?: string[]; type: string; key: string };
  setSubmenu: (value: { label: string; key: string }) => void;
  type: string;
  setSearchParams: Dispatch<
    SetStateAction<Record<string, SelectOption[] | DateRangeOption>>
  >;
  setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
};

const Category = ({
  category,
  setSubmenu,
  type,
  setSearchParams,
  setIsOptionsListVisible,
}: Props) => {
  const isDate = type === LogsFilterCategoryTypes.date_range;

  const onClick = () => {
    if (isDate) {
      setIsOptionsListVisible(false);
      setSearchParams(prev => {
        return {
          ...prev,
          [category.key]: {},
        };
      });
    } else {
      setSubmenu({ label: category.label, key: category.key });
    }
  };

  return (
    <li className={styles.optionLabel} onClick={onClick}>
      <p className={styles.label}>{category.label}</p>
      {category.type === LogsFilterCategoryTypes.array && (
        <IconSVG name={IconsNames.arrow} />
      )}
    </li>
  );
};

export default Category;
