import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  options: ReturnType<typeof usePopperTooltip>;
  className?: string;
};

const SimpleTooltip: React.FC<Props> = ({
  options: { setTooltipRef, getTooltipProps, getArrowProps },
  className,
  children,
}) => {
  return (
    <span className={styles.container}>
      <span
        {...getTooltipProps({
          className: cn('tooltip-container', styles.tooltip, className),
        })}
        ref={setTooltipRef}
      >
        <span {...getArrowProps({ className: 'tooltip-arrow' })} />
        {children}
      </span>
    </span>
  );
};

export default SimpleTooltip;
