import { useTranslation } from 'react-i18next';
import { iso6393 } from 'iso-639-3';

import IconSVG from 'components/UI/IconSVG';
import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';

import getLongStringView from 'helpers/getLongStringView';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  description?: string;
  email?: string;
  website?: string;
  languages?: string[];
  instagram?: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
  name?: string;
};

const AboutProduct: React.FC<Props> = ({
  description,
  email,
  website,
  languages,
  instagram,
  twitter,
  facebook,
  linkedin,
  name,
}) => {
  const { t } = useTranslation();

  const languagesLabels = languages
    ?.map(item => {
      return iso6393.filter(lang => lang.iso6393 === item);
    })
    .map(option => {
      return option[0].name;
    });

  return (
    <div>
      <h1 className={styles.title}>{name}</h1>

      <SectionWrapper
        title={t('manage-products.product-details')}
        iconName={IconsNames.list}
        withoutBorder
        className={styles.container}
      >
        <div className={styles.overview}>
          {description ? (
            <p className={styles.description}>{description}</p>
          ) : (
            '-'
          )}
        </div>
        {(!!website ||
          !!email ||
          !!instagram ||
          !!twitter ||
          !!facebook ||
          !!linkedin) && (
          <div className={styles.contactInfo}>
            <h3 className={styles.heading}>{t('common.field.contact-info')}</h3>
            <div className={styles.infoWrapper}>
              {website && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.website} />
                  <a href={website} className={styles.field}>
                    {t('common.field.website')}
                  </a>
                </div>
              )}
              {email && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.email} />
                  <a href={`mailto: ${email}`} className={styles.field}>
                    {getLongStringView(email, 40)}
                  </a>
                </div>
              )}
              {instagram && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.instagram} />
                  <a href={instagram} className={styles.field}>
                    {t('common.field.instagram')}
                  </a>
                </div>
              )}
              {linkedin && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.linkedin} />
                  <a href={linkedin} className={styles.field}>
                    {t('common.field.linkedin')}
                  </a>
                </div>
              )}
              {twitter && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.twitter} />
                  <a href={twitter} className={styles.field}>
                    {t('common.field.twitter')}
                  </a>
                </div>
              )}
              {facebook && (
                <div className={styles.fieldWrapper}>
                  <IconSVG name={IconsNames.facebook} />
                  <a href={facebook} className={styles.field}>
                    {t('common.field.facebook')}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
        {!!languagesLabels?.length && (
          <div className={styles.languages}>
            <h3 className={styles.heading}>
              {t('common.field.language-support')}
            </h3>
            <ul className={styles.languagesList}>
              {languagesLabels?.map(item => {
                return (
                  <li key={item} className={styles.languageItem}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </SectionWrapper>
    </div>
  );
};

export default AboutProduct;
