import type { Dispatch, SetStateAction } from 'react';

import type { LogsFilterType } from 'components/common/Table/TableFilter/models';
import { LogsFilterCategoryTypes } from 'components/common/Table/TableFilter/models';

import StaticOptions from 'components/common/Table/TableFilter/StaticOptions';
import CampaignsDynamicOptions from 'components/IntegrationsContent/AdminContent/LogsTable/CampaignsDynamicOptions';
import EmailsDynamicOptions from 'components/IntegrationsContent/AdminContent/LogsTable/EmailsDynamicOptions';

import { IntegrationsLogStatuses } from 'constants/constants';
import { DateRangeOption, SelectOption } from 'types/models';

export const logsFilters: LogsFilterType = {
  campaigns: {
    label: 'Campaign',
    key: 'campaigns',
    type: LogsFilterCategoryTypes.array,
    getSubmenu: ({
      setSearchParams,
      category,
      searchParams,
      setIsOptionsListVisible,
    }: {
      setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
      category: string;
      searchParams: SelectOption[];
      setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
    }) => (
      <CampaignsDynamicOptions
        setSearchParams={
          setSearchParams as Dispatch<
            SetStateAction<Record<string, SelectOption[]>>
          >
        }
        category={category}
        searchParams={searchParams}
        setIsOptionsListVisible={setIsOptionsListVisible}
      />
    ),
  },
  emails: {
    label: 'Lead email',
    key: 'emails',
    type: LogsFilterCategoryTypes.array,
    getSubmenu: ({
      setSearchParams,
      category,
      searchParams,
      setIsOptionsListVisible,
    }: {
      setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
      category: string;
      searchParams: SelectOption[] | DateRangeOption;
      setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
    }) => (
      <EmailsDynamicOptions
        setSearchParams={
          setSearchParams as Dispatch<
            SetStateAction<Record<string, SelectOption[]>>
          >
        }
        category={category}
        searchParams={searchParams as SelectOption[]}
        setIsOptionsListVisible={setIsOptionsListVisible}
      />
    ),
  },
  statuses: {
    label: 'Status',
    key: 'statuses',
    type: LogsFilterCategoryTypes.array,
    translationPrefix: 'integrations.statuses.',
    getSubmenu: ({
      setSearchParams,
      category,
      searchParams,
      setIsOptionsListVisible,
      translationPrefix,
    }: {
      setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
      category: string;
      searchParams: SelectOption[];
      setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
      translationPrefix?: string;
    }) => (
      <StaticOptions
        options={Object.values(IntegrationsLogStatuses)}
        setSearchParams={setSearchParams}
        category={category}
        searchParams={searchParams}
        setIsOptionsListVisible={setIsOptionsListVisible}
        translationPrefix={translationPrefix}
      />
    ),
  },
  'date-range': {
    label: 'Date range',
    key: 'date-range',
    type: LogsFilterCategoryTypes.date_range,
  },
};
