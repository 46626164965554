import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  FormProvider,
  useForm,
  useController,
  useFieldArray,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isMobile } from 'react-device-detect';

import type { AxiosError } from 'axios';
import type {
  CampaignResponse,
  CampaignAssetFileResponse,
  OfferResponse,
} from 'types/models';
import type {
  AssignRequestBody,
  OffersFormValues,
} from 'components/CampaignManage/models';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import ReportedLeadsTable from 'components/common/ReportedLeadsTable';

import VMOfferItem from 'components/CampaignManage/OfferItem/VMOfferItem';
import SelectVendors from 'components/CampaignManage/VendorManagerSettings/SelectVendors';
import ActionButtons from 'components/CampaignManage/VendorManagerSettings/ActionButtons';

import { OfferStatuses, UserTypes } from 'constants/constants';

import {
  getLeadsByAssetsPerMilestoneDictionary,
  getRequestedLeadsPerMilestoneDictionary,
  convertOfferResponseToFormValues,
} from 'helpers/offers';
import getResponseError from 'helpers/getResponseError';

import { validationSchemaCreateOffer } from 'utils/validations';

import styles from './index.module.scss';

type Props = {
  campaign: CampaignResponse;
  assets: CampaignAssetFileResponse[];
  offers: OfferResponse[];
};

const VendorManagerSettings = ({
  campaign,
  assets,
  offers: offersData,
}: Props) => {
  const { t } = useTranslation();
  const { user, axios } = useAuth();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);

  const isDemoCampaign = campaign.campaign_details.cost_per_lead === 0;

  const newOffersFormMethods = useForm<OffersFormValues>({
    defaultValues: { offers: [] },
    resolver: yupResolver(validationSchemaCreateOffer(isDemoCampaign)),
  });
  const {
    fields: newOffersFields,
    append,
    remove,
  } = useFieldArray({
    control: newOffersFormMethods.control,
    name: 'offers',
    keyName: 'key',
  });

  const assignedFormMethods = useForm<OffersFormValues>({
    defaultValues: {
      offers: offersData.map(offer =>
        convertOfferResponseToFormValues({
          offer,
          campaign,
          isShowEmptyMilestones: true,
        })
      ),
    },
    resolver: yupResolver(validationSchemaCreateOffer(isDemoCampaign)),
  });

  const { field: assignedOffersFields } = useController({
    control: assignedFormMethods.control,
    name: 'offers',
  });

  const leadsCountByAssets = useMemo(
    () =>
      getLeadsByAssetsPerMilestoneDictionary(
        campaign.campaign_delivery.milestones
      ),
    [campaign.campaign_delivery.milestones]
  );
  const requestedLeadsPerMilestoneDictionary = useMemo(
    () =>
      getRequestedLeadsPerMilestoneDictionary(
        campaign.campaign_delivery.milestones
      ),
    [campaign]
  );

  const onError = (err: AxiosError) => {
    openModal({
      Content: (
        <ModalWindow
          title={t('common.error.something-went-wrong')}
          errorMessage={getResponseError(err)}
        />
      ),
    });
  };

  const updateAssignedOfferAfterSubmit = (offer: OfferResponse) => {
    const offersCache = queryClient.getQueryData<OfferResponse[]>([
      'vendor-manager-offers',
      campaign.id.toString(),
      user?.company.id.toString(),
    ]);
    const offerIndex = offersCache?.findIndex(item => item.id === offer.id);
    const updatedOffers = [...(offersCache || [])];
    updatedOffers[offerIndex && offerIndex !== -1 ? offerIndex : 0] = offer;

    const newOffersValues = updatedOffers.map(item =>
      convertOfferResponseToFormValues({
        offer: item,
        campaign,
        updatedBy: UserTypes.vendorManager,
        isShowEmptyMilestones: true,
      })
    );

    queryClient.setQueryData<OfferResponse[]>(
      [
        'vendor-manager-offers',
        campaign.id.toString(),
        user?.company.id.toString(),
      ],
      updatedOffers
    );

    assignedFormMethods.reset({
      offers: newOffersValues,
    });
  };

  const assignMutation = useMutation<
    { data: OfferResponse; offerIndex: number },
    AxiosError,
    AssignRequestBody
  >(
    async ({ values, index }: AssignRequestBody) => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/campaigns/${campaign.id}/offers/`,
          values
        );

        return { data, offerIndex: index };
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async ({ data, offerIndex }) => {
        openModal({
          Content: (
            <ModalWindow
              title={data.vendor_display_name}
              successMessage={t('offers.assigned-successfully')}
            />
          ),
        });
        const newValues = [...newOffersFormMethods.getValues().offers];
        newValues.splice(offerIndex, offerIndex + 1);
        newOffersFormMethods.reset({
          offers: newValues,
        });

        const offersQueryKey = [
          'vendor-manager-offers',
          campaign.id.toString(),
          user?.company.id.toString(),
        ];
        const offersCache =
          queryClient.getQueryData<OfferResponse[]>(offersQueryKey);
        const updatedAssignedOffers = [...(offersCache || []), data];
        assignedFormMethods.reset({
          offers: updatedAssignedOffers?.map(item =>
            convertOfferResponseToFormValues({
              offer: item,
              campaign,
              isShowEmptyMilestones: true,
            })
          ),
        });
        queryClient.setQueryData<OfferResponse[]>(
          offersQueryKey,
          updatedAssignedOffers
        );
      },
      onError,
    }
  );

  const bidMutation = useMutation<
    OfferResponse,
    AxiosError,
    AssignRequestBody['values']
  >(
    async values => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/campaigns/${campaign.id}/offers/${values.id}/rebid/`,
          values
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: updateAssignedOfferAfterSubmit,
      onError,
    }
  );

  const acceptMutation = useMutation<OfferResponse, AxiosError, number>(
    async (offerId: number) => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/campaigns/${campaign.id}/offers/${offerId}/accept/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: updateAssignedOfferAfterSubmit,
      onError,
    }
  );

  const allocatedLeadsCount = offersData?.reduce((acc, val) => {
    if (val.status !== OfferStatuses.declined) {
      val.campaign_offer_milestones.forEach(
        milestone => (acc += milestone.leads_required)
      );
    }

    return acc;
  }, 0);

  const isAllLeadsAssigned =
    allocatedLeadsCount === campaign.campaign_details.total_leads;
  const assignedOffers = offersData?.filter(
    offer => offer.status !== OfferStatuses.accepted
  );
  const acceptedOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.accepted
  );
  const canceledOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.canceled
  );

  const finishedOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.finished
  );

  const isAcceptedOffers =
    offersData && assignedOffers?.length !== offersData.length;

  const isCanceledOffers = offersData && !!canceledOffers?.length;

  const isFinishedOffers = offersData && !!finishedOffers?.length;

  const isLoaderScreenVisible =
    assignMutation.isLoading ||
    bidMutation.isLoading ||
    acceptMutation.isLoading;

  return (
    <div className={styles.wrapper}>
      {!isMobile && (
        <FormProvider {...newOffersFormMethods}>
          <form>
            <div className={styles.select}>
              <p className={styles.assigned}>
                {t('campaign.assigned-leads')}
                {allocatedLeadsCount}/{campaign.campaign_details.total_leads}
              </p>
              {!isAllLeadsAssigned && (
                <SelectVendors
                  offersData={offersData}
                  field={{
                    value: newOffersFields,
                    onChange: (val, meta) => {
                      if (meta.action === 'select-option' && meta.option) {
                        append(meta.option);
                      }
                      if (meta.action === 'remove-value') {
                        remove(
                          newOffersFields.findIndex(
                            item => item.label === meta.removedValue.label
                          )
                        );
                      }
                      if (meta.action === 'clear') {
                        remove();
                      }
                    },
                    name: 'offers',
                  }}
                  campaign={campaign}
                />
              )}
            </div>
            {!isAllLeadsAssigned && (
              <>
                {!newOffersFields.length ? (
                  <h2>{t('campaign.select-vendor')}</h2>
                ) : (
                  <div className={styles.grid}>
                    {newOffersFields.map(({ value, key }, index) => {
                      return value ? (
                        <VMOfferItem
                          index={index}
                          campaign={campaign}
                          key={key}
                          assets={assets}
                          assignedLeadsBySavedAssets={leadsCountByAssets}
                          acceptMutation={acceptMutation}
                          bidMutation={bidMutation}
                          watchAssignedOffersForm={assignedFormMethods.watch}
                          watchNewOffersForm={newOffersFormMethods.watch}
                          requiredLeadsCountPerMilestone={
                            requestedLeadsPerMilestoneDictionary
                          }
                          assignMutation={assignMutation}
                          updateAssignedOfferAfterSubmit={
                            updateAssignedOfferAfterSubmit
                          }
                        >
                          {value.vendor_display_name}
                        </VMOfferItem>
                      ) : null;
                    })}
                  </div>
                )}
              </>
            )}
          </form>
        </FormProvider>
      )}

      {!!assignedOffersFields.value?.length && (
        <FormProvider {...assignedFormMethods}>
          <form className={styles.offers}>
            {assignedOffersFields.value.map(({ value }, index) => {
              const offerResponseData = offersData?.find(
                offer => offer.id === value.id
              );
              const offerInitialData = offerResponseData
                ? convertOfferResponseToFormValues({
                    offer: offerResponseData,
                    campaign,
                  })
                : undefined;

              return (
                <VMOfferItem
                  status={value.status}
                  key={value.id}
                  campaign={campaign}
                  index={index}
                  assignedLeadsBySavedAssets={leadsCountByAssets}
                  bidMutation={bidMutation}
                  assets={assets}
                  watchAssignedOffersForm={assignedFormMethods.watch}
                  watchNewOffersForm={newOffersFormMethods.watch}
                  requiredLeadsCountPerMilestone={
                    requestedLeadsPerMilestoneDictionary
                  }
                  acceptMutation={acceptMutation}
                  offerId={value.id}
                  offerInitialData={offerInitialData?.value}
                  updateAssignedOfferAfterSubmit={
                    updateAssignedOfferAfterSubmit
                  }
                >
                  {value.vendor_display_name}
                </VMOfferItem>
              );
            })}
          </form>
        </FormProvider>
      )}

      {(isAcceptedOffers || isCanceledOffers || isFinishedOffers) && (
        <>
          <ActionButtons isDataEmpty={isDataEmpty} />
          <ReportedLeadsTable
            setIsDataEmpty={setIsDataEmpty}
            campaign={campaign}
            acceptedOffers={acceptedOffers}
            canceledOffers={canceledOffers}
          />
        </>
      )}
      {isLoaderScreenVisible && <LoaderScreen />}
    </div>
  );
};

export default VendorManagerSettings;
