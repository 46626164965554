import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { ScheduleDemoValues } from 'components/Product/ProductPage/models';
import type { Dayjs } from 'dayjs';

import SelectTimezone from 'components/Product/ProductPage/GetDemoModal/SelectTimezone';
import InputWrapper from 'components/common/InputWrapper';

import styles from './index.module.scss';

const displayDays = 6;
const displayTimeslots = 24;

export const getTimeSlots = () => {
  let initSlot = dayjs().set('hour', 7).set('minute', 0);
  const timeSlotsArray = [initSlot];
  for (let i = 0; i < displayTimeslots; i++) {
    timeSlotsArray.push(dayjs(timeSlotsArray[i].add(30, 'm')));
  }

  return timeSlotsArray;
};

export const getDays = () => {
  let date = dayjs();
  const datesArr = [date];

  for (let i = 0; i < displayDays - 1; i++) {
    datesArr.push(dayjs(datesArr[i]).add(1, 'day'));
  }

  return datesArr;
};
const currentTime = dayjs();

const TimeSlotPicker = () => {
  const { t } = useTranslation();
  const { register, watch, resetField } = useFormContext<ScheduleDemoValues>();
  const { onChange } = register('date');

  const datesArr = getDays();
  const timeSlotsArray = getTimeSlots();

  const isCurrentDaySelected =
    watch('date') === dayjs(datesArr[0]).format('YYYY-MM-DD');

  const getIsTimeslotDisabled = (option: Dayjs) => {
    return isCurrentDaySelected && option.diff(currentTime) < 0;
  };

  return (
    <>
      <div className={styles.datePicker}>
        <ul className={styles.daysList}>
          {datesArr.map((date, index) => {
            return (
              <li className={styles.day} key={index}>
                {dayjs(date).format('ddd')}
              </li>
            );
          })}
        </ul>
        <ul className={styles.datesList}>
          {datesArr.map(option => {
            return (
              <label className={styles.date} key={option.toISOString()}>
                <input
                  className={styles.input}
                  type="radio"
                  {...register('date')}
                  onChange={e => {
                    resetField('timeslot');
                    onChange(e);
                  }}
                  value={option.format('YYYY-MM-DD')}
                  aria-label="date"
                />
                <span className={styles.label}>{option.format('DD')}</span>
              </label>
            );
          })}
        </ul>
      </div>
      <div className={styles.timePicker}>
        <InputWrapper
          wrapperClasses={styles.select}
          label={t('common.field.timezone')}
        >
          <SelectTimezone />
        </InputWrapper>
        <ul className={styles.slotsList}>
          {timeSlotsArray.map(option => {
            return (
              <label className={styles.slotItem} key={option.toISOString()}>
                <input
                  type="radio"
                  disabled={getIsTimeslotDisabled(option)}
                  className={styles.input}
                  {...register('timeslot', {
                    required: true,
                  })}
                  value={option.format('THH:mm')}
                  aria-label="time"
                />
                <span
                  className={cn(styles.label, {
                    [styles.disabled]: getIsTimeslotDisabled(option),
                  })}
                >
                  {option.format('hh:mm A')}
                </span>
              </label>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TimeSlotPicker;
