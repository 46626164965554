import axios, { AxiosError } from 'axios';
import { createContext, useState, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosInstance } from 'axios';
import type { UserCompany } from 'types/models';
import type { TwoFADevice } from 'components/Authorization/TwoFA/models';

import { PublicPaths } from 'constants/routes';
import { LocalStorageKeys, UserTypes } from 'constants/constants';

import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import getResponseError from 'helpers/getResponseError';

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  country_code?: string;
  company: UserCompany;
  timezone: string;
  phone_number: string;
  date_of_birth?: string;
  job_title: string;
  current_group_name: UserTypes;
  available_groups: UserTypes[];
  is_superadmin: boolean;
  devices: TwoFADevice[];
  vendor_exists?: boolean;
};

type State = {
  user: null | User;
};

const initialState = {
  user: null,
  setState: () => {},
  axios,
  logout: () => {},
  isGetUserDataEnabled: false,
};

export const AuthContext = createContext<
  State & {
    setState: (state: State) => void;
    axios: AxiosInstance;
    logout: () => void;
    isGetUserDataEnabled: boolean;
  }
>(initialState);

export const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State>(initialState);
  const [accessToken, , removeToken] = useLocalStorage<string>(
    LocalStorageKeys.TOKEN
  );
  const [OTPDevice, , removeOTPDevice] = useLocalStorage<string>(
    LocalStorageKeys.OTP_DEVICE_ID
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const logout = () => {
    queryClient.clear();
    setState({ user: null });
    removeToken();
    removeOTPDevice();
    navigate(PublicPaths.LOG_IN);
  };

  const axiosInstance = useMemo(() => {
    const AxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    AxiosInstance.interceptors.request.use(config => {
      const tokensString = localStorage.getItem(LocalStorageKeys.TOKEN);
      const token: string | null = tokensString && JSON.parse(tokensString);

      if (!token && state.user) {
        logout();
      }

      return {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? `Token ${token}` : '',
        },
      };
    });

    AxiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const isUnauthorized = error.response && error.response.status === 401;

        if (isUnauthorized) logout();

        return Promise.reject(error);
      }
    );

    return AxiosInstance;
  }, []);

  const isGetUserDataEnabled = !!accessToken && !state.user?.id && !!OTPDevice;

  const { isLoading, error, refetch } = useQuery<User, AxiosError>(
    'profile',
    async () => {
      try {
        const user = await axiosInstance.get<User>('/accounts/profile/');

        return user.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: userData => {
        setState({ user: userData });
      },
      staleTime: Infinity,
      enabled: isGetUserDataEnabled,
    }
  );

  const value = {
    user: state.user,
    setState,
    axios: axiosInstance,
    logout,
    isGetUserDataEnabled,
  };

  return (
    <AuthContext.Provider value={value}>
      {error ? (
        <ModalWindow
          title={t('common.error.account-loading-error')}
          errorMessage={getResponseError(error)}
          positiveButtonProps={{
            onClick: () => refetch(),
            children: t('common.button.try-again'),
          }}
        />
      ) : (
        children
      )}
      {isLoading && <LoaderScreen />}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
