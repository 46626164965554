import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { iso6393 } from 'iso-639-3';

import type { AxiosError } from 'axios';
import type { ProductResponse } from 'types/models';
import type { ProductFormValues } from 'components/Product/CreateProductContent/models';

import CompanyFeatures from 'components/Product/CreateProductContent/CompanyFeatures';
import PricingPlans from 'components/Product/CreateProductContent/PricingPlans';
import LoaderScreen from 'components/common/LoaderScreen';
import FirstStep from 'components/Product/CreateProductContent/FirstStep';
import Header from 'components/Product/CreateProductContent/Header';
import AssetSorting from 'components/Product/CreateProductContent/AssetSorting';
import AssetsList from 'components/Product/CreateProductContent/AssetsList';
import ProductPagePreview from 'components/Product/CreateProductContent/ProductPagePreview';

import { validationSchemaCreateProduct } from 'components/Product/CreateProductContent/validationSchema';

import useAuth from 'contexts/AuthContext';

import { IconsNames } from 'constants/constants';
import { AssetContentType } from 'constants/assets';

import styles from './index.module.scss';

const createLanguagesOptions = (languages: string[]) => {
  return languages
    .map(item => {
      return iso6393.filter(lang => lang.iso6393 === item);
    })
    .map(option => {
      return { label: option[0].name, value: option[0].iso6393 };
    });
};

const CreateProductContent = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const oldSlug = searchParams.get('productSlug');
  const [isFirstStepDone, setIsFirstStepDone] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const { user, axios } = useAuth();

  const methods = useForm<ProductFormValues>({
    resolver: yupResolver(validationSchemaCreateProduct),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const companySlug = user?.company.slug;

  const { isLoading: isLoadingGetProduct, data: productData } = useQuery(
    ['get-product', companySlug, oldSlug],
    async () => {
      try {
        const { data } = await axios.get<ProductResponse>(
          `/companies/${companySlug}/products/${oldSlug}/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        methods.reset({
          ...data,
          languages: createLanguagesOptions(data.languages),
        });
      },
      enabled: !!oldSlug && !!companySlug,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      cacheTime: Infinity,
    }
  );

  const { mutate, isLoading, error, isSuccess } = useMutation<
    ProductResponse,
    AxiosError,
    FormData
  >(
    async product => {
      try {
        {
          if (oldSlug) {
            const { data } = await axios.patch(
              `/companies/${companySlug}/products/${oldSlug}/`,
              product
            );
            return data;
          } else {
            const { data } = await axios.post(
              `/companies/${companySlug}/products/`,
              product
            );
            return data;
          }
        }
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: product => {
        setIsFirstStepDone(true);
        setSearchParams({ productSlug: product.slug });

        methods.reset({
          ...product,
          languages: createLanguagesOptions(product.languages),
        });
      },
    }
  );

  const onSubmit = (data: ProductFormValues) => {
    if (isFirstStepDone) return;

    const formData = new FormData();

    const entries = Object.entries(data);
    entries.forEach(([key, value]) => {
      if (typeof value === 'string') {
        formData.set(key, value);
      }
    });

    if (!!data?.languages?.length) {
      const languagesArray = data.languages.map(item => {
        return item.value;
      });
      languagesArray.forEach(item => formData.append('languages', item));
    } else {
      formData.append('languages', '');
    }

    if (typeof data.background_image !== 'string') {
      formData.set('background_image', data.background_image?.file || '');
    } else {
      formData.delete('background_image');
    }

    if (typeof data.background_image_original !== 'string') {
      formData.set(
        'background_image_original',
        data.background_image_original || ''
      );
    } else {
      formData.delete('background_image_original');
    }

    if (typeof data.product_photo !== 'string') {
      formData.set('product_photo', data.product_photo?.file || '');
    } else {
      formData.delete('product_photo');
    }
    if (typeof data.product_photo_original !== 'string') {
      formData.set('product_photo_original', data.product_photo_original || '');
    } else {
      formData.delete('product_photo_original');
    }

    mutate(formData);
  };

  return (
    <>
      <Header
        isFirstStepDone={isFirstStepDone}
        isPreviewMode={isPreviewMode}
        setIsPreviewMode={setIsPreviewMode}
      />

      {isPreviewMode && (
        <ProductPagePreview
          values={methods.getValues()}
          productResponse={productData}
        />
      )}
      <main className={styles.container}>
        {(isLoading || isLoadingGetProduct) && <LoaderScreen />}
        <FirstStep
          methods={methods}
          isFirstStepDone={isFirstStepDone}
          onSubmit={onSubmit}
          error={error}
          setIsFirstStepDone={setIsFirstStepDone}
        />
        <div className={styles.mainWidth}>
          <CompanyFeatures
            isFirstStepDone={isFirstStepDone}
            companySlug={companySlug}
            productId={productData?.id}
            isProductJustSubmitted={isSuccess}
          />
          <PricingPlans
            isFirstStepDone={isFirstStepDone}
            companySlug={companySlug}
            productId={productData?.id}
            isProductJustSubmitted={isSuccess}
          />

          <AssetSorting
            isFirstStepDone={isFirstStepDone}
            ordering={productData?.product_asset_ordering}
            companySlug={companySlug}
          />

          <AssetsList
            title={t('manage-products.product-videos')}
            iconName={IconsNames.play_arrow}
            companySlug={companySlug}
            isFirstStepDone={isFirstStepDone}
            productId={productData?.id}
            contentType={AssetContentType.productVideo}
            productAssetOrdering={productData?.product_asset_ordering}
          />
          <AssetsList
            title={t('manage-products.advertise-videos')}
            iconName={IconsNames.play_arrow}
            companySlug={companySlug}
            isFirstStepDone={isFirstStepDone}
            productId={productData?.id}
            contentType={AssetContentType.advertiseVideo}
            productAssetOrdering={productData?.product_asset_ordering}
          />
          <AssetsList
            title={t('manage-products.whitepaper')}
            iconName={IconsNames.book}
            companySlug={companySlug}
            isFirstStepDone={isFirstStepDone}
            productId={productData?.id}
            contentType={AssetContentType.whitepaperEbook}
            productAssetOrdering={productData?.product_asset_ordering}
          />
          <AssetsList
            title={t('manage-products.case-studies')}
            iconName={IconsNames.case_study}
            companySlug={companySlug}
            isFirstStepDone={isFirstStepDone}
            productId={productData?.id}
            contentType={AssetContentType.caseStudy}
            productAssetOrdering={productData?.product_asset_ordering}
          />
          <AssetsList
            title={t('manage-products.webinars-on-demand')}
            iconName={IconsNames.on_demand_video}
            companySlug={companySlug}
            isFirstStepDone={isFirstStepDone}
            productId={productData?.id}
            contentType={AssetContentType.webinarOnDemand}
            productAssetOrdering={productData?.product_asset_ordering}
          />
        </div>
      </main>
    </>
  );
};

export default CreateProductContent;
