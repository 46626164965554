import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import type { ProductPricingPlanResponse } from 'components/Product/models';
import type { AxiosError } from 'axios';

import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import PricingPlanItem from 'components/Product/CreateProductContent/PricingPlans/PricingPlanItem';
import CreatePricingPlanModal from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal';
import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  companySlug?: string;
  productId?: number;
  isProductJustSubmitted: boolean;
};

const PricingPlans: React.FC<Props> = ({
  isFirstStepDone,
  companySlug,
  productId,
  isProductJustSubmitted,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { axios } = useAuth();

  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');

  const { data: pricingPlans, error } = useQuery<
    ProductPricingPlanResponse[],
    AxiosError
  >(
    ['get-pricing-plans', companySlug, productId?.toString()],
    async () => {
      try {
        const { data } = await axios.get<ProductPricingPlanResponse[]>(
          `/companies/${companySlug}/products/${productSlug}/pricing-plans/`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        !!productSlug &&
        !!companySlug &&
        !!productId &&
        !isProductJustSubmitted,
      refetchOnWindowFocus: false,
    }
  );

  const isAddButtonDisabled = !companySlug || !productId || !productSlug;

  const openCreateModal = () => {
    if (isAddButtonDisabled) return;
    openModal({
      Content: (
        <CreatePricingPlanModal
          companySlug={companySlug}
          productId={productId}
          productSlug={productSlug}
        />
      ),
    });
  };

  const isImagesWarningVisible =
    (pricingPlans?.length || 0) > 1 &&
    pricingPlans?.filter(plan => plan.images.length).length !==
      pricingPlans?.length;

  return (
    <SectionWrapper
      disabled={!isFirstStepDone}
      title={t('manage-products.pricing-plans')}
      iconName={IconsNames.view_carousel}
      buttonProps={{
        children: t('common.button.add'),
        onClick: openCreateModal,
        iconProps: { name: IconsNames.plus },
        disabled: isAddButtonDisabled,
      }}
    >
      {!!error && <Error message={getResponseError(error)} />}
      {!!pricingPlans?.length && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('common.field.name')}</th>
              <th>{t('common.field.period')}</th>
              <th>{t('common.field.price')}</th>
              <th>{t('manage-products.benefits')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pricingPlans.map(plan => (
              <PricingPlanItem
                key={plan.id}
                planData={plan}
                companySlug={companySlug}
                productSlug={productSlug}
                productId={productId}
              />
            ))}
          </tbody>
        </table>
      )}
      {isImagesWarningVisible && (
        <div className={styles.warning}>
          <IconSVG name={IconsNames.warning_filled} className={styles.icon} />
          {t('manage-products.pricing-plans-images-warning')}
        </div>
      )}
    </SectionWrapper>
  );
};

export default PricingPlans;
