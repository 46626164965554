import { useTranslation } from 'react-i18next';

import type { TotalCostResponse } from 'components/CreateCampaign/Step4/TotalCost';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  totalCampaignCost: TotalCostResponse;
  isPaymentRequired: boolean;
  transactionFee?: string;
};

const PriceDetails = ({
  totalCampaignCost,
  isPaymentRequired,
  transactionFee,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ul>
      <li className={styles.cost}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.people} />
        </span>
        <p>
          {totalCampaignCost.total_leads}
          <span>{t('common.field.total-leads')}</span>
        </p>
      </li>
      <li className={styles.cost}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.person_money} />
        </span>
        <p>
          ${totalCampaignCost.cost_per_lead}
          <span>{t('common.field.max-cpl')}</span>
        </p>
      </li>
      <li className={styles.cost}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.fee_dollar} />
        </span>
        <p>
          ${totalCampaignCost.platform_fee}
          <span>{t('common.field.platform-fee')}</span>
        </p>
      </li>
      <li className={styles.cost}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.fee_dollar} />
        </span>
        <p>
          ${totalCampaignCost.qc_fee}
          <span>{t('common.field.qc-fee')}</span>
        </p>
      </li>
      <li className={styles.cost}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.fee_dollar} />
        </span>
        <p>
          {isPaymentRequired && transactionFee
            ? `$${Number(transactionFee).toFixed(2)}`
            : '-'}
          <span>{t('common.field.processing-fee')}</span>
        </p>
      </li>
    </ul>
  );
};

export default PriceDetails;
