import * as Yup from 'yup';

import type { SelectOption } from 'types/models';

import { CustomDefaultValue } from 'constants/constants';
import { UnsubscribeOption } from 'components/UnsubscribeContent/models';

import { REQUIRED_MESSAGE, MAX_LENGTH_MESSAGE } from 'utils/validations';

const MAX_LENGTH_REASON = 500;

export const validationSchemaUnsubscribe = Yup.lazy(() =>
  Yup.object().shape({
    step: Yup.number(),
    unsubscribe_option: Yup.object().shape({ value: Yup.string() }),
    until_date: Yup.object().when('unsubscribe_option', {
      is: (option: SelectOption) =>
        option.value === UnsubscribeOption.snooze_receiving_emails,
      then: Yup.object()
        .shape({
          customValue: Yup.string()
            .optional()
            .test('customValue', REQUIRED_MESSAGE, function (value) {
              if (!value && this.parent.value === CustomDefaultValue.custom) {
                return false;
              }

              return true;
            }),
        })
        .required(REQUIRED_MESSAGE),
      otherwise: Yup.object().optional().nullable(),
    }),
    reason: Yup.string()
      .trim()
      .test('reason', function (value) {
        if (this.parent.step === 1) {
          const isReasonRequired =
            !value &&
            this.parent.unsubscribe_option.value !==
              UnsubscribeOption.snooze_receiving_emails;
          if (isReasonRequired) {
            return this.createError({
              path: this.path,
              message: REQUIRED_MESSAGE,
            });
          }

          const isReasonMoreThanMaxLength =
            value && value.length > MAX_LENGTH_REASON;
          if (isReasonMoreThanMaxLength) {
            return this.createError({
              message: {
                key: MAX_LENGTH_MESSAGE,
                values: { length: MAX_LENGTH_REASON },
              },
            });
          }
        }
        return true;
      }),
  })
);
