import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

import type { PluginHook, Row, TableInstance } from 'react-table';

import styles from '../index.module.scss';

type Props = {
  wrapperClassName?: string;
  headClassName?: string;
  rowClassName?: string;
  tableProps?: TableInstance<any>;
  plugins?: Array<PluginHook<{}>>;
  renderRowSubComponent?: ({ row }: { row: Row<{}> }) => JSX.Element;
  noDataComponent?: React.ReactNode;
  isFirstColumnFixed?: boolean;
  isLastColumnFixed?: boolean;
  isEvenRowGrey?: boolean;
  setSelectedRows?: React.Dispatch<React.SetStateAction<any>>;
};

const TableLayout = ({
  wrapperClassName,
  headClassName,
  rowClassName,
  renderRowSubComponent,
  noDataComponent,
  isFirstColumnFixed = false,
  isLastColumnFixed = false,
  isEvenRowGrey,
  tableProps,
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = tableProps || {};
  const { t } = useTranslation();

  return (
    <div className={cn(styles.wrapper, wrapperClassName)}>
      <table {...getTableProps?.()} className={styles.table}>
        <thead className={cn(styles.head, headClassName)}>
          {headerGroups?.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className={cn(styles.row, rowClassName)}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                  className={cn(styles.th, {
                    [styles.fixedFirstColumn]: isFirstColumnFixed,
                    [styles.fixedLastColumn]: isLastColumnFixed,
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps?.()} className={styles.body}>
          {!rows?.length && (
            <tr className={cn(styles.row, rowClassName)}>
              <td
                colSpan={visibleColumns?.length}
                className={cn(styles.td, styles.empty)}
              >
                {noDataComponent
                  ? noDataComponent
                  : t('common.error.empty-data')}
              </td>
            </tr>
          )}
          {rows?.map(row => {
            prepareRow?.(row);

            return (
              <Fragment key={row.getRowProps().key}>
                <tr
                  className={cn(styles.row, rowClassName, {
                    [styles.grey]: isEvenRowGrey,
                  })}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                >
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                      className={cn(styles.td, {
                        [styles.fixedFirstColumn]: isFirstColumnFixed,
                        [styles.fixedLastColumn]: isLastColumnFixed,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>

                {row.isExpanded && renderRowSubComponent ? (
                  <tr
                    className={cn(styles.row, rowClassName)}
                    {...row.getRowProps()}
                    key={`${row.getRowProps().key}-expanded`}
                  >
                    <td colSpan={visibleColumns?.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableLayout;
