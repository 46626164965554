import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import cn from 'classnames';

import type { Area, Size, CropperProps } from 'react-easy-crop';

import styles from './index.module.scss';

const ZOOM_STEP = 0.01;
const ZOOM_MIN = 0.01;
const ZOOM_MAX = 3;

export type Props = {
  imageHeight: number;
  imageWidth: number;
  setCroppedArea: React.Dispatch<React.SetStateAction<Area | null>>;
  value: string;
  className?: string;
  controllerClassName?: string;
  cropSize?: Size;
  objectFit?: CropperProps['objectFit'];
};

const ImageCropper = ({
  imageWidth,
  imageHeight,
  setCroppedArea,
  value,
  className,
  controllerClassName,
  cropSize,
  objectFit,
}: Props) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const handleCropComplete = useCallback(
    (croppedAreaPercentage: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  );

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scaleValue = parseFloat(e.target.value);
    setZoom(scaleValue);
  };

  const onPlus = () => {
    if (zoom >= ZOOM_MAX - ZOOM_STEP) return;
    setZoom(prev => prev + ZOOM_STEP);
  };

  const onMinus = () => {
    if (zoom <= ZOOM_MIN + ZOOM_STEP) return;
    setZoom(prev => prev - ZOOM_STEP);
  };

  return (
    <>
      <div className={cn(styles.editorWrapper, className)}>
        <Cropper
          image={value}
          crop={crop}
          zoom={zoom}
          aspect={imageWidth / imageHeight}
          onCropChange={setCrop}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
          restrictPosition={false}
          cropSize={cropSize}
          objectFit={objectFit}
        />
      </div>

      <div className={cn(styles.zoomWrapper, controllerClassName)}>
        <div className={styles.minus} onClick={onMinus}>
          <span />
        </div>
        <div className={styles.inputWrapper}>
          <input
            name="zoom"
            type="range"
            onChange={handleScale}
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            step={ZOOM_STEP}
            value={zoom}
            className={styles.zoom}
          />
          <div
            className={styles.progressBar}
            style={{
              width: `${((zoom - ZOOM_MIN) / (ZOOM_MAX - ZOOM_MIN)) * 100}%`,
            }}
          />
        </div>
        <div className={styles.plus} onClick={onPlus}>
          <span />
          <span />
        </div>
      </div>
    </>
  );
};

export default ImageCropper;
