import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { UseMutateFunction } from 'react-query';
import type { AxiosError } from 'axios';
import type { UseFieldArrayReplace } from 'react-hook-form';
import type { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';

import CropImageModal from 'components/common/CropImageModal';
import UploadFile from 'components/common/UploadFile';

import useModal from 'contexts/ModalContext';

import { createImageRenderLink } from 'helpers/files';
import { moveSortedItems } from 'helpers/dragAndDrop';

import {
  PRICING_IMAGE_WIDTH,
  PRICING_IMAGE_HEIGHT,
} from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/validationSchema';

import styles from '../../index.module.scss';

type Props = {
  index: number;
  replace: UseFieldArrayReplace<ProductPricingPlanFormValues, 'images'>;
  onMoveImage: (dragIndex: number, hoverIndex: number) => void;
  onDeleteImage: UseMutateFunction<
    void,
    AxiosError,
    { imageId?: number; imageUUID: string }
  >;
};

const PricingImage = ({ index, onMoveImage, onDeleteImage }: Props) => {
  const { t } = useTranslation();
  const { openNestedModal } = useModal();
  const { control, setValue, watch } =
    useFormContext<ProductPricingPlanFormValues>();
  const ref = useRef<HTMLLIElement>(null);

  const {
    field: { onChange, value },
  } = useController({
    control,
    name: `images.${index}.image`,
  });

  const imageItem = watch(`images.${index}`);
  const originalImage = imageItem.image_original;

  const [, dragRef] = useDrag({
    type: 'image',
    canDrag: !!value?.renderLink,
    item: { index, ...imageItem },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop<
    ProductPricingPlanFormValues['images'][0] & { index: number }
  >({
    accept: 'image',
    drop: item => {
      setValue(`images.${index}.order`, item.index);
      return { ...item, index };
    },
    hover: (item, monitor) => {
      moveSortedItems({
        item,
        monitor,
        currentIndex: index,
        itemRef: ref,
        onMove: onMoveImage,
        isSkipYCheck: true,
      });
    },
  });

  const handleDeleteImage = () => {
    onDeleteImage(
      { imageId: imageItem.id, imageUUID: imageItem.uuid },
      {
        onSuccess: () => {
          setValue(`images.${index}`, {
            image: null,
            image_original: null,
            order: index,
            uuid: uuidv4(),
          });
        },
      }
    );
  };

  const openCropImageModal = (imageSrc: string, file: File | null | string) => {
    openNestedModal({
      NestedModalContent: (
        <CropImageModal
          value={imageSrc}
          title={t('manage-products.cover-image')}
          imageHeight={PRICING_IMAGE_HEIGHT}
          imageWidth={PRICING_IMAGE_WIDTH}
          uploadImage={imageData => {
            onChange(imageData);
            if (imageData.originalFile) {
              setValue(
                `images.${index}.image_original`,
                imageData.originalFile
              );
            } else {
              setValue(`images.${index}.image_original`, file);
            }
          }}
          objectFit="horizontal-cover"
          isNestedModal
          onDeleteImage={handleDeleteImage}
          instanceName={t('common.field.image')}
          itemName={t('common.field.image')}
        />
      ),
    });
  };

  const insertImage = (
    file: File | null,
    renderLink: string | ArrayBuffer | null | undefined
  ) => {
    openCropImageModal(renderLink as string, file);
  };

  const handleEditImage = () => {
    if (!originalImage) return;

    if (typeof originalImage !== 'string') {
      createImageRenderLink(originalImage, src =>
        openCropImageModal(src, originalImage)
      );
    } else {
      openCropImageModal(originalImage, originalImage);
    }
  };

  const dragDropRef = dragRef(dropRef(ref));

  return (
    <li ref={dragDropRef as React.LegacyRef<HTMLLIElement>}>
      <UploadFile
        isTitleHidden
        accept={['image/jpeg', 'image/png']}
        onEdit={handleEditImage}
        onChangeOptions={{
          withPreview: true,
          insertSingle: insertImage,
          maxFiles: 1,
        }}
        imageSrc={value ? value.renderLink : undefined}
        editButtonClassName={styles.edit}
        isDraggable
        inputClasses={styles.dropzone}
      />
    </li>
  );
};

export default PricingImage;
