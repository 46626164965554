import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { SyncDataModalProps } from 'components/IntegrationsContent/models';

import ModalWindow from 'components/common/ModalWindow';
import LogStatus from 'components/IntegrationsContent/AdminContent/LogsTable/LogStatus';
import Button from 'components/common/Button';
import Table from 'components/common/Table';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

const SyncDataModal = ({
  syncData,
  status,
  date,
  email,
}: SyncDataModalProps) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const rowsData = Object.keys(syncData).map(key => {
    return {
      crm_field: key,
      pushed_value: syncData[key as keyof typeof syncData],
    };
  });

  const columnsData = useMemo(
    () => [
      { Header: t('integrations.crm-field'), accessor: 'crm_field' },
      { Header: t('integrations.pushed-value'), accessor: 'pushed_value' },
    ],
    []
  );

  return (
    <ModalWindow
      className={styles.modal}
      primaryTitle={t('integrations.sync-details')}
    >
      <div className={styles.heading}>
        <p className={styles.email}>{email}</p>
        <LogStatus status={status} className={styles.status} />
        <span className={styles.date}>
          {dayjs(date).utcOffset(0).format('DD MMM, YYYY, hh:mm A')}
        </span>
      </div>
      <div className={styles.content}>
        <Table
          tableOptions={{
            columns: columnsData,
            data: rowsData || [],
          }}
          isEvenRowGrey
          headClassName={styles.thead}
        />
      </div>
      <div className={styles.bottom}>
        <Button white onClick={closeModal}>
          {t('common.button.close')}
        </Button>
      </div>
    </ModalWindow>
  );
};

export default SyncDataModal;
