import { useFormContext, useController, useWatch } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AssetValues } from 'components/CreateCampaign/models';

import ImageCropper from 'components/common/ImageCropper';
import ChangeImageButton from 'components/common/ImageCropper/ChangeImageButton';
import Error from 'components/common/Error';
import UploadFile from 'components/common/UploadFile';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const IMAGE_WIDTH = 544;
const IMAGE_HEIGHT = 332;
const MAX_THUMBNAIL_FILE_SIZE = 3145728; // 3mb

type Props = {
  handleOpenDeleteImageModal: (isOpen: boolean) => void;
};

const Step2 = ({ handleOpenDeleteImageModal }: Props) => {
  const { t } = useTranslation();
  const [uploadFileError, setUploadFileError] = useState('');
  const { control, setValue } = useFormContext<AssetValues>();
  const [isEditImage, setIsEditImage] = useState(false);
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: 'landing_image',
  });

  const { field } = useController({
    name: 'landingImageCroppingArea',
    control,
  });
  const landingImageOriginal = useWatch({
    control,
    name: 'landing_image_original',
  });

  const handleChangeImage = ({
    renderLink,
    originalFile,
  }: {
    originalFile: File | null;
    renderLink: string;
  }) => {
    setIsEditImage(true);
    setValue('landing_image.renderLink', renderLink);
    setValue('landing_image_original', originalFile);
  };

  const handleDeleteImage = () => {
    handleOpenDeleteImageModal(true);
  };

  const isCropComponentVisible = (isEditImage || !!field.value) && value;

  return (
    <div className={styles.wrapper}>
      {isCropComponentVisible && (
        <ImageCropper
          value={value.renderLink}
          setCroppedArea={field.onChange}
          imageWidth={IMAGE_WIDTH}
          imageHeight={IMAGE_HEIGHT}
          className={styles.cropper}
          controllerClassName={styles.control}
          cropSize={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
        />
      )}
      {!isCropComponentVisible && (
        <UploadFile
          accept={['image/jpeg', 'image/png']}
          imageSrc={value?.renderLink}
          wrapperClasses={styles.dropzone}
          maxSize={MAX_THUMBNAIL_FILE_SIZE}
          secondaryTitle={t('common.modal.thumbnail')}
          sign={t('common.field.optimal-dimensions', {
            dimensions: `${IMAGE_WIDTH} x ${IMAGE_HEIGHT} px`,
          })}
          secondarySign={`${t('common.field.formats', {
            formats: 'PNG, JPG',
          })}. ${t('common.field.max-size')}: 3Mb`}
          onEdit={() => {
            if (typeof landingImageOriginal === 'string')
              setValue('landing_image.renderLink', landingImageOriginal);
            setIsEditImage(true);
          }}
          onChangeOptions={{
            maxFiles: 1,
            insertSingle: (file, link) => {
              onChange({ file, renderLink: link as string });
              setValue('landing_image_original', file);
              setIsEditImage(true);
            },
            withPreview: true,
          }}
        />
      )}

      {value?.renderLink && (
        <div className={styles.buttons}>
          <Button
            iconProps={{ name: IconsNames.trash }}
            type="button"
            onClick={handleDeleteImage}
            white
            className={styles.delete}
          >
            {t('common.button.delete-image')}
          </Button>
          <ChangeImageButton
            className={styles.change}
            setValidationError={setUploadFileError}
            uploadImage={handleChangeImage}
          />
        </div>
      )}
      {uploadFileError && (
        <Error message={uploadFileError} className={styles.error} />
      )}
    </div>
  );
};

export default Step2;
