import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState, Fragment } from 'react';

import type { ProductPricingPlanResponse } from 'components/Product/models';
import type { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';
import type { AxiosResponse } from 'axios';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import { MAX_LENGTH_MESSAGE } from 'utils/validations';

import {
  PRICING_PLAN_BENEFIT_NAME_MAX_LENGTH,
  PRICING_BENEFITS_MAX_COUNT,
} from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/validationSchema';
import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  submitErrors?: AxiosResponse<ProductPricingPlanResponse>;
};

const Benefits = ({ submitErrors }: Props) => {
  const { t } = useTranslation();
  const [benefitName, setBenefitName] = useState('');
  const { control } = useFormContext<ProductPricingPlanFormValues>();
  const {
    fields: benefits,
    remove,
    prepend,
  } = useFieldArray({
    control,
    name: 'benefits',
    keyName: 'key',
  });

  const validBenefitName = benefitName?.trim();

  const isValidName =
    validBenefitName.length <= PRICING_PLAN_BENEFIT_NAME_MAX_LENGTH;

  const handleAddFeature = () => {
    if (!validBenefitName || !isValidName) return;
    prepend({ name: validBenefitName });
    setBenefitName('');
  };

  return (
    <fieldset>
      <legend>{t('manage-products.benefits')}</legend>
      <InputWrapper
        validationError={
          !isValidName
            ? {
                key: MAX_LENGTH_MESSAGE,
                values: { length: PRICING_PLAN_BENEFIT_NAME_MAX_LENGTH },
              }
            : ''
        }
        isErrorHidden={isValidName}
        isMediumInput
        label={t('manage-products.plan-benefits-tip', {
          count: PRICING_BENEFITS_MAX_COUNT,
        })}
        charactersLength={[
          validBenefitName.length,
          PRICING_PLAN_BENEFIT_NAME_MAX_LENGTH,
        ]}
        wrapperClasses={styles.inputWrapper}
      >
        <input
          type="text"
          placeholder={`${t('common.button.add')} ${t(
            'manage-products.benefits'
          ).toLowerCase()}`}
          value={benefitName}
          onChange={e => setBenefitName(e.target.value)}
          onKeyDown={event => {
            if (event.code === 'Enter') {
              event.preventDefault();
              handleAddFeature();
            }
          }}
          disabled={benefits.length >= PRICING_BENEFITS_MAX_COUNT}
        />
        {!!validBenefitName.length && isValidName && (
          <Button
            type="button"
            transparent
            onClick={handleAddFeature}
            className={styles.add}
          >
            {t('common.button.add')}
          </Button>
        )}
      </InputWrapper>
      <ul>
        {benefits.map(({ name, key }, index) => (
          <Fragment key={key}>
            <li className={styles.benefit}>
              <IconSVG
                name={IconsNames.success_checkmark}
                className={styles.checkmark}
              />
              <span className={styles.name}>{name}</span>
              <Button
                iconProps={{ name: IconsNames.close }}
                isOnlyIcon
                type="button"
                onClick={() => remove(index)}
              />
            </li>
            {submitErrors?.data.benefits?.[index] && (
              <Error
                message={submitErrors?.data.benefits?.[index]?.name?.[0]}
              />
            )}
          </Fragment>
        ))}
      </ul>
    </fieldset>
  );
};

export default Benefits;
