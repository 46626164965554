import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';

import type { Path } from 'react-hook-form';
import type { TwoFADevice } from 'components/Authorization/TwoFA/models';

import AuthHeader from 'components/common/AuthHeader';
import AuthForm from 'components/common/AuthForm';
import Button from 'components/common/Button';

import TwoFA from 'components/Authorization/TwoFA';
import TwoFaSelect from 'components/Authorization/TwoFA/TwoFASelect';

import useAuth from 'contexts/AuthContext';

import { PrivatePaths, PublicPaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

import { InputData } from 'types/models';

import { validationSchemaLogIn } from 'utils/validations';

import { getDevicesDictionary } from 'helpers/twoFA';

import styles from './index.module.scss';

type LogInData = {
  email: string;
  password: string;
};

type Field = InputData<LogInData> & { name: Path<LogInData> };

const defaultValues = {
  email: '',
  password: '',
};

const fields: Field[] = [
  {
    type: 'email',
    name: 'email',
    required: true,
    label: 'email',
  },
  {
    type: 'password',
    name: 'password',
    required: true,
    label: 'password',
  },
];

const LogInContent = () => {
  const navigate = useNavigate();
  const { axios: axiosInstance, setState } = useAuth();
  const [, setValue] = useLocalStorage(LocalStorageKeys.TOKEN);
  const [, setOTPDevice] = useLocalStorage(LocalStorageKeys.OTP_DEVICE_ID);
  const { t } = useTranslation();
  const [twoFADevice, setTwoFADevice] = useState<TwoFADevice>();

  const {
    mutate,
    isLoading,
    error,
    data: twoFADevices,
    isSuccess,
  } = useMutation<TwoFADevice[] | undefined, AxiosError, LogInData>(
    async (userData: LogInData) => {
      try {
        const { data: authData } = await axios.post<{
          auth_token: string;
          devices?: TwoFADevice[];
        }>(`${process.env.REACT_APP_BACKEND_URL}/auth/token/login/`, userData);
        setValue(authData.auth_token);

        return authData.devices;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async devices => {
        if (!devices || !devices.length) {
          setOTPDevice('FAKE DEVICE');
          const { data } = await axiosInstance.get('/accounts/profile/');
          setState({ user: data });
          navigate(PrivatePaths.INDEX);
        }
      },
    }
  );

  const onSubmit = (data: LogInData) => {
    mutate(data);
  };

  const forgotPasswordLink = (
    <Link to={`/${PublicPaths.RESET_PASSWORD}`} className={styles.link}>
      {t('auth.forgot-password')}
    </Link>
  );

  const navigateToSignUp = () => navigate(`/${PublicPaths.SIGN_UP}`);

  const devicesDictionary = getDevicesDictionary(twoFADevices);

  return isSuccess && devicesDictionary ? (
    <>
      {!twoFADevice?.type ? (
        <TwoFaSelect
          onDeviceChange={setTwoFADevice}
          devices={devicesDictionary}
        />
      ) : (
        <TwoFA device={twoFADevice} onDeviceChange={setTwoFADevice} />
      )}
    </>
  ) : (
    <div className={styles.wrapper}>
      <AuthHeader
        title={t('common.button.login')}
        subtitle={t('auth.not-logged')}
        hideLink
      />

      <AuthForm<LogInData>
        fields={fields}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        btnTitle="login"
        validationSchema={validationSchemaLogIn}
        sign={forgotPasswordLink}
        loading={isLoading}
        submitError={error}
        additionalButton={
          <Button
            type="button"
            className={styles.button}
            onClick={navigateToSignUp}
            isDarkBlueBordered
            isBig
          >
            {t('common.button.create-account')}
          </Button>
        }
      />
    </div>
  );
};

export default LogInContent;
