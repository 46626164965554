import { useState, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import type { Dispatch, SetStateAction } from 'react';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';
import { SelectOption, DateRangeOption } from 'types/models';

import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from './index.module.scss';

type Props = {
  label: string;
  selectedOptions: SelectOption[];
  category: string;
  setSearchParams: Dispatch<
    SetStateAction<Record<string, SelectOption[] | DateRangeOption>>
  >;
  translationPrefix?: string;
  getSubmenu?: ({
    setSearchParams,
    category,
    searchParams,
    setIsOptionsListVisible,
    translationPrefix,
  }: {
    setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
    category: string;
    searchParams: SelectOption[];
    setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
    translationPrefix?: string;
  }) => React.ReactElement;
};

const AppliedFilter = ({
  label,
  selectedOptions,
  category,
  setSearchParams,
  getSubmenu,
  translationPrefix,
}: Props) => {
  const { t } = useTranslation();

  const listRef = useRef(null);

  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);

  useEscapeEvent(() => {
    setIsOptionsListVisible(false);
  });

  useClickAway(listRef, () => {
    setIsOptionsListVisible(false);
  });

  const removeSearchCategory = () => {
    setSearchParams(prev => {
      const updatedParams = { ...prev };
      delete updatedParams[category];
      return updatedParams;
    });
  };

  return (
    <div className={styles.wrapper} ref={listRef}>
      <div className={styles.inputWrapper}>
        <div
          className={styles.labelWrapper}
          onClick={() => setIsOptionsListVisible(prev => !prev)}
        >
          <span className={styles.label}>
            {label}
            <span className={styles.number}>{selectedOptions.length}</span>
          </span>

          <IconSVG
            name={IconsNames.select_arrow}
            className={cn(styles.arrow, {
              [styles.active]: isOptionsListVisible,
            })}
          />
        </div>
        <div className={styles.border}>
          <IconSVG
            name={IconsNames.close}
            className={styles.close}
            onClick={removeSearchCategory}
          />
        </div>
      </div>
      {isOptionsListVisible && (
        <div className={cn(styles.menuWrapper)}>
          <p className={styles.heading}>{t('common.field.filter')}</p>
          {getSubmenu?.({
            setSearchParams: setSearchParams as Dispatch<
              SetStateAction<Record<string, SelectOption[]>>
            >,
            category: category,
            searchParams: selectedOptions,
            setIsOptionsListVisible: setIsOptionsListVisible,
            translationPrefix: translationPrefix,
          })}
        </div>
      )}
    </div>
  );
};

export default AppliedFilter;
