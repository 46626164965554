import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useMutation } from 'react-query';

import type { ProductAssetResponse } from 'types/models';

import CreateAssetModal from 'components/common/CreateAssetModal';
import LoaderScreen from 'components/common/LoaderScreen';

import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';
import AssetItem from 'components/Product/CreateProductContent/AssetsList/AssetItem';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import { useGetAssetsByContentType } from 'hooks/api/useGetAssetsByContentType';
import { useSaveProductAsset } from 'components/Product/CreateProductContent/useSaveProductAsset';

import { IconsNames } from 'constants/constants';
import { AssetContentType, ProductAssetsOrdering } from 'constants/assets';

type Props = {
  isFirstStepDone: boolean;
  companySlug?: string;
  productId?: number;
  contentType: AssetContentType;
  title: string;
  iconName: IconsNames;
  productAssetOrdering?: ProductAssetsOrdering;
};

const AssetsList = ({
  companySlug,
  productId,
  isFirstStepDone,
  contentType,
  iconName,
  title,
  productAssetOrdering,
}: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');
  const { openModal } = useModal();
  const { axios } = useAuth();
  const { mutate, isLoading: isCreateAssetLoading } = useSaveProductAsset({
    companySlug,
    productSlug,
    productId,
  });
  const { data: assets, refetch } = useGetAssetsByContentType({
    productId,
    productSlug,
    companySlug,
    contentType,
    productOrdering: productAssetOrdering,
    keepPreviousData: true,
  });

  const { mutate: moveAsset, isLoading } = useMutation(
    async ({ order, slug }: { order: number; slug: string }) => {
      try {
        const formData = new FormData();
        formData.set('order', order.toString());
        await axios.patch<ProductAssetResponse>(
          `/companies/${companySlug}/products/${productSlug}/assets/${slug}/`,
          formData
        );
        await refetch();
      } catch (err) {
        throw err;
      }
    }
  );

  const handleUploadAsset = () => {
    if (productSlug && companySlug) {
      openModal({
        Content: (
          <CreateAssetModal contentType={contentType} onSubmit={mutate} />
        ),
      });
    }
  };

  return (
    <SectionWrapper
      disabled={!isFirstStepDone}
      title={title}
      iconName={iconName}
      isSkipHorizontalPadding
      buttonProps={{
        children: t('common.button.add'),
        onClick: handleUploadAsset,
        iconProps: { name: IconsNames.upload_file },
      }}
      isLoading={isLoading}
    >
      {!!assets?.length && (
        <ul>
          <DndProvider backend={HTML5Backend}>
            {assets.map((asset, index) => (
              <AssetItem
                asset={asset}
                key={asset.id}
                productSlug={productSlug}
                companySlug={companySlug}
                index={index}
                productId={productId}
                isLoading={isLoading}
                onMoveAsset={moveAsset}
                assetsOrdering={productAssetOrdering}
                isDragDropEnabled={
                  productAssetOrdering === ProductAssetsOrdering.custom &&
                  assets.length > 1
                }
              />
            ))}
          </DndProvider>
        </ul>
      )}
      {isCreateAssetLoading && <LoaderScreen />}
    </SectionWrapper>
  );
};

export default AssetsList;
