import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { Path } from 'react-hook-form';

import AuthHeader from 'components/common/AuthHeader';
import AuthForm from 'components/common/AuthForm';
import AcceptTerms from 'components/common/AcceptTerms';

import type { InputData, SelectOption } from 'types/models';

import { validationSchemaSignUp } from 'utils/validations';

import { userDepartmentOptions } from 'constants/selectOptions';

import styles from './index.module.scss';

type Field = InputData<SignUpData> & { name: Path<SignUpData> };

type SignUpData = {
  id?: string;
  first_name: string;
  last_name: string;
  job_title: string | SelectOption;
  email: string;
  password: string;
  re_password?: string;
  company: {
    name: string;
    country_code: string | SelectOption;
    state?: string | SelectOption;
    city: string | SelectOption;
    address: string;
    department: string | SelectOption;
  };
  acceptTerms?: boolean;
  phone_number: string;
  token?: string;
};

const fields: Array<Field | Field[]> = [
  {
    type: 'text',
    name: 'first_name',
    required: true,
    label: 'name',
  },
  {
    type: 'text',
    name: 'last_name',
    required: true,
    label: 'surname',
  },
  {
    type: 'job-title',
    name: 'job_title',
    required: true,
    label: 'job-title',
  },
  {
    type: 'single-select',
    name: 'company.department',
    required: true,
    label: 'department',
    selectOptions: userDepartmentOptions,
  },
  {
    type: 'address',
    name: 'company.address',
    required: true,
    label: 'company-address',
  },
  {
    type: 'location',
    name: 'company',
    required: true,
  },
  {
    type: 'text',
    name: 'company.name',
    required: true,
    label: 'company-name',
  },
  {
    type: 'tel',
    name: 'phone_number',
    required: true,
    label: 'phone',
  },
  {
    type: 'email',
    name: 'email',
    required: true,
    label: 'email',
  },
  {
    type: 'password',
    name: 'password',
    required: true,
    label: 'password',
  },
  {
    type: 'password',
    name: 're_password',
    required: true,
    label: 'confirm-password',
  },
  {
    type: 'checkbox',
    name: 'acceptTerms',
    required: true,
    label: <AcceptTerms />,
  },
];

type Props = {
  isInvited?: boolean;
  defaultValues?: Partial<Omit<SignUpData, 'company'>> & {
    company?: Partial<SignUpData['company']>;
  };
};

const SignUpContent = ({ isInvited = false, defaultValues }: Props) => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { mutate, isLoading, error, isSuccess } = useMutation<
    SignUpData,
    AxiosError,
    SignUpData
  >(async userData => {
    try {
      const requestURI = isInvited
        ? `${process.env.REACT_APP_BACKEND_URL}/accounts/signup-invited/`
        : `${process.env.REACT_APP_BACKEND_URL}/accounts/signup/`;
      const { data } = await axios.post<SignUpData>(requestURI, userData);

      return data;
    } catch (err) {
      throw err;
    }
  });

  const onSubmit = (data: SignUpData) => {
    const {
      company: { city, state, country_code: country, department },
      job_title: jobTitle,
    } = data;

    const requestBody = {
      ...data,
      job_title: typeof jobTitle === 'string' ? jobTitle : jobTitle?.value,
      company: {
        ...data.company,
        department:
          typeof department === 'string' ? department : department.value,
        city: typeof city === 'string' ? city : city.value,
        country_code: typeof country === 'string' ? country : country.value,
        state: typeof state === 'string' ? state : state?.label,
      },
    };

    if (
      requestBody.company.country_code !== 'US' &&
      requestBody.company.country_code !== 'CA'
    ) {
      delete requestBody.company.state;
    }

    delete requestBody.re_password;
    delete requestBody.acceptTerms;
    if (isInvited) {
      mutate({ ...requestBody, token });
    } else {
      mutate(requestBody);
    }
  };

  const companyDefaultValue = {
    company: {
      department: {
        value: userDepartmentOptions[0],
        label: t(`common.field.${userDepartmentOptions[0]}`),
      },
    },
  };
  const formDefaultValues = isInvited ? defaultValues : companyDefaultValue;

  return (
    <div className={styles.wrapper}>
      <AuthHeader title={t('auth.create-account')} />

      {isSuccess ? (
        <>
          <p className={styles.success}>{t('auth.create-account-success')}</p>
          {!isInvited && <p>{t('auth.check-activation-email')}</p>}
        </>
      ) : (
        <AuthForm<SignUpData>
          fields={fields}
          isInvited={isInvited}
          onSubmit={onSubmit}
          btnTitle="sign-up"
          validationSchema={validationSchemaSignUp}
          loading={isLoading}
          submitError={error}
          defaultValues={formDefaultValues}
          disableAutocomplete={true}
        />
      )}
    </div>
  );
};

export default SignUpContent;
