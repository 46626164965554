import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames, LocalStorageKeys } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  isHidable: boolean;
  className?: string;
};

const DemoMessage = ({ isHidable, className }: Props) => {
  const { t } = useTranslation();
  const [isWarningHidden, setIsWarningHidden] = useLocalStorage<boolean>(
    LocalStorageKeys.IS_PRODUCT_PAGE_PREVIEW_WARNING_HIDDEN
  );

  const isVisible = !isHidable || (isHidable && !isWarningHidden);

  return isVisible ? (
    <div className={cn(styles.message, className)}>
      <IconSVG name={IconsNames.info} />
      <p className={styles.info}>{t('manage-products.preview-mode-message')}</p>
      {isHidable && (
        <button
          type="button"
          onClick={() => {
            setIsWarningHidden(true);
          }}
        >
          {t('common.button.hide-message')}
        </button>
      )}
    </div>
  ) : null;
};

export default DemoMessage;
