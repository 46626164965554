import { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { iso6393 as iso6393List } from 'iso-639-3';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { InputActionMeta, OptionProps, GroupBase } from 'react-select';
import type { SelectOption } from 'types/models';
import type { ProductFormValues } from 'components/Product/CreateProductContent/models';

import InputWrapper from 'components/common/InputWrapper';
import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';
import IconSVG from 'components/UI/IconSVG';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import { IconsNames } from 'constants/constants';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import styles from './index.module.scss';

const ENGLISH_CODE = 'eng';

type Props = {
  isFirstStepDone: boolean;
  error?: AxiosError | null;
};

const Option = <T extends SelectOption, IsMulti extends boolean>(
  props: OptionProps<T, IsMulti, GroupBase<T>> & {
    onClick: (item: SelectOption) => void;
  }
) => {
  return (
    <components.Option {...props}>
      <div
        className={styles.optionWrapper}
        onClick={() => {
          props.onClick(props.data);
        }}
      >
        <label aria-label="language option">{props.label}</label>
      </div>
    </components.Option>
  );
};

export const options = iso6393List
  .filter(item => item.iso6391)
  .map(item => ({
    label: item.name,
    value: item.iso6393,
  }))
  .sort(a => {
    return a.value === ENGLISH_CODE ? -1 : 1;
  });

const AboutProduct: React.FC<Props> = ({ isFirstStepDone, error }) => {
  const { t } = useTranslation();
  const { register, formState, control } = useFormContext<ProductFormValues>();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'languages',
  });

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setSearchValue(value);
    }
  };

  return (
    <SectionWrapper
      title={t('manage-products.product-details')}
      iconName={IconsNames.list}
      disabled={isFirstStepDone}
    >
      <div className={styles.overview}>
        <InputWrapper
          validationError={formState.errors?.description?.message}
          label="manage-products.overview"
        >
          <TextareaAutoHeight
            methods={register('description')}
            control={control}
            placeholder={t('common.field.enter-overview')}
            ariaLabel="description"
            className={styles.textarea}
          />
        </InputWrapper>
      </div>
      <div className={styles.details}>
        <div className={styles.container}>
          <div className={styles.columnWrapper}>
            <div className={styles.column}>
              <InputWrapper
                wrapperClasses={styles.inputContainer}
                label="common.field.website"
                validationError={formState.errors?.website?.message}
                submitError={error?.response?.data?.website[0]}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG name={IconsNames.website} className={styles.icon} />
                  <input
                    type="text"
                    placeholder={t('common.field.enter-website-url')}
                    {...register('website')}
                  />
                </div>
              </InputWrapper>
              <InputWrapper
                wrapperClasses={styles.inputContainer}
                label="common.field.instagram"
                validationError={formState.errors?.instagram_link?.message}
                submitError={error?.response?.data?.instagram_link[0]}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG
                    name={IconsNames.instagram}
                    className={styles.icon}
                  />
                  <input
                    type="text"
                    {...register('instagram_link')}
                    placeholder={t('common.field.instagram-placeholder')}
                  />
                </div>
              </InputWrapper>
              <InputWrapper
                wrapperClasses={styles.inputContainer}
                label="common.field.twitter"
                validationError={formState.errors?.twitter_link?.message}
                submitError={error?.response?.data?.twitter_link[0]}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG name={IconsNames.twitter} className={styles.icon} />
                  <input
                    type="text"
                    {...register('twitter_link')}
                    placeholder={t('common.field.twitter-placeholder')}
                  />
                </div>
              </InputWrapper>
            </div>
            <div className={styles.column}>
              <InputWrapper
                label="common.field.contact-email"
                wrapperClasses={styles.inputContainer}
                validationError={formState.errors?.email?.message}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG name={IconsNames.email} className={styles.icon} />
                  <input
                    type="text"
                    placeholder={t('script.type-email')}
                    {...register('email')}
                  />
                </div>
              </InputWrapper>
              <InputWrapper
                label="common.field.linkedin"
                wrapperClasses={styles.inputContainer}
                validationError={formState.errors?.linkedin_link?.message}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG name={IconsNames.linkedin} className={styles.icon} />
                  <input
                    type="text"
                    placeholder={t('common.field.linkedin-placeholder')}
                    {...register('linkedin_link')}
                  />
                </div>
              </InputWrapper>
              <InputWrapper
                label="common.field.facebook"
                wrapperClasses={styles.inputContainer}
                validationError={formState.errors?.facebook_link?.message}
              >
                <div className={styles.inputWrapper}>
                  <IconSVG name={IconsNames.facebook} className={styles.icon} />
                  <input
                    type="text"
                    {...register('facebook_link')}
                    placeholder={t('common.field.facebook-placeholder')}
                  />
                </div>
              </InputWrapper>
            </div>
          </div>
          <div className={styles.bottom}>
            <InputWrapper
              label="common.field.language-support"
              wrapperClasses={cn(styles.language, styles.inputContainer)}
            >
              <>
                <Select
                  aria-label="languages"
                  isMulti
                  options={options}
                  isClearable={false}
                  value={fields.map(item => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  closeMenuOnSelect={false}
                  noOptionsMessage={() => t('common.field.no-options')}
                  styles={getDefaultSelectStylesWithError({
                    isSmall: true,
                    isSelectorWithOutsideValueBox: true,
                  })}
                  placeholder={t('common.field.select')}
                  components={{
                    Option: props => (
                      <Option
                        {...{
                          ...props,
                          onClick: append,
                        }}
                      />
                    ),
                  }}
                  inputValue={searchValue}
                  onInputChange={handleInputChange}
                />
                <div className={styles.valueContainer}>
                  {fields?.map((item, index) => {
                    return (
                      <p key={item.value} className={styles.valueItem}>
                        {item.label}
                        <span
                          className={styles.delete}
                          onClick={() => remove(index)}
                        >
                          <IconSVG
                            name={IconsNames.close}
                            className={styles.deleteIcon}
                          />
                        </span>
                      </p>
                    );
                  })}
                </div>
              </>
            </InputWrapper>
            <InputWrapper
              label="manage-products.privacy-policy"
              wrapperClasses={cn(styles.inputWrapper, styles.privacyPolicy)}
              validationError={formState.errors?.privacy_policy_url?.message}
              isMediumInput
            >
              <input
                type="text"
                aria-label="privacy-policy-url"
                placeholder={t('common.field.privacy-policy-placeholder')}
                {...register('privacy_policy_url')}
              />
            </InputWrapper>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default AboutProduct;
