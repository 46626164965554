import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';

import type { AxiosError } from 'axios';
import type { ReportResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import FileItem from 'components/common/FileItem';
import UploadFile from 'components/common/UploadFile';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';

import UploadedReport from 'components/CampaignManage/VendorSettings/UploadedReport';

import IconSVG from 'components/UI/IconSVG';

import useAuth from 'contexts/AuthContext';
import useAsideContext from 'contexts/AsideContext';
import { useGetUploadedReport } from 'components/CampaignManage/VendorSettings/useGetUploadedReport';

import { IconsNames, ReportUploadStatus } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';
import { getFileIconFromFileName } from 'helpers/assets';

import styles from './index.module.scss';

type Props = {
  campaignId?: number;
  setParentProcessedFileId: React.Dispatch<React.SetStateAction<number | null>>;
};

const ReportModal = ({ campaignId, setParentProcessedFileId }: Props) => {
  const [processedFileId, setProcessedFileId] = useState<null | number>(null);
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { setAsideContent } = useAsideContext();
  const [newReportFile, setNewReportFile] = useState<null | File>(null);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  const { data, isLoading, error } = useQuery<ReportResponse[], AxiosError>(
    ['reports', campaignId?.toString()],
    async () => {
      try {
        const result = await axios.get<ReportResponse[]>(
          `/vendor-campaigns/${campaignId}/report-uploads/`
        );
        const isFileInProgress = result.data.find(
          file =>
            file.status !== ReportUploadStatus.done &&
            file.status !== ReportUploadStatus.failed
        );
        if (isFileInProgress) {
          setProcessedFileId(isFileInProgress.id);
          setParentProcessedFileId(isFileInProgress.id);
        }

        return result.data.filter(
          file =>
            file.status === ReportUploadStatus.done ||
            file.status === ReportUploadStatus.failed
        );
      } catch (err) {
        throw err;
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const { data: uploadProgressData, isLoading: uploadProgressLoading } =
    useGetUploadedReport({
      campaignId,
      processedFileId,
      setProcessedFileId: id => {
        setProcessedFileId(id);
        setParentProcessedFileId(id);
      },
    });

  const {
    mutate,
    isLoading: isUploadLoading,
    error: isUploadError,
  } = useMutation<ReportResponse, AxiosError, File>(
    async file => {
      try {
        const formData = new FormData();
        formData.append('report_file', file);

        const result = await axios.post<ReportResponse>(
          `/vendor-campaigns/${campaignId}/report-uploads/`,
          formData
        );

        return result.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: file => {
        setProcessedFileId(file.id);
        setParentProcessedFileId(file.id);
      },
    }
  );

  const FileIcon = newReportFile
    ? getFileIconFromFileName(newReportFile?.name)
    : null;

  return (
    <>
      <div className={styles.header}>
        <h3>{t('common.button.upload-report')}</h3>
        <button
          type="button"
          className={styles.close}
          onClick={() => setAsideContent(null)}
        >
          <IconSVG name={IconsNames.close} />
        </button>
      </div>

      {!isLoading && (
        <UploadFile
          isLoading={isUploadLoading || uploadProgressLoading}
          disabled={
            isLoading ||
            isUploadLoading ||
            uploadProgressLoading ||
            !!processedFileId
          }
          accept={[
            'text/csv',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          secondarySign={t('common.field.formats', {
            formats: 'CSV, XLS, XLSX',
          })}
          onChangeOptions={{
            maxFiles: 1,
            insertSingle: file => {
              setIsTermsAgreed(false);
              setNewReportFile(file);
            },
          }}
        >
          {newReportFile && (
            <FileItem
              name={newReportFile.name}
              deleteFile={() => setNewReportFile(null)}
              Icon={
                FileIcon ? <FileIcon className={styles.fileIcon} /> : undefined
              }
            />
          )}
        </UploadFile>
      )}

      {!!newReportFile && (
        <>
          <InputWrapper
            label="reports.report-upload-terms"
            wrapperClasses={styles.agree}
            isErrorHidden
          >
            <input
              type="checkbox"
              checked={isTermsAgreed}
              onChange={() => setIsTermsAgreed(prev => !prev)}
            />
          </InputWrapper>
          <Button
            type="button"
            disabled={!isTermsAgreed}
            onClick={() => {
              mutate(newReportFile);
              setIsTermsAgreed(false);
              setNewReportFile(null);
            }}
          >
            {t('common.button.upload')}
          </Button>
        </>
      )}

      {uploadProgressData && processedFileId && (
        <div className={styles.uploading}>
          <UploadedReport data={uploadProgressData} />
        </div>
      )}

      {isUploadError && <Error message={getResponseError(isUploadError)} />}

      {!!data?.length && (
        <>
          <h4 className={styles.uploaded}>{t('reports.uploaded-files')}</h4>
          <ul className={styles.list}>
            {data?.map(report => (
              <li key={report.id}>
                <UploadedReport data={report} />
              </li>
            ))}
          </ul>
        </>
      )}

      {isLoading && (
        <div className={styles.dropzone}>
          <Loader />
        </div>
      )}

      {error && <Error message={getResponseError(error)} />}
    </>
  );
};

export default ReportModal;
