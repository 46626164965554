import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import axios from 'axios';

import type { AxiosError } from 'axios';

import useAuth, { User } from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { PrivatePaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const useVerifyOTPCode = (isSkipNavigation?: boolean) => {
  const { axios: axiosInstance, setState } = useAuth();
  const navigate = useNavigate();
  const [, setOTPDevice] = useLocalStorage(LocalStorageKeys.OTP_DEVICE_ID);
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const mutation = useMutation<
    { device_id: string; token: string },
    AxiosError,
    { device_id: string; token: string }
  >(
    async requestBody => {
      try {
        await axios.post<{ device_id: string; token: string }>(
          `${process.env.REACT_APP_BACKEND_URL}/otp/verify/`,
          requestBody
        );
        setOTPDevice(requestBody.device_id);

        return requestBody;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async device => {
        const oldProfileData = queryClient.getQueryData<User>('profile');
        if (oldProfileData) {
          queryClient.setQueryData<User>('profile', {
            ...oldProfileData,
            devices: oldProfileData.devices.map(item => ({
              ...item,
              confirmed:
                item.persistent_id === device.device_id ? true : item.confirmed,
            })),
          });
          closeModal();

          return;
        }

        const { data } = await axiosInstance.get('/accounts/profile/');
        setState({ user: data });
        closeModal();
        if (!isSkipNavigation) navigate(PrivatePaths.INDEX);
      },
    }
  );

  return mutation;
};

export default useVerifyOTPCode;
