import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCaptcha from 'react-google-recaptcha';
import cn from 'classnames';

import type {
  ScheduleDemoValues,
  ScheduleDemoResponse,
} from 'components/Product/ProductPage/models';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import Button from 'components/common/Button';
import Steps from 'components/common/Steps';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import TimeSlotPicker from 'components/Product/ProductPage/GetDemoModal/TimeSlotPicker';
import UserInfo from 'components/Product/ProductPage/GetDemoModal/UserInfo';
import DemoMessage from 'components/Product/CreateProductContent/ProductPagePreview/DemoMessage';

import { useScheduleDemo } from 'components/Product/ProductPage/GetDemoModal/useScheduleDemo';
import useEnabledFeatures from 'contexts/EnabledFeaturesContext';
import useModal from 'contexts/ModalContext';

import { validationSchemaGetADemo } from 'components/Product/ProductPage/GetDemoModal/validationSchema';

import { createAddToCalendarLink } from 'helpers/createAddToCalendarLink';

import { StepNavigation, IconsNames } from 'constants/constants';
import { ToggledFeatures } from 'constants/features';

import styles from './index.module.scss';

enum StepName {
  'share-data' = 'share-data',
  'choose-time-slot' = 'choose-time-slot',
}

const steps = [
  {
    name: StepName['choose-time-slot'],
    title: 'manage-products.choose-time-slot',
    index: 0,
    Component: TimeSlotPicker,
  },
  {
    name: StepName['share-data'],
    title: 'manage-products.share-data',
    index: 1,
    Component: UserInfo,
  },
];

type Props = {
  companySlug?: string;
  productSlug?: string;
  companyName?: string;
  defaultValues: Partial<ScheduleDemoValues> & { email: string };
  isPreviewMode?: boolean;
};

const GetDemoModal: React.FC<Props> = ({
  companySlug,
  productSlug,
  defaultValues,
  companyName,
  isPreviewMode,
}) => {
  const [step, setStep] = useState(steps[0]);
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { getIsFeatureEnabled } = useEnabledFeatures();
  const isReCaptchaEnabled = getIsFeatureEnabled(
    ToggledFeatures.recaptcha_on_get_a_demo
  );

  const methods = useForm<ScheduleDemoValues>({
    defaultValues,
    resolver:
      step.name === StepName['share-data']
        ? yupResolver(validationSchemaGetADemo(isReCaptchaEnabled))
        : undefined,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useScheduleDemo({
    companySlug,
    productSlug,
  });

  const goToStep = (name: StepName | StepNavigation) => {
    const stepIndex = steps.findIndex(item => item.name === name);
    if (typeof stepIndex === 'number') setStep(steps[stepIndex]);
  };

  const isTimePicked = !!methods.watch('date') && !!methods.watch('timeslot');

  const openAddToMyCalendarModal = (data: ScheduleDemoResponse) => {
    openModal({
      Content: (
        <ModalWindow className={styles.modal}>
          <div className={styles.modal_success}>
            <div className={styles.iconWrapper}>
              <IconSVG
                name={IconsNames.success_checkmark}
                className={styles.icon}
              />
            </div>
            <h1 className={styles.heading}>
              {t('common.modal.thanks-for-request')}
            </h1>
            <p className={styles.description}>
              {t('common.modal.contact-you-soon')}
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={createAddToCalendarLink({
                timeslot: data.timeslot,
                timezone: data.client_timezone,
                leadCompanyName: data.company_name,
                companyName: companyName || '',
              })}
            >
              <Button className={styles.calendar}>
                {t('common.button.add-to-my-calendar')}
              </Button>
            </a>
            {isPreviewMode && (
              <DemoMessage isHidable={false} className={styles.demo} />
            )}
          </div>
        </ModalWindow>
      ),
    });
  };

  const onSubmit = (data: ScheduleDemoValues) => {
    const formattedData = {
      ...data,
      job_title: data.job_title.value,
      timeslot: `${data.date}${data.timeslot}`,
      client_timezone: data.client_timezone.value,
    };

    if (isPreviewMode) {
      openAddToMyCalendarModal(formattedData);
      return;
    }

    mutate(formattedData, { onSuccess: openAddToMyCalendarModal });
  };

  const recaptchaError = methods.formState.errors.recaptcha?.message;

  return (
    <>
      <ModalWindow
        primaryTitle={t('common.button.get-demo')}
        className={cn(styles.modalContainer, {
          [styles.withRecaptcha]: isReCaptchaEnabled,
        })}
        ariaLabel="get a demo modal"
        closeOnClickAway={false}
      >
        <Steps<StepName>
          steps={steps}
          step={step}
          goToStep={goToStep}
          className={styles.steps}
        />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            aria-label="get a demo form"
          >
            <step.Component />

            <div className={styles.buttonContainer}>
              {step.index === 0 ? (
                <Button
                  className={styles.button}
                  aria-label="choose a time slot"
                  disabled={!isTimePicked}
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    goToStep(StepName['share-data']);
                  }}
                >
                  {t('common.button.choose-a-time-slot')}
                </Button>
              ) : (
                <>
                  {isReCaptchaEnabled && (
                    <div
                      className={styles.recaptcha}
                      aria-label="google re-captcha"
                    >
                      <ReCaptcha
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        onChange={val => {
                          methods.setValue('recaptcha', val, {
                            shouldValidate: true,
                          });
                        }}
                      />
                      {recaptchaError && (
                        <Error
                          message={t(recaptchaError)}
                          className={styles.error}
                        />
                      )}
                    </div>
                  )}
                  <Button
                    type="submit"
                    className={styles.button}
                    aria-label="schedule demo"
                  >
                    {t('common.button.schedule-a-demo')}
                  </Button>
                </>
              )}
            </div>
          </form>
        </FormProvider>
      </ModalWindow>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default GetDemoModal;
