import { TwoFATypes, TwoFADevice } from 'components/Authorization/TwoFA/models';

import TwoFAWrapper from 'components/Authorization/TwoFA/TwoFAWrapper';
import TwoFAPhone from 'components/Authorization/TwoFA/TwoFAPhone';
import TwoFAEmail from 'components/Authorization/TwoFA/TwoFAEmail';
import TwoFAApp from 'components/Authorization/TwoFA/TwoFAApp';

type Props = {
  device: TwoFADevice;
  onDeviceChange?: React.Dispatch<
    React.SetStateAction<TwoFADevice | undefined>
  >;
};

const TwoFA = ({ device, onDeviceChange }: Props) => {
  const render = (tfaType: TwoFATypes | undefined) => {
    switch (tfaType) {
      case TwoFATypes.sms:
        return <TwoFAPhone device={device} onDeviceChange={onDeviceChange} />;
      case TwoFATypes.email:
        return <TwoFAEmail device={device} onDeviceChange={onDeviceChange} />;
      case TwoFATypes.totp:
        return <TwoFAApp device={device} onDeviceChange={onDeviceChange} />;
      default:
        return null;
    }
  };

  return <TwoFAWrapper>{render(device.type)}</TwoFAWrapper>;
};

export default TwoFA;
