const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

const MAX_CANVAS_AREA = 268435456 / 2;

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { width: number; height: number; x: number; y: number } | null
) {
  if (!pixelCrop) return;

  const image = await createImage(imageSrc);

  const resultArea = pixelCrop.width * pixelCrop.height;
  let resultWidth = pixelCrop.width;
  let resultHeight = pixelCrop.height;
  let resultX = pixelCrop.x;
  let resultY = pixelCrop.y;

  if (resultArea > MAX_CANVAS_AREA) {
    const proportion = MAX_CANVAS_AREA / resultArea;
    resultWidth *= proportion;
    resultHeight *= proportion;
    image.width *= proportion;
    image.height *= proportion;
    resultX *= proportion;
    resultY *= proportion;
  }

  const canvas = document.createElement('canvas');
  canvas.width = resultWidth;
  canvas.height = resultHeight;
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

  if (!ctx) return;

  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(image, resultX * -1, resultY * -1, image.width, image.height);

  return fetch(canvas.toDataURL('image/png'))
    .then(res => res.blob())
    .then(blob => ({
      file: new File([blob], 'image.png', {
        type: 'image/png',
      }),
      renderLink: canvas.toDataURL('image/png'),
    }));
}
