import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { FileRejection } from 'react-dropzone';

import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';
import Button from 'components/common/Button';

import { validateUploadedFiles, createImageRenderLink } from 'helpers/files';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  accept?: string | string[];
  sign?: string;
  title?: string;
  wrapperClasses?: string;
  inputClasses?: string;
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  imageSrc?: string;
  isTitleHidden?: boolean;
  maxSize?: number;
  secondaryTitle?: string;
  errorFilledImageClassName?: string;
  errorEmptyImageClassName?: string;
  backgroundImageClassName?: string;
  secondarySign?: string;
  tip?: string;
  tooltipClassName?: string;
  onEdit?: () => void;
  editButtonClassName?: string;
  isDraggable?: boolean;
  onChangeOptions:
    | {
        maxFiles?: undefined;
        uploadedFilesLength: number;
        insertMultiple: (
          uploadedFilesLength: number,
          files: File[],
          renderLink?: string
        ) => void;
      }
    | {
        withPreview?: boolean;
        maxFiles: 1;
        insertSingle: (
          file: File | null,
          renderLink?: string | ArrayBuffer | null | undefined
        ) => void;
      };
};

const UploadFile = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      sign,
      title,
      wrapperClasses,
      accept,
      children,
      onChangeOptions,
      isLoading,
      error,
      disabled,
      imageSrc,
      isTitleHidden,
      inputClasses,
      maxSize,
      secondaryTitle,
      errorFilledImageClassName,
      backgroundImageClassName,
      secondarySign,
      tooltipClassName,
      editButtonClassName,
      tip,
      onEdit,
      isDraggable,
      errorEmptyImageClassName,
    },
    ref
  ) => {
    const [validationError, setError] = useState('');
    const { t } = useTranslation();
    const tooltipOptions = usePopperTooltip({
      placement: 'top',
    });

    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setError('');
      const isValidationError = validateUploadedFiles({
        files: acceptedFiles,
        maxSize,
        t,
      });
      if (isValidationError) {
        return setError(isValidationError);
      }
      if (rejectedFiles.length) {
        const firstRejected = rejectedFiles[0];
        setError(firstRejected.errors[0].message);
      }

      if (!acceptedFiles.length) return;

      if (onChangeOptions.maxFiles === 1) {
        if (onChangeOptions.withPreview) {
          createImageRenderLink(acceptedFiles[0], src =>
            onChangeOptions.insertSingle(acceptedFiles[0], src)
          );
        } else {
          onChangeOptions.insertSingle(acceptedFiles[0]);
        }

        return;
      }

      const { insertMultiple, uploadedFilesLength } = onChangeOptions;
      insertMultiple(uploadedFilesLength, acceptedFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      maxFiles: onChangeOptions.maxFiles,
      onDrop,
      accept,
      noClick: Boolean(imageSrc && onEdit),
      disabled: isLoading || disabled,
      validator: (file: File) => {
        const fileValidationError = validateUploadedFiles({
          files: file,
          t,
        });

        return fileValidationError
          ? {
              message: fileValidationError,
              code: 'code',
            }
          : null;
      },
    });

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onEdit?.();
    };

    const errorMessage = validationError || error;
    const isEditButtonVisible = onEdit && imageSrc;

    return (
      <>
        <div className={wrapperClasses}>
          <div className={styles.wrapper}>
            {title && <h3 className={styles.heading}>{t(title)}</h3>}

            <div
              {...getRootProps()}
              className={cn(styles.dropzone, inputClasses, {
                [styles.hasEditButton]: isEditButtonVisible,
                [styles.dragActive]: isDragActive,
                [styles.hasImage]: imageSrc,
              })}
            >
              {tip && (
                <IconSVG
                  name={IconsNames.help}
                  className={styles.tip}
                  ref={tooltipOptions.setTriggerRef}
                />
              )}

              {tooltipOptions.visible && (
                <SimpleTooltip
                  options={tooltipOptions}
                  className={tooltipClassName}
                >
                  {tip}
                </SimpleTooltip>
              )}
              {isLoading && (
                <div className={styles.loader}>
                  <Loader />
                </div>
              )}
              {imageSrc && (
                <div
                  className={cn(
                    styles.backgroundImage,
                    backgroundImageClassName
                  )}
                >
                  <img src={imageSrc} alt="preview" />
                </div>
              )}

              <IconSVG name={IconsNames.upload_file} className={styles.icon} />
              <input
                /* @ts-ignore */
                {...{ ...getInputProps(), ref: ref || getInputProps().ref }}
                aria-label="upload file"
                multiple={onChangeOptions.maxFiles !== 1}
              />
              {!isTitleHidden && (
                <>
                  <p
                    className={cn(styles.title, {
                      [styles.customTitle]: !!secondaryTitle,
                    })}
                  >
                    {secondaryTitle || t('common.field.drag-drop')}
                  </p>
                  {!!sign && (
                    <p
                      className={cn(styles.sign, {
                        [styles.sign__default]: !secondaryTitle,
                      })}
                    >
                      {sign}
                    </p>
                  )}
                  {secondarySign && (
                    <span className={styles.secondarySign}>
                      {secondarySign}
                    </span>
                  )}
                </>
              )}

              {isEditButtonVisible && (
                <div
                  className={cn(styles.edit, {
                    [styles.draggable]: isDraggable,
                  })}
                >
                  {isDraggable && (
                    <IconSVG
                      name={IconsNames.drag_indicator}
                      className={styles.drag}
                    />
                  )}
                  <Button
                    iconProps={{ name: IconsNames.edit }}
                    white
                    className={cn(styles.edit__button, editButtonClassName)}
                    type="button"
                    onClick={handleEdit}
                  />
                </div>
              )}

              <Error
                message={!imageSrc ? errorMessage || '' : ''}
                className={cn(styles.error, errorEmptyImageClassName)}
              />
            </div>
          </div>
          {children}
        </div>
        {imageSrc && errorMessage && (
          <Error
            message={errorMessage || ''}
            className={cn(styles.error, errorFilledImageClassName)}
          />
        )}
      </>
    );
  }
);

export default UploadFile;
