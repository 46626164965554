import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { ProductPricingPlanResponse } from 'components/Product/models';

import PricingCard from 'components/Product/ProductPage/PricingPlans/PricingCard';
import SectionWrapper from 'components/Product/CreateProductContent/SectionWrapper';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  pricingPlans?: ProductPricingPlanResponse[];
  isAssetsExists: boolean;
};

const PricingPlans: React.FC<Props> = ({ pricingPlans, isAssetsExists }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper
      title={t('manage-products.pricing-plans')}
      iconName={IconsNames.view_carousel}
      withoutBorder
      className={cn(styles.container, { [styles.hasBorder]: isAssetsExists })}
    >
      <ul className={styles.wrapper}>
        {!!pricingPlans?.length &&
          pricingPlans.map(plan => {
            return <PricingCard key={plan.id} planData={plan} />;
          })}
      </ul>
    </SectionWrapper>
  );
};

export default PricingPlans;
