import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';

import PageContainer from 'components/common/PageContainer';
import Hubspot from 'components/IntegrationsContent/Hubspot';
import Card from 'components/IntegrationsContent/AdminContent/Card';
import Error from 'components/common/Error';
import LoaderScreen from 'components/common/LoaderScreen';

import useEnabledFeatures from 'contexts/EnabledFeaturesContext';
import useAuth from 'contexts/AuthContext';

import type { CRMIntegration } from 'components/IntegrationsContent/models';

import { ToggledFeatures } from 'constants/features';
import { UserTypes } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const AdminContent = () => {
  const { user, axios } = useAuth();
  const { getIsFeatureEnabled } = useEnabledFeatures();

  const {
    data: CRMIntegrationsData,
    isLoading,
    error,
  } = useQuery<CRMIntegration[], AxiosError>(
    ['crm-connections', user?.current_group_name, user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<CRMIntegration[]>(
          `/crm-integrations/api/${user?.company.id}/connections/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: user?.current_group_name === UserTypes.admin }
  );

  const isAllCRMsEnabled = getIsFeatureEnabled(ToggledFeatures.integrations);

  return (
    <PageContainer path={['integrations.title.admin']} isWhiteBackground>
      {isAllCRMsEnabled && !isLoading
        ? CRMIntegrationsData && (
            <div className={styles.wrapper}>
              {CRMIntegrationsData.map(item => {
                return (
                  <Card
                    key={item.id}
                    title={item.platform}
                    status={item.status}
                    type={item.connection_type}
                    id={item.id}
                  />
                );
              })}
            </div>
          )
        : !isLoading && <Hubspot />}
      {isLoading && <LoaderScreen />}
      {error && <Error message={getResponseError(error)} />}
    </PageContainer>
  );
};

export default AdminContent;
