import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import axios from 'axios';

import type { AxiosError } from 'axios';
import type { ScheduleDemoResponse } from 'components/Product/ProductPage/models';

import ModalWindow from 'components/common/ModalWindow';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

export const useScheduleDemo = ({
  companySlug,
  productSlug,
}: {
  companySlug?: string;
  productSlug?: string;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const request = useMutation<
    ScheduleDemoResponse,
    AxiosError,
    ScheduleDemoResponse
  >(
    async getDemoData => {
      try {
        const { data } = await axios.post<ScheduleDemoResponse>(
          `${process.env.REACT_APP_BACKEND_URL}/companies/${companySlug}/products/${productSlug}/schedule-demo/`,
          getDemoData
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onError: error => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(error)}
            />
          ),
        });
      },
    }
  );

  return request;
};
