import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useClickAway, useCopyToClipboard } from 'react-use';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cn from 'classnames';

import type { ProductResponse } from 'types/models';

import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';

import defaultLogo from 'assets/images/product/default-image.svg';

import getLongStringView from 'helpers/getLongStringView';

import { IconsNames } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  product: ProductResponse;
  companySlug?: string;
};

const ProductItem: React.FC<Props> = ({ product, companySlug }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [isActionsVisible, setIsActionsVisible] = useState(false);
  const actionsRef = useRef(null);
  useClickAway(actionsRef, () => setIsActionsVisible(false));
  const {
    product_photo: logoSrc,
    name,
    created_by: createdBy,
    created_at: dateCreate,
    updated_by: updatedBy,
    updated_at: dateUpdate,
    slug: productSlug,
  } = product;

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = () => {
    copyToClipboard(`${window.location.origin}/${companySlug}/${productSlug}/`);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <li className={styles.item}>
      <div className={styles.name}>
        <div className={styles.logo}>
          <img src={logoSrc || defaultLogo} alt="logo" />
        </div>
        <p>{getLongStringView(name, 50)}</p>
        <IconSVG
          name={IconsNames.dots}
          className={styles.dots}
          onClick={() => setIsActionsVisible(prev => !prev)}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.info}>
          <p className={styles.author}>
            {t('manage-products.created')}: {createdBy.first_name}{' '}
            {createdBy.last_name}
          </p>
          <p className={styles.date}>{`${dayjs(dateCreate).format(
            'D MMMM YYYY'
          )} AT ${dayjs(dateCreate).format('h:mmA')}`}</p>
        </div>
        <div className={styles.info}>
          <p className={styles.author}>
            {t('manage-products.updated')}: {updatedBy.first_name}{' '}
            {updatedBy.last_name}
          </p>
          <p className={styles.date}>{`${dayjs(dateUpdate).format(
            'D MMMM YYYY'
          )} AT ${dayjs(dateUpdate).format('h:mmA')}`}</p>
        </div>
        <div
          className={cn(styles.actions, {
            [styles['actions-visible']]: isActionsVisible,
          })}
          ref={actionsRef}
        >
          <Link
            to={`/${companySlug}/${productSlug}/`}
            target="_blank"
            className={styles.link}
          >
            <Button iconProps={{ name: IconsNames.eye }} transparent isSmall>
              {t('common.button.view-as-customer')}
            </Button>
          </Link>
          <div className={styles.buttons}>
            <div className={styles.imageContainer} onClick={handleCopyClick}>
              <IconSVG name={IconsNames.copy} />

              {isCopied && (
                <p className={styles.copied}>{t('common.button.copied')}</p>
              )}
              <p className={styles.copied__mobile}>
                {isCopied ? t('common.button.copied') : t('common.button.copy')}
              </p>
            </div>
            <Link
              to={`${PrivatePaths.CREATE}/?productSlug=${productSlug}`}
              className={cn(styles.imageContainer, styles.edit)}
            >
              <IconSVG name={IconsNames.edit} className={styles.edit__icon} />
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
