import { Carousel } from 'react-responsive-carousel';
import cn from 'classnames';

import type { ProductPricingPlanImage } from 'components/Product/models';

import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  images: ProductPricingPlanImage[];
  navigationButtonsClassName?: string;
  isBig?: boolean;
};

const PlanImagesCarousel = ({
  images,
  navigationButtonsClassName,
  isBig,
}: Props) => {
  const isCarouselControlsVisible = images.length > 1;

  return (
    <div className={cn(styles.wrapper, { [styles.big]: isBig })}>
      <Carousel
        showArrows={isCarouselControlsVisible}
        showThumbs={(isBig && isCarouselControlsVisible) || false}
        showIndicators={isCarouselControlsVisible}
        showStatus={false}
        renderIndicator={(onClick, isSelected, index, label) => (
          <span
            onClick={e => {
              e.stopPropagation();
              onClick(e);
            }}
            className={cn(styles.indicator, { [styles.selected]: isSelected })}
            aria-label={label}
            key={index}
          />
        )}
        renderArrowPrev={(onClick, hasPrev, label) =>
          isCarouselControlsVisible && hasPrev ? (
            <Button
              white
              iconProps={{ name: IconsNames.arrow }}
              aria-label={label}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
              className={cn(
                styles.navigation,
                styles.prev,
                navigationButtonsClassName
              )}
            />
          ) : null
        }
        renderArrowNext={(onClick, hasNext, label) =>
          isCarouselControlsVisible && hasNext ? (
            <Button
              white
              iconProps={{ name: IconsNames.arrow }}
              aria-label={label}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
              className={cn(
                styles.navigation,
                styles.next,
                navigationButtonsClassName
              )}
            />
          ) : null
        }
      >
        {images.map(image => (
          <div key={image.id}>
            <img
              src={image.image}
              className={cn(styles.image, { [styles.bigImage]: isBig })}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PlanImagesCarousel;
