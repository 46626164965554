import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import cn from 'classnames';

import type { TwoFADevice } from 'components/Authorization/TwoFA/models';

import TwoFATitle from 'components/Authorization/TwoFA/TwoFATitle';
import TwoFAInput from 'components/Authorization/TwoFA/TwoFAInput';
import TwoFADescription from 'components/Authorization/TwoFA/TwoFADescription';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import useVerifyOTPCode from 'hooks/api/useVerifyOTPCode';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  device: TwoFADevice;
};

const Step2 = ({ device }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const { mutate: verify, isLoading, error } = useVerifyOTPCode(true);
  const [, copyToClipboard] = useCopyToClipboard();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    verify({
      device_id: device.persistent_id,
      token: value.replace(/ /g, ''),
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  const configURL = 'config_url' in device && new URL(device.config_url);
  const secretKey = configURL && configURL.searchParams.get('secret');

  const handleCopyClick = () => {
    if (secretKey) {
      copyToClipboard(secretKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.clause}>
        <span className={styles.number}>1</span>
        <TwoFATitle className={styles.title}>{t('auth.go-to-app')}</TwoFATitle>
        <p className={styles.description}>
          <span>{t('auth.download-auth-app')}</span>
          <a
            href="https://authy.com/download/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Authy,{' '}
          </a>
          <a
            href="https://support.google.com/accounts/answer/1066447"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Google Authenticator,{' '}
          </a>
          <a
            href="https://www.microsoft.com/en-us/security/mobile-authenticator-app#getapp"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Windows Authenticator,{' '}
          </a>
          <span>{t('auth.or-any-app')}</span>
        </p>
      </div>
      <div className={styles.clause}>
        <span className={styles.number}>2</span>
        {'config_url' in device ? (
          <TwoFATitle className={styles.title}>
            {t('auth.scan-qr-code')}
          </TwoFATitle>
        ) : (
          <TwoFADescription text="auth.please-enter-2-factor-code" />
        )}
        <p className={styles.description}>{t('auth.open-and-scan')}</p>
      </div>
      {'config_url' in device && (
        <>
          <div className={styles.qrcode}>
            <QRCodeSVG
              value={device.config_url}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
              includeMargin={false}
            />
          </div>
          <p className={styles.link}>
            <span className={styles.key}>{secretKey}</span>
            <button
              type="button"
              className={cn(styles.copy, { [styles.success]: isCopied })}
              onClick={handleCopyClick}
            >
              <IconSVG name={IconsNames.copy_success} />
              {!!isCopied && (
                <span className={styles.copied}>
                  {t('common.button.copied')}
                </span>
              )}
            </button>
          </p>
          <p className={styles.description}>{t('auth.enter-key')}</p>
        </>
      )}
      <form>
        <div className={styles.clause}>
          <span className={styles.number}>3</span>
          <TwoFATitle>{t('auth.enter-verification-code')}</TwoFATitle>
          <p className={styles.description}>{t('auth.')}</p>

          <TwoFAInput value={value} onChange={handleInputChange} />
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              type="submit"
              onClick={handleSubmit}
            >
              {t('common.button.verify')}
            </Button>
          </div>
          <Error message={error ? getResponseError(error) : ''} />
        </div>
      </form>

      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default Step2;
