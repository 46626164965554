import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import cn from 'classnames';

import type { Dispatch, SetStateAction } from 'react';
import type {
  SubmenuType,
  LogsFilterType,
} from 'components/common/Table/TableFilter/models';
import { SelectOption, DateRangeOption } from 'types/models';

import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';
import Category from 'components/common/Table/TableFilter/Category';

import { IconsNames } from 'constants/constants';

import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from './index.module.scss';

type Props = {
  filterOptions: LogsFilterType;
  setSearchParams: Dispatch<
    SetStateAction<Record<string, SelectOption[] | DateRangeOption>>
  >;
  searchParams: Record<string, SelectOption[] | DateRangeOption>;
};

const TableFilter = ({
  filterOptions,
  setSearchParams,
  searchParams,
}: Props) => {
  const { t } = useTranslation();

  const listRef = useRef(null);

  const [isOptionsListVisible, setIsOptionsListVisible] = useState(false);
  const [submenu, setSubmenu] = useState<SubmenuType | null>(null);

  const showListHandler = () => setIsOptionsListVisible(prev => !prev);
  useEscapeEvent(() => {
    setIsOptionsListVisible(false);
    setSubmenu(null);
  });
  useClickAway(listRef, () => {
    setIsOptionsListVisible(false);
    setSubmenu(null);
  });

  const filterCategories: string[] = useMemo(() => {
    return Object.keys(filterOptions);
  }, [filterOptions]);

  return (
    <div className={styles.buttonWrapper}>
      <Button
        onClick={showListHandler}
        iconProps={{ name: IconsNames.filter }}
        white
      >
        {t('common.button.add-filter')}
      </Button>
      <ul
        className={cn(styles.add_filter, {
          [styles.show]: isOptionsListVisible,
        })}
        ref={listRef}
      >
        <h3 className={styles.heading}>{t('common.button.add-filter')}</h3>
        {!submenu ? (
          filterCategories.map(option => {
            return (
              <Category
                key={option}
                category={filterOptions[option]}
                setSubmenu={setSubmenu}
                type={filterOptions[option].type}
                setSearchParams={setSearchParams}
                setIsOptionsListVisible={setIsOptionsListVisible}
              />
            );
          })
        ) : (
          <>
            <h3
              className={styles.submenuHeading}
              onClick={() => setSubmenu(null)}
            >
              <IconSVG name={IconsNames.arrow} className={styles.backArrow} />
              {submenu?.label}
            </h3>

            {filterOptions[submenu.key].getSubmenu?.({
              setSearchParams: setSearchParams as Dispatch<
                SetStateAction<Record<string, SelectOption[]>>
              >,
              category: submenu.key,
              searchParams: searchParams[submenu.key] as SelectOption[],
              setIsOptionsListVisible: setIsOptionsListVisible,
              translationPrefix: filterOptions[submenu.key]?.translationPrefix,
            })}
          </>
        )}
      </ul>
    </div>
  );
};

export default TableFilter;
