import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import axios from 'axios';
import Select from 'react-select';

import type { AxiosError } from 'axios';
import type { ProductAssetResponse, SelectOption } from 'types/models';
import type { FormValues } from 'components/Product/AssetDownloadContent/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import RichText from 'components/RichText';

import Answer from 'components/Product/AssetDownloadContent/Answer';

import useModal from 'contexts/ModalContext';
import { useIncreaseAssetDownloads } from 'hooks/api/useIncreaseAssetDownloads';

import { createDownloadFileLink } from 'helpers/files';
import {
  getDefaultEmailTermsAndConditions,
  getDefaultTermsAndConditions,
} from 'helpers/assets';
import getResponseError from 'helpers/getResponseError';

import { validationSchemaDownloadAsset } from 'components/Product/AssetDownloadContent/DownloadForm/validationSchema';
import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import {
  VIDEO_ASSET_CONTENT_TYPES,
  PROD_QUESTIONS_COMPANY_SLUG,
  PROD_QUESTIONS_PRODUCT_SLUG,
  PROD_QUESTIONS_ASSET_SLUGS,
  PROD_SELECT_OPTIONS,
} from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  setIsVideoPreview: React.Dispatch<React.SetStateAction<boolean>>;
  isModalContent?: boolean;
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
  isAssetAvailable: boolean;
};

type SubmitAnswersPayload = {
  script_builder_option?: number;
  custom_answer: string;
}[];

const DownloadForm = ({
  data,
  setIsVideoPreview,
  isModalContent,
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
  isAssetAvailable,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const [selectValue, setSelectValue] = useState<SelectOption | null>(null);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setFocus,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaDownloadAsset),
    mode: 'all',
    defaultValues: {
      questions: data.script_builder_questions?.map(question => ({
        ...question,
        answers: question.answers?.map(answer => ({
          ...answer,
          custom_answer: '',
          checked: false,
        })),
      })),
    },
  });

  const isVideoContent = VIDEO_ASSET_CONTENT_TYPES.some(
    type => data.content_type === type
  );
  const contactEmail = data.share_contact_data?.email;

  const { mutate: increasePopularity } = useIncreaseAssetDownloads({
    companySlug,
    email: contactEmail,
    assetId: data.id,
  });

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    SubmitAnswersPayload
  >(
    async payload => {
      try {
        const { data: response } = await axios.post<{
          attached_file?: string;
          attached_file_link?: string;
        }>(
          `${process.env.REACT_APP_BACKEND_URL}/shares/${contactShareId}/companies/${companySlug}/products/${productSlug}/assets/${assetSlug}/submit-answers/`,
          { campaign_lead_answers: payload }
        );

        if (!isVideoContent) {
          increasePopularity();

          if (response.attached_file) {
            createDownloadFileLink(
              response.attached_file,
              data.attached_file_name
            );
          }
          if (response.attached_file_link) {
            window.open(response.attached_file_link, '_blank')?.focus();
          }

          setValue('questions', []);
        }

        await queryClient.refetchQueries([
          'asset-public',
          companySlug,
          productSlug,
          assetSlug,
        ]);
        if (isVideoContent) {
          setIsVideoPreview(true);
        }
      } catch (err) {
        throw err;
      }
    },
    {
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const { fields } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  const handleSubmitAnswers = () => {
    const payload = getValues()
      .questions.map(item => item.answers)
      .flat()
      .filter(item => item.checked)
      .map(item => ({
        script_builder_option: item.id,
        custom_answer: item.custom_answer,
      }));

    mutate(payload);
  };

  const termsAndConditionsElement = (
    <p
      className={cn(styles.termsCondition, {
        [styles.termsCondition__modal]: isModalContent,
      })}
    >
      {getDefaultTermsAndConditions(data.share_contact_data?.campaign)}{' '}
      {contactEmail &&
        getDefaultEmailTermsAndConditions({
          email: contactEmail,
          hasVariable: !!contactEmail,
          campaignId: data.share_contact_data?.campaign,
        })}
    </p>
  );

  const WrapperElement = isAssetAvailable ? 'section' : 'form';

  // TODO: delete this terrible crutch when script builder will be done
  const isQuestionVisible =
    companySlug === PROD_QUESTIONS_COMPANY_SLUG &&
    PROD_QUESTIONS_PRODUCT_SLUG.some(slug => slug === productSlug) &&
    PROD_QUESTIONS_ASSET_SLUGS.some(slug => slug === assetSlug);
  const isDownloadDisabled = isQuestionVisible && !selectValue;

  return (
    <WrapperElement
      className={cn(styles.row, { [styles.scrollable]: isModalContent })}
      onSubmit={handleSubmit(handleSubmitAnswers)}
    >
      <h1
        className={cn(styles.title, {
          [styles.title__small]: isModalContent,
        })}
      >
        {data.title}
      </h1>
      <p className={styles.text}>{data.description}</p>

      {isQuestionVisible && (
        <>
          <p className={styles.question}>
            Does your company plan to upgrade or replace its current Workforce
            Management, HR Payroll system? If so, when?
          </p>
          <Select
            options={PROD_SELECT_OPTIONS}
            isSearchable={false}
            styles={getDefaultSelectStylesWithError({ isSmall: true })}
            value={selectValue}
            onChange={setSelectValue}
            isClearable={false}
            placeholder="Select"
          />
        </>
      )}

      <div className={styles.form}>
        {!!fields.length && !isAssetAvailable && (
          <ul>
            {fields.map((question, questionIndex) => {
              const questionError = errors.questions?.[questionIndex]?.message;

              return (
                <li key={question.id}>
                  <RichText
                    htmlString={question.name}
                    className={cn(styles.questionName, {
                      [styles.hasError]: questionError,
                    })}
                  />
                  {questionError && (
                    <Error
                      message={t(questionError)}
                      className={styles.error}
                    />
                  )}
                  <ul>
                    {question.answers.map((answer, answerIndex) => (
                      <Answer
                        questionIndex={questionIndex}
                        answer={answer}
                        key={answer.id}
                        control={control}
                        answerType={question.answer_type}
                        getValues={getValues}
                        setValue={setValue}
                        answerIndex={answerIndex}
                        register={register}
                        customValueError={
                          errors.questions?.[questionIndex]?.answers?.[
                            answerIndex
                          ]?.message
                        }
                        clearErrors={clearErrors}
                        setFocus={setFocus}
                      />
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {!isModalContent && termsAndConditionsElement}
      <div className={cn({ [styles.sticky]: isModalContent })}>
        {!isAssetAvailable && (
          <Button
            className={styles.button}
            isBig
            type="submit"
            disabled={isDownloadDisabled}
          >
            {data.attached_file_link_name || t('common.button.download')}
          </Button>
        )}

        {isAssetAvailable && !isVideoContent && (
          <>
            {data.attached_file && (
              <Button
                className={styles.button}
                isBig
                onClick={() => {
                  createDownloadFileLink(
                    data.attached_file,
                    data.attached_file_name
                  );
                  increasePopularity();
                }}
                disabled={isDownloadDisabled}
              >
                {data.attached_file_link_name || t('common.button.download')}
              </Button>
            )}
            {data.attached_file_link && (
              <a
                className={cn(styles.link, {
                  [styles.disabled]: isDownloadDisabled,
                })}
                href={data.attached_file_link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => increasePopularity()}
              >
                <Button
                  className={styles.button}
                  isBig
                  disabled={isDownloadDisabled}
                >
                  {data.attached_file_link_name || t('common.button.download')}
                </Button>
              </a>
            )}
          </>
        )}

        {isVideoContent && isAssetAvailable && (
          <Button
            className={styles.button}
            isBig
            onClick={() => setIsVideoPreview(true)}
            disabled={isDownloadDisabled}
          >
            {data.attached_file_link_name || t('common.button.download')}
          </Button>
        )}

        {isModalContent && termsAndConditionsElement}
      </div>

      {!isModalContent && (
        <Link to=".." className={styles.toProductLink}>
          <Button isBig className={styles.button} type="button" transparent>
            {t('manage-products.learn-more')}
          </Button>
        </Link>
      )}

      {isLoading && <LoaderScreen />}
    </WrapperElement>
  );
};

export default DownloadForm;
