import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import cn from 'classnames';
import { useClickAway } from 'react-use';
import dayjs from 'dayjs';

import type { Dispatch, SetStateAction } from 'react';

import IconSVG from 'components/UI/IconSVG';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import DatePicker from 'components/UI/DatePicker';

import { IconsNames } from 'constants/constants';
import { DateRangeOption } from 'types/models';

import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from '../index.module.scss';

type Props = {
  setSearchParams: Dispatch<SetStateAction<Record<string, DateRangeOption>>>;
  category: string;
  submittedValue: DateRangeOption;
};

const DateFilter = ({ category, setSearchParams, submittedValue }: Props) => {
  const { t } = useTranslation();

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(true);

  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [showDateFromPicker, setShowDateFromPicker] = useState(false);

  const [dateTo, setDateTo] = useState<string | null>(null);
  const [showDateToPicker, setShowDateToPicker] = useState(false);

  const listRef = useRef(null);

  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);

  useEscapeEvent(() => {
    setIsDatePickerVisible(false);
  });

  useClickAway(listRef, () => {
    setIsDatePickerVisible(false);
    setShowDateFromPicker(false);
    setShowDateToPicker(false);
  });

  useClickAway(dateFromRef, () => {
    setShowDateFromPicker(false);
  });

  useClickAway(dateToRef, () => {
    setShowDateToPicker(false);
  });

  const removeSearchCategory = () => {
    setSearchParams(prev => {
      const updatedParams = { ...prev };
      delete updatedParams[category];
      return updatedParams;
    });
  };

  const clearField = (
    e: React.KeyboardEvent,
    callback: (value: string) => void
  ) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      callback('');
    }
  };

  const handleClickApply = () => {
    setSearchParams(prev => {
      return {
        ...prev,
        [category]: {
          date_from: dateFrom,
          date_to: dateTo,
        },
      };
    });
    setIsDatePickerVisible(false);
  };

  const formatDateRange = ({ date_from, date_to }: DateRangeOption) => {
    if (submittedValue.date_from && !submittedValue.date_to) {
      return `${t('common.field.from')} ${dayjs(date_from).format(
        'DD/MM/YYYY'
      )}`;
    } else if (!date_from && date_to) {
      return `${t('common.field.to')} ${dayjs(date_to).format('DD/MM/YYYY')}`;
    } else if (date_from && date_to) {
      return `${dayjs(date_from).format('DD/MM/YYYY')}-${dayjs(date_to).format(
        'DD/MM/YYYY'
      )}`;
    } else {
      return '';
    }
  };

  return (
    <div className={styles.wrapper} ref={listRef}>
      <div className={styles.inputWrapper}>
        <div
          className={cn(styles.labelWrapper)}
          onClick={() => setIsDatePickerVisible(prev => !prev)}
        >
          <span className={styles.label}>
            {formatDateRange(submittedValue) || t('common.field.from-to')}
          </span>
          <IconSVG
            name={IconsNames.select_arrow}
            className={cn(styles.arrow, {
              [styles.active]: isDatePickerVisible,
            })}
          />
        </div>
        <div className={styles.border}>
          <IconSVG
            name={IconsNames.close}
            className={styles.close}
            onClick={removeSearchCategory}
          />
        </div>
      </div>
      {isDatePickerVisible && (
        <div className={cn(styles.menuWrapper)}>
          <p className={styles.heading}>{t('common.field.calendar-filter')}</p>
          <div className={styles.dateInputWrapper} ref={dateFromRef}>
            <InputWrapper label={t('common.field.from')} isMediumInput>
              <div className={styles.dateInputWrapper}>
                <input
                  type="text"
                  className={styles.input}
                  value={dateFrom ? dayjs(dateFrom).format('DD/MM/YYYY') : ''}
                  onClick={() => {
                    setShowDateFromPicker(true);
                  }}
                  onChange={e => e.preventDefault()}
                  onKeyDown={e => clearField(e, setDateFrom)}
                />
                <IconSVG
                  name={IconsNames.calendar}
                  className={styles.calendarIcon}
                />
              </div>
            </InputWrapper>
            {showDateFromPicker && (
              <div className={styles.datePicker}>
                <DatePicker
                  inline
                  selected={dateFrom ? dayjs(dateFrom).toDate() : null}
                  onChange={value => {
                    setDateFrom(dayjs(value).format('YYYY-MM-DD'));
                    setShowDateFromPicker(false);
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.dateInputWrapper} ref={dateToRef}>
            <InputWrapper
              label={t('common.field.to')}
              isMediumInput
              wrapperClasses={styles.dateInputWrapper}
            >
              <div className={styles.dateInputWrapper}>
                <input
                  type="text"
                  className={styles.input}
                  value={dateTo ? dayjs(dateTo).format('DD/MM/YYYY') : ''}
                  onClick={() => setShowDateToPicker(true)}
                  onKeyDown={e => clearField(e, setDateTo)}
                  onChange={e => e.preventDefault()}
                />
                <IconSVG
                  name={IconsNames.calendar}
                  className={styles.calendarIcon}
                />
              </div>
              {showDateToPicker && (
                <div className={styles.datePicker}>
                  <DatePicker
                    inline
                    onChange={value => {
                      setDateTo(dayjs(value).format('YYYY-MM-DD'));
                      setShowDateToPicker(false);
                    }}
                    selected={dateTo ? dayjs(dateTo).toDate() : null}
                  />
                </div>
              )}
            </InputWrapper>
          </div>
          <Button
            white
            className={styles.button}
            disabled={!dateFrom && !dateTo}
            onClick={handleClickApply}
          >
            {t('common.button.apply')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
