import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TwoFATypes } from 'components/Authorization/TwoFA/models';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import Step2 from 'components/Profile/Security/EmailAuth/Step2';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import useAddAuthMethod from 'components/Profile/Security/useAddAuthMethod';

import { validationSchemaLogIn } from 'utils/validations';
import { getDevicesDictionary } from 'helpers/twoFA';
import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

type NewEmailDeviceFormValues = {
  email: string;
  password: string;
};

const EmailAuth = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { closeModal } = useModal();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isShowPassword, setIsShowPassword] = useState(true);
  const devicesDictionary = getDevicesDictionary(user?.devices);
  const devices = devicesDictionary?.[TwoFATypes.email];

  const methods = useForm<NewEmailDeviceFormValues>({
    resolver: yupResolver(validationSchemaLogIn),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { email: user?.email },
  });

  const { mutate, newDeviceResponse, isLoading, error } = useAddAuthMethod({
    devices,
    type: TwoFATypes.email,
    setCurrentStep,
  });

  const onGoToNextStep = async () => {
    const isValid = await methods.trigger('password');
    if (isValid) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const onSubmit = (data: NewEmailDeviceFormValues) => {
    mutate(data);
  };

  return (
    <ModalWindow
      title={
        <div className={styles.primaryTitle}>
          <h2>{t('auth.new-device', { type: TwoFATypes.email })}</h2>
          <span
            className={styles.progress}
            style={{ width: `${(currentStep / 3) * 100}%` }}
          />
        </div>
      }
      className={styles.wrapper}
      closeButtonClassName={styles.close}
      onClose={closeModal}
      isLockBodyScroll
      closeOnClickAway={false}
    >
      {!newDeviceResponse ? (
        <FormProvider {...methods}>
          {currentStep === 1 && (
            <form className={styles.form}>
              <h2 className={styles.title}>
                {t('auth.enter-method', { type: 'password' })}
              </h2>
              <p className={styles.text}>{t('auth.need-verify')}</p>
              <InputWrapper
                label="common.field.password"
                validationError={methods.formState.errors.password?.message}
                setIsHiddenInputContent={setIsShowPassword}
                wrapperClasses={styles.inputWrapper}
                isMediumInput
              >
                <input
                  {...methods.register('password')}
                  type={isShowPassword ? 'password' : 'text'}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onGoToNextStep();
                    }
                  }}
                />
              </InputWrapper>
              <Button
                className={styles.button}
                type="submit"
                onClick={onGoToNextStep}
              >
                {t('common.button.continue')}
              </Button>
            </form>
          )}
          {currentStep === 2 && (
            <form
              className={styles.form}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <h2 className={styles.title}>
                {t('auth.enter-method', { type: 'email' })}
              </h2>
              <p className={styles.text}>{t('auth.email-for-auth')}</p>
              <InputWrapper
                label="common.field.email"
                validationError={methods.formState.errors.email?.message}
                wrapperClasses={styles.inputWrapper}
                submitError={error ? getResponseError(error) : undefined}
                isMediumInput
              >
                <input {...methods.register('email')} type="text" />
              </InputWrapper>
              <Button
                className={styles.button}
                type="submit"
                isLoading={isLoading}
              >
                {t('common.button.continue')}
              </Button>
            </form>
          )}
        </FormProvider>
      ) : (
        <div className={styles.animation}>
          <Step2 device={newDeviceResponse} />
        </div>
      )}
    </ModalWindow>
  );
};

export default EmailAuth;
