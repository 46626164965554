import { useInfiniteQuery } from 'react-query';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import type { AxiosError } from 'axios';

import type { PaginationReturn } from 'types/models';
import type { DynamicOptionsProps } from 'components/common/Table/TableFilter/DynamicOptions';

import DynamicOptions from 'components/common/Table/TableFilter/DynamicOptions';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

const DEBOUNCE_DELAY = 250;
const PAGE_SIZE = 10;

const withEmailsResult = (Component: typeof DynamicOptions) => {
  return (
    props: Omit<
      DynamicOptionsProps,
      'options' | 'searchValue' | 'setSearchValue' | 'fetchNextPage'
    >
  ) => {
    const { axios, user } = useAuth();
    const companyId = user?.company.id;

    const [searchValue, setSearchValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    useDebounce(() => setDebouncedValue(searchValue), DEBOUNCE_DELAY, [
      searchValue,
    ]);

    const {
      data: campaigns,
      isLoading,
      error,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isSuccess,
      isRefetching,
    } = useInfiniteQuery<
      PaginationReturn<{ id: number; email: string }[]>,
      AxiosError
    >(
      ['emails-options', companyId, debouncedValue],
      async ({ pageParam }) => {
        let page;
        if (typeof pageParam === 'string') {
          const url = new URL(pageParam);
          page = url.searchParams.get('page');
        }
        const pageNumber = page || 1;
        try {
          const { data } = await axios.get<
            PaginationReturn<{ id: number; email: string }[]>
          >(
            `companies/${companyId}/leads-search/?search=${debouncedValue}&page=${pageNumber}&page_size=${PAGE_SIZE}`
          );

          return data;
        } catch (err) {
          throw err;
        }
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        getNextPageParam: page => page.next || undefined,
      }
    );

    const optionsList = campaigns?.pages.map(page => page.results).flat();

    const isEmptyMessageVisible =
      isSuccess && !optionsList?.length && !isRefetching;

    return (
      <>
        {isLoading && <Loader size={20} />}
        {error && <Error message={getResponseError(error)} />}
        {!isLoading && isSuccess && (
          <Component
            {...props}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            fetchNextPage={fetchNextPage}
            isEmptyMessageVisible={isEmptyMessageVisible}
            options={
              optionsList?.map(({ email }) => ({
                value: email,
                label: email,
              })) || []
            }
          />
        )}
      </>
    );
  };
};

const EmailsDynamicOptions = withEmailsResult(DynamicOptions);

export default EmailsDynamicOptions;
