import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';

import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';

export const useDeleteQuestion = () => {
  const { axios } = useAuth();
  const { t } = useTranslation();
  const { openModal } = useModal();

  const mutation = useMutation<
    void,
    AxiosError,
    { campaignId: string | number; questionId: string | number }
  >(
    async ({ campaignId, questionId }) => {
      try {
        await axios.delete(
          `/campaigns/${campaignId}/script-builder/questions/${questionId}/`
        );
      } catch (error) {
        throw error;
      }
    },
    {
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return mutation;
};
