import dayjs from 'dayjs';
import DayPicker from 'react-datepicker';
import { useState } from 'react';

import type { ReactDatePickerProps } from 'react-datepicker';

import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const DatePicker = (
  props: Omit<ReactDatePickerProps, 'renderCustomHeader'>
) => {
  const defaultValue = props.value || props.selected;
  const [customValue, setCustomValue] = useState(
    defaultValue ? dayjs(defaultValue).toDate() : null
  );

  const onDayChange = (
    day: Date | null,
    event: React.SyntheticEvent | undefined
  ) => {
    setCustomValue(day);
    props.onChange(day, event);
  };

  return (
    <div className={styles.picker}>
      <DayPicker
        {...props}
        onChange={onDayChange}
        selected={customValue}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles.header}>
            <Button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={styles.navigation}
              iconProps={{ name: IconsNames.arrow }}
              isOnlyIcon
            />
            <span className={styles.month}>
              {dayjs(date).format('MMMM YYYY')}
            </span>
            <Button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className={styles.navigation}
              iconProps={{ name: IconsNames.arrow }}
              isOnlyIcon
            />
          </div>
        )}
      />
    </div>
  );
};

export default DatePicker;
