import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { CampaignStatuses } from 'constants/constants';

import styles from './index.module.scss';

const CampaignStatus = ({
  status,
  className,
}: {
  status: CampaignStatuses;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <span
      className={cn(styles.status, className, {
        [styles['in-progress']]: status === CampaignStatuses.inProgress,
        [styles.finished]: status === CampaignStatuses.finished,
        [styles.draft]: status === CampaignStatuses.draft,
        [styles.paused]:
          status === CampaignStatuses.paused ||
          status === CampaignStatuses.forcePaused,
        [styles.processing]: status === CampaignStatuses.paymentProcessing,
      })}
    >
      {t(`campaign.statuses.${status}`)}
    </span>
  );
};

export default CampaignStatus;
