import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import type { ValidationError } from 'types/models';

import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  label?: string;
  wrapperClasses?: string;
  submitError?: string;
  number?: number;
  validationError?:
    | string
    | ValidationError
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>;
  isRequired?: boolean;
  isErrorHidden?: boolean;
  numberClassName?: string;
  isSmallInput?: boolean;
  isMediumInput?: boolean;
  maxErrorLength?: number;
  tip?: string;
  setIsHiddenInputContent?: (value: React.SetStateAction<boolean>) => void;
  isSmallTitle?: boolean;
  isPreventDefaultClick?: boolean;
  charactersLength?: [number | undefined, number];
  tipClassName?: string;
};

const InputWrapper: React.FC<Props> = ({
  label,
  children,
  wrapperClasses,
  submitError,
  validationError,
  number,
  isRequired = false,
  isErrorHidden,
  numberClassName,
  isSmallInput,
  isMediumInput,
  maxErrorLength,
  tip,
  setIsHiddenInputContent,
  isPreventDefaultClick,
  charactersLength,
  tipClassName,
}) => {
  const { t } = useTranslation();
  const tooltipOptions = usePopperTooltip({
    placement: 'top',
  });

  const validationErrorMessage =
    validationError &&
    typeof validationError === 'string' &&
    t(validationError);
  const customValidationError =
    validationError &&
    typeof validationError !== 'string' &&
    'values' in validationError
      ? t(validationError.key?.toString() || '', validationError.values)
      : undefined;
  const fieldValidationError =
    validationError &&
    typeof validationError !== 'string' &&
    'message' in validationError &&
    t(validationError.message?.toString() || '');

  const errorMessage =
    validationErrorMessage ||
    customValidationError ||
    fieldValidationError ||
    submitError;
  const isCharactersLengthVisible = !!charactersLength && !errorMessage;

  return (
    <label
      className={cn(
        styles.label,
        {
          [styles.label__number]: number,
          [styles.error]: errorMessage,
          [styles.small]: isSmallInput,
          [styles.medium]: isMediumInput,
          [styles.hidable]: setIsHiddenInputContent,
        },
        wrapperClasses
      )}
      onClick={isPreventDefaultClick ? e => e.preventDefault() : undefined}
    >
      {!!number && (
        <span className={cn(styles.number, numberClassName)}>{number}</span>
      )}
      {!!label && (
        <span className={styles.title}>
          <>
            {t(label)}
            {isRequired ? <span className={styles.required}>*</span> : ''}
            {tip && (
              <IconSVG
                name={IconsNames.help}
                className={styles.icon}
                ref={tooltipOptions.setTriggerRef}
              />
            )}
          </>
        </span>
      )}

      {tooltipOptions.visible && (
        <SimpleTooltip options={tooltipOptions} className={tipClassName}>
          {tip}
        </SimpleTooltip>
      )}

      {children}

      {isCharactersLengthVisible && (
        <p className={styles.charactersAmount}>
          {charactersLength[0] || 0}/{charactersLength[1]}{' '}
          {t('common.field.characters')}
        </p>
      )}
      <Error
        message={errorMessage || ''}
        className={cn(styles.error__text, {
          [styles.hidden]: isErrorHidden,
          [styles.noMargin]: isCharactersLengthVisible,
          [styles.error__characters]: !!charactersLength && errorMessage,
        })}
        maxErrorLength={maxErrorLength}
      />

      {setIsHiddenInputContent && (
        <button
          onClick={() => setIsHiddenInputContent(prev => !prev)}
          type="button"
          className={styles.hide}
        >
          <IconSVG name={IconsNames.eye} />
        </button>
      )}
    </label>
  );
};

export default InputWrapper;
