import { useTranslation } from 'react-i18next';

import type { ReportResponse } from 'types/models';

import Button from 'components/common/Button';
import Error from 'components/common/Error';

import FailedReasonsModal from 'components/CampaignManage/VendorSettings/UploadedReport/FailedReasonsModal';

import useModal from 'contexts/ModalContext';

import { getFileIconFromFileName } from 'helpers/assets';

import { ReportUploadStatus } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  data: ReportResponse;
};

const UploadedReport = ({
  data: {
    report_file_name: fileName,
    report_file: fileLink,
    status,
    total_rows_number: totalRowsNumber,
    processed_rows_number: processedRowsNumber,
    success_rows_number: successRowsNumber,
    failed_rows_number: failedRowsNumber,
    execution_details: executionDetails,
  },
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const openErrorDetailsModal = () => {
    openModal({
      Content: <FailedReasonsModal data={executionDetails} />,
    });
  };

  const isUploadingInProgress = status === ReportUploadStatus['in-progress'];
  const progressPercent = (
    (processedRowsNumber * 100) /
    totalRowsNumber
  ).toFixed(2);
  const isFailedStatus = status === ReportUploadStatus.failed;
  const Icon = getFileIconFromFileName(fileName);

  return (
    <>
      <a href={fileLink} download className={styles.link}>
        <Icon />
        {fileName}
      </a>

      {isFailedStatus && (
        <Error
          message={t('common.error.uploading-failed')}
          className={styles.failed}
        />
      )}

      {isUploadingInProgress && (
        <>
          <progress
            id="file"
            max="100"
            value={progressPercent}
            className={styles.progress}
          >
            {progressPercent}%
          </progress>
          <p className={styles.percent}>{progressPercent}%</p>
        </>
      )}

      {!isUploadingInProgress && !!totalRowsNumber && (
        <p className={styles.total}>
          {t('reports.total-leads-uploaded')}
          <span className={styles.total__count}>{totalRowsNumber}</span>
        </p>
      )}

      {!isUploadingInProgress && !!successRowsNumber && (
        <p className={styles.success}>
          {t('reports.success-leads-uploaded')}
          <span className={styles.success__count}>{successRowsNumber}</span>
        </p>
      )}

      {!!failedRowsNumber && (
        <div className={styles.error}>
          <p className={styles.error__disqualified}>
            {t('reports.leads-were-disqualified')}
            <span className={styles.error__count}>{failedRowsNumber}</span>
          </p>
          <p className={styles.error__more}>
            {t('reports.some-leads-disqualified')}
          </p>
          <p className={styles.error__more}>{t('reports.see-more')}</p>
          <Button type="button" white isSmall onClick={openErrorDetailsModal}>
            {t('common.button.show-details')}
          </Button>
        </div>
      )}
    </>
  );
};

export default UploadedReport;
