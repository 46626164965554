import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import type { Area } from 'react-easy-crop';

import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import ImageCropper, {
  Props as CropperProps,
} from 'components/common/ImageCropper';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';
import ChangeImageButton from 'components/common/ImageCropper/ChangeImageButton';

import { IconsNames } from 'constants/constants';

import useModal from 'contexts/ModalContext';

import getCroppedImg from 'utils/cropImage';

import styles from './index.module.scss';

type Props = Omit<CropperProps, 'setCroppedArea'> & {
  title: string;
  uploadImage: (imageData: {
    renderLink: string;
    file: File;
    originalFile: File | null;
  }) => void;
  onDeleteImage?: () => void;
  instanceName?: string;
  itemName?: string;
  accept?: string;
  maxSize?: number;
  isNestedModal?: boolean;
};
const CropImageModal = ({
  value,
  title,
  uploadImage,
  onDeleteImage,
  instanceName,
  itemName,
  isNestedModal,
  maxSize,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { closeModal, openModal, openNestedModal } = useModal();
  const [currentImage, setCurrentImage] = useState<{
    renderLink: string;
    originalFile: File | null;
  }>({ renderLink: value, originalFile: null });
  const [croppedArea, setCroppedArea] = useState<null | Area>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getImageError, setGetImageError] = useState('');
  const [maxSizeError, setMaxSizeError] = useState('');

  const onSaveResult = async () => {
    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(
        currentImage?.renderLink as string,
        croppedArea
      );
      if (croppedImage)
        uploadImage({
          ...croppedImage,
          originalFile: currentImage.originalFile,
        });
      closeModal(isNestedModal);
    } catch (err) {
      setGetImageError(t('common.error.something-went-wrong'));
      Sentry.captureMessage(
        `Get cropping image error: ${value}, ${JSON.stringify(croppedArea)}`
      );
    }
    setIsLoading(false);
  };

  const handleDeleteImage = () => {
    if (!onDeleteImage) return;
    const DeleteModalContent = (
      <DeleteConfirmationModal
        onDelete={() => {
          onDeleteImage();
          closeModal(isNestedModal);
        }}
        instanceName={instanceName?.toLowerCase() || ''}
        itemName={itemName || ''}
        isNestedModal={isNestedModal}
      />
    );

    if (isNestedModal) {
      return openNestedModal({
        NestedModalContent: DeleteModalContent,
      });
    }
    openModal({
      Content: DeleteModalContent,
    });
  };

  return (
    <ModalWindow
      primaryTitle={title}
      className={styles.modalWrapper}
      isNestedModal={isNestedModal}
    >
      <ImageCropper
        {...props}
        value={currentImage.renderLink}
        className={styles.cropper}
        setCroppedArea={setCroppedArea}
      />

      {getImageError && <Error message={getImageError} />}
      <div className={styles.buttonsWrapper}>
        {onDeleteImage && (
          <Button
            white
            onClick={handleDeleteImage}
            iconProps={{ name: IconsNames.trash }}
            className={styles.delete}
          >
            {t('common.button.delete-image')}
          </Button>
        )}

        <ChangeImageButton
          maxSize={maxSize}
          setValidationError={setMaxSizeError}
          uploadImage={setCurrentImage}
        />

        <Button
          iconProps={{ name: IconsNames.check_mark }}
          onClick={onSaveResult}
          isLoading={isLoading}
          className={styles.apply}
        >
          {t('common.button.apply')}
        </Button>
        {maxSizeError && (
          <Error message={maxSizeError} className={styles.error} />
        )}
      </div>
    </ModalWindow>
  );
};

export default CropImageModal;
