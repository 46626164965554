import type { TFunction } from 'react-i18next';

import { SlateElementTypes } from 'components/RichText/models';

import { ScriptQuestionType } from 'constants/constants';

export enum ScriptCompliance {
  casl = 'casl',
  gdpr = 'gdpr',
}

export const NOT_ALLOWED_ANSWERS_SYMBOLS_REGEXP = /;/g;

export enum VariablesInTemplates {
  firstName = 'First Name',
  assetTitle = 'Asset Title',
  assetDescription = 'Asset Description',
  email = 'Email',
}

export const ASSET_PDF_DYNAMIC_VARIABLES = ['Asset Title', 'Asset Description'];

export const createIntroductionTemplate = (t: TFunction) => [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.hi'),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'First Name' }],
        value: 'First Name',
      },
      {
        text: t('script.calling-from'),
      },
    ],
  },
];

export const createWhitepaperTemplate = (t: TFunction) => [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.i-am-calling'),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'Asset Title' }],
        value: 'Asset Title',
      },
      {
        text: t('script.that-talks-about'),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'Asset Description' }],
        value: 'Asset Description',
      },
      {
        text: t('script.can-i-go'),
      },
    ],
  },
];

export const createWebinarTemplate = (t: TFunction) => [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.i-am-calling-to-invite'),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'Asset Title' }],
        value: 'Asset Title',
      },
      {
        text: t('script.scheduled-for'),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'Asset Description' }],
        value: 'Asset Description',
      },
    ],
  },
];

export const createClosingTemplate = (t: TFunction) => [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.goodbye'),
      },
    ],
  },
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.have-nice-day'),
      },
    ],
  },
];

export const emptyTemplate = [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: '',
      },
    ],
  },
];

export const createComplianceTemplate = (compliance: string, t: TFunction) => [
  {
    type: SlateElementTypes.paragraph,
    children: [
      {
        text: t('script.in-order-to', { id: compliance }),
      },
      {
        type: SlateElementTypes.variable,
        children: [{ text: 'Email' }],
        value: 'Email',
      },
      {
        text: t('script.do-i-have'),
      },
    ],
  },
];

export const emptyQuestionAnswer = {
  name: emptyTemplate,
  is_disqualifier: false,
  is_other: false,
};

export const createQuestionAnswerOther = (t: TFunction) => ({
  name: [
    {
      type: SlateElementTypes.paragraph,
      children: [
        {
          text: t('common.field.other'),
        },
      ],
    },
  ],
  is_disqualifier: false,
  is_other: true,
});

export const emptyQuestion = {
  name: emptyTemplate,
  answer_type: ScriptQuestionType.radiobutton,
  display_on_asset_page: false,
  shouldFocus: true,
  answers: [emptyQuestionAnswer, emptyQuestionAnswer],
};
