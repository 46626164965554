import { IconsNames } from 'constants/constants';

export enum PaymentMethodName {
  creditCard = 'creditCard',
  bank = 'bank',
  payWithTerms = 'payWithTerms',
  invoice = 'invoice',
}

export enum PaymentStatus {
  open = 'open',
  pending = 'pending',
  failed = 'failed',
  canceled = 'canceled',
  charged = 'charged',
}

export enum PaymentType {
  deposit = 'deposit',
  systemPayment = 'system-payment',
  campaignCreationCharge = 'campaign-creation-charge',
  vendorPayout = 'vendor-payout',
  qcCharge = 'qc-charge',
}

export enum TermValues {
  fifteen = 15,
  thirty = 30,
  fortyFive = 45,
  sixty = 60,
}

declare global {
  interface Window {
    balanceSDK: {
      checkout: {
        init: (options: CheckoutOptions) => void;
        render: (transactionToken: string, elementId: string) => void;
      };
    };
  }
}

export interface CheckoutOptions {
  isAuth?: boolean;
  hideDueDate?: boolean;
  hideDueDateText?: string;
  skipSuccessPage?: boolean;
  allowedPaymentMethods?: PaymentMethodName[];
  onClose: (() => void) | (() => Promise<void>);
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
  hideNoTerms?: boolean;
}

export enum PaymentOptions {
  now = 'pay-now',
  later = 'pay-with-terms',
}

type PaymentLineItems = {
  title: string;
  quantity: number;
  price: number;
}[];

export type CreatePaymentPayload = {
  amount: number;
  method?: PaymentMethodName;
  term?: TermValues | null;
  notes?: string;
  emails_cc?: string[];
  line_items?: PaymentLineItems;
  campaign_id?: string;
  discount?: string;
  option?: PaymentOptions;
  terms_method?: PaymentMethodName.payWithTerms | PaymentMethodName.invoice;
};

export type DepositFormValues = {
  amount: number;
  discount?: string;
  method?: {
    value: PaymentMethodName;
    label: string;
    iconProps: { name: IconsNames; className?: string };
  } | null;
  term?: { value: TermValues; label: number | string } | null;
  option: PaymentOptions;
  line_items?: PaymentLineItems;
  campaign_id?: string;
};

export type Transaction = {
  id: string;
  payment_type: PaymentType;
  payment_terms: number;
  created_at: string;
  updated_at: string;
  status: PaymentStatus;
  payment_method: string;
  created_by: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  invoice_pdf_link: string;
  amount_currency: string;
  amount_credits: string;
  pay_link: string | null;
};

export enum PayoutType {
  leadQualify = 'lead-qualify',
  qcCharge = 'qc-charge',
  transfer = 'transfer',
  withdraw = 'withdraw',
  platformCharge = 'platform-charge',
}

export enum PayoutStatus {
  pending = 'pending',
  paid = 'paid',
  canceled = 'canceled',
  failed = 'failed',
}

export type VendorTransaction = {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: {
    id: number;
    first_name: number;
    last_name: string;
    email: string;
  };
  status: PayoutStatus;
  amount_currency: string;
  amount_credits: string;
  campaign: {
    id: number;
    name: string;
  };
  payout_type: PayoutType;
};

export type TransactionData = {
  transaction_id: string;
  token: string;
  link: string;
};

export type BalanceResponse = {
  in_account: string;
};
