import { components } from 'react-select';
import cn from 'classnames';

import type { OptionProps, GroupBase } from 'react-select';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const CheckmarkOption = <
  T extends { value: unknown; label: string },
  IsMulti extends boolean
>(
  props: OptionProps<T, IsMulti, GroupBase<T>>
) => {
  return (
    <components.Option {...props}>
      <div className={styles.container}>
        <IconSVG
          name={IconsNames.check_mark}
          className={cn(styles.icon, { [styles.checked]: props.isSelected })}
        />
        <label className={styles.label}>{props.label}</label>
      </div>
    </components.Option>
  );
};

export default CheckmarkOption;
