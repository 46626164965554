import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useEffect } from 'react';

import type {
  Control,
  UseFormSetValue,
  UseFormGetValues,
  UseFormRegister,
  UseFormClearErrors,
  UseFormSetFocus,
  Path,
} from 'react-hook-form';
import type {
  AnswerValue,
  FormValues,
} from 'components/Product/AssetDownloadContent/models';

import RichText from 'components/RichText';
import InputWrapper from 'components/common/InputWrapper';

import { ScriptQuestionType } from 'constants/constants';

import styles from './index.module.scss';

type CustomAnswerProps = {
  setFocus: UseFormSetFocus<FormValues>;
  register: UseFormRegister<FormValues>;
  customValueError?: string;
  path: Path<FormValues>;
};

type Props = Omit<CustomAnswerProps, 'path'> & {
  questionIndex: number;
  answerType: ScriptQuestionType;
  answer: AnswerValue;
  control: Control<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  answerIndex: number;
  clearErrors: UseFormClearErrors<FormValues>;
};

const CustomAnswer = ({
  path,
  customValueError,
  register,
  setFocus,
}: CustomAnswerProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    setFocus(path);
  }, []);

  return (
    <InputWrapper
      validationError={customValueError}
      isMediumInput
      isErrorHidden
    >
      <input
        type="text"
        {...register(path)}
        placeholder={`${t('common.field.other')}...`}
      />
    </InputWrapper>
  );
};

const Answer = ({
  questionIndex,
  answer,
  answerType,
  control,
  answerIndex,
  getValues,
  setValue,
  register,
  customValueError,
  clearErrors,
  setFocus,
}: Props) => {
  const { t } = useTranslation();
  const { field: answersField } = useController({
    control,
    name: `questions.${questionIndex}`,
  });
  const isChecked = useWatch({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.checked`,
  });

  const handleSelectAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const questionAnswers = getValues().questions[questionIndex].answers;

    let isSomeAnswerChecked = false;

    const updatedAnswers = questionAnswers.map(item => {
      const checkBoxValue =
        answer.id === item.id ? e.currentTarget.checked : item.checked;
      const isAnswerChecked =
        answerType === ScriptQuestionType.radiobutton
          ? answer.id === item.id
          : checkBoxValue;
      if (isAnswerChecked) isSomeAnswerChecked = true;

      return {
        ...item,
        checked: isAnswerChecked,
      };
    });

    setValue(`questions.${questionIndex}.answers`, updatedAnswers);
    if (isSomeAnswerChecked) {
      clearErrors(`questions.${questionIndex}`);
    }
  };

  const isOtherChecked = answer.is_other && isChecked;

  return (
    <li
      className={cn(styles.wrapper, {
        [styles.custom]: isOtherChecked,
      })}
    >
      <label
        htmlFor={answer.id?.toString()}
        className={cn(styles.label, { [styles.other]: answer.is_other })}
      >
        <input
          type={
            answerType === ScriptQuestionType.checkbox ? answerType : 'radio'
          }
          id={answer.id?.toString()}
          name={questionIndex?.toString()}
          value={answer.id}
          onChange={handleSelectAnswer}
          checked={isChecked}
          ref={answerIndex === 0 ? answersField.ref : undefined}
        />
        {answer.is_other ? (
          <span>{t('common.field.other')}</span>
        ) : (
          <RichText htmlString={answer.name} className={styles.name} />
        )}
      </label>

      {isOtherChecked && (
        <CustomAnswer
          register={register}
          customValueError={customValueError}
          setFocus={setFocus}
          path={`questions.${questionIndex}.answers.${answerIndex}.custom_answer`}
        />
      )}
    </li>
  );
};

export default Answer;
