import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { UseFormReturn } from 'react-hook-form';
import type { AxiosError } from 'axios';
import type { ProductFormValues } from 'components/Product/CreateProductContent/models';

import TopInfo from 'components/Product/CreateProductContent/TopInfo';
import AboutProduct from 'components/Product/CreateProductContent/AboutProduct';
import ButtonName from 'components/Product/CreateProductContent/ButtonName';
import Button from 'components/common/Button';
import Error from 'components/common/Error';

import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

export type Props = {
  methods: UseFormReturn<ProductFormValues>;
  isFirstStepDone: boolean;
  onSubmit: (data: ProductFormValues) => void;
  error?: AxiosError | null;
  setIsFirstStepDone: (isDone: boolean) => void;
};

const FirstStep = ({
  methods,
  isFirstStepDone,
  onSubmit,
  error,
  setIsFirstStepDone,
}: Props) => {
  const { t } = useTranslation();

  const onEdit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsFirstStepDone(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.formContainer}
        onSubmit={methods.handleSubmit(onSubmit)}
        aria-label="product overview"
      >
        <TopInfo isFirstStepDone={isFirstStepDone} error={error} />

        <div className={styles.mainWidth}>
          <AboutProduct isFirstStepDone={isFirstStepDone} />
          <ButtonName isFirstStepDone={isFirstStepDone} error={error} />

          {isFirstStepDone ? (
            <Button type="button" className={styles.button} onClick={onEdit}>
              {t('common.button.edit')}
            </Button>
          ) : (
            <Button className={styles.button} type="submit">
              {t('common.button.save')}
            </Button>
          )}
          {error && <Error message={getResponseError(error)} />}
        </div>
      </form>
    </FormProvider>
  );
};

export default FirstStep;
