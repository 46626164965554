import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import type { AxiosError } from 'axios';
import type { ProductResponse } from 'types/models';

import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import ProductPage from 'components/Product/ProductPage';

import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

const Product = () => {
  const { companySlug, productSlug, contactShareId } = useParams();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const { data, isLoading, refetch } = useQuery<ProductResponse, AxiosError>(
    ['get-product', companySlug, productSlug],
    async () => {
      try {
        const response = await axios.get<ProductResponse>(
          `${process.env.REACT_APP_BACKEND_URL}${
            contactShareId ? `/shares/${contactShareId}` : ''
          }/companies/${companySlug}/products/${productSlug}/`
        );

        return response.data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: Boolean(companySlug && productSlug),
      onSuccess: async response => {
        if (response.background_image) {
          await new Promise<HTMLImageElement>((res, rej) => {
            const image = new Image();
            image.src = response.background_image;
            image.onload = () => res(image);
            image.onerror = () => rej();
          });
        }
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return (
    <>
      {data && <ProductPage data={data} refetch={refetch} />}
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default Product;
