import {
  MilestoneType,
  CustomDefaultValue,
  Regions,
  LeadsTableAccessors,
} from 'constants/constants';
import { AssetTypes } from 'constants/assets';

export const industriesOptions = [
  'airlines-aviation',
  'animation',
  'apparel-fashion',
  'architecture-planning',
  'automotive',
  'aviation-aerospace',
  'banking',
  'biotechnology',
  'broadcast-media',
  'building-materials',
  'business-supplies-and-equipment',
  'capital-markets',
  'chemicals',
  'civic-social-organization',
  'civil-engineering',
  'commercial-real-estate',
  'computer-network-security',
  'computer-games',
  'computer-hardware',
  'computer-networking',
  'computer-software',
  'construction',
  'consumer-electronics',
  'consumer-goods',
  'consumer-services',
  'cosmetics',
  'dairy',
  'defense-space',
  'design',
  'education-management',
  'e-learning',
  'electrical-electronic-manufacturing',
  'entertainment',
  'environmental-services',
  'events-services',
  'executive-office',
  'facilities-services',
  'farming',
  'financial-services',
  'fine-art',
  'fishery',
  'food-beverages',
  'food-production',
  'fund-raising',
  'furniture',
  'gambling-casinos',
  'glass-ceramics-concrete',
  'government-administration',
  'government-relations',
  'graphic-design',
  'health-wellness-and-fitness',
  'higher-education',
  'hospital-health-care',
  'hospitality',
  'human-resources',
  'import-and-export',
  'individual-family-services',
  'industrial-automation',
  'information-services',
  'information-technology-and-services',
  'insurance',
  'international-affairs',
  'international-trade-and-development',
  'internet',
  'investment-banking',
  'investment-management',
  'judiciary',
  'law-enforcement',
  'law-practice',
  'legal-services',
  'legislative-office',
  'leisure-travel-tourism',
  'libraries',
  'logistics-and-supply-chain',
  'luxury-goods-jewelry',
  'machinery',
  'management-consulting',
  'maritime',
  'market-research',
  'marketing-and-advertising',
  'mechanical-or-industrial-engineering',
  'media-production',
  'medical-devices',
  'medical-practice',
  'mental-health-care',
  'military',
  'mining-metals',
  'motion-pictures-and-film-museums-and-institutions',
  'music',
  'nanotechnology',
  'newspapers',
  'non-profit-organization-management',
  'oil-energy',
  'online-media',
  'outsourcing-offshoring',
  'package-freight-delivery',
  'packaging-and-containers',
  'paper-forest-products',
  'performing-arts',
  'pharmaceuticals',
  'philanthropy',
  'photography',
  'plastics',
  'political-organization',
  'primary-secondary-education',
  'printing',
  'professional-training-coaching',
  'program-development',
  'public-relations-and-communications',
  'public-safety',
  'publishing',
  'railroad-manufacture',
  'real-estate',
  'recreational-facilities-and-services',
  'religious-institutions',
  'renewables-environment',
  'research',
  'restaurants',
  'retail',
  'security-and-investigations',
  'semiconductors',
  'shipbuilding',
  'sporting-goods',
  'staffing-and-recruiting-supermarkets',
  'telecommunications',
  'textiles',
  'think-tanks',
  'tobacco',
  'translation-and-localization',
  'transportation-trucking-railroad',
  'utilities',
  'venture-capital-private-equity-veterinary',
  'warehousing',
  'wholesale',
  'wine-and-spirits',
  'wireless',
  'writing-and-editing',
];

export const filterListLeadDetailsItems = [
  {
    label: LeadsTableAccessors.firstName,
    id: 'first_name',
  },
  {
    label: LeadsTableAccessors.lastName,
    id: 'last_name',
  },
  {
    label: LeadsTableAccessors.email,
    id: 'email',
  },
  {
    label: LeadsTableAccessors.location,
    id: 'location',
  },
  {
    label: LeadsTableAccessors.jobTitle,
    id: 'job_title',
  },
  {
    label: LeadsTableAccessors.country,
    id: 'person_country',
  },
  {
    label: LeadsTableAccessors.zip,
    id: 'person_zip_code',
  },
  {
    label: LeadsTableAccessors.phone,
    id: 'phone',
  },
  {
    label: LeadsTableAccessors.asset,
    id: 'asset_name',
  },
  {
    label: LeadsTableAccessors.company,
    id: 'company',
  },
  {
    label: LeadsTableAccessors.extension,
    id: 'extension',
  },
];

export const userDepartmentOptions = [
  'c-suite',
  'engineering-and-technical',
  'design',
  'education',
  'finance',
  'human-resources',
  'information-technology',
  'legal',
  'marketing',
  'medical-and-health',
  'operations',
  'sales',
  'consulting',
];

export const revenueOptions = [
  '0_1_m',
  '2_10_m',
  '11_20_m',
  '21_50_m',
  '51_100_m',
  '100_200_m',
  '200_500_m',
  '500_1_b',
  '1_b+',
  CustomDefaultValue.custom,
];

export const managementLevelOptions = [
  'owner',
  'founder',
  'c-suite',
  'partner',
  'vp',
  'head',
  'director',
  'manager',
  'senior',
  'entry',
  'intern',
];

export const companySizeOptions = [
  '1_10',
  '11_50',
  '51_200',
  '201_500',
  '501_1000',
  '1001_5000',
  '5000_10000',
  '10001+',
  CustomDefaultValue.custom,
];

export const assetTypes = [
  AssetTypes.it,
  AssetTypes.marketing,
  AssetTypes.accounting,
  AssetTypes.finance,
  AssetTypes.hr,
  AssetTypes.sales,
];

export const milestoneOptionsList = [
  MilestoneType.monthly,
  MilestoneType.custom,
];

export const milestoneTemplate = {
  id: null,
  delivery_date: new Date().toISOString(),
  total_leads_amount: 0,
};

export const geographyRegions = {
  [Regions['us-states']]: [
    {
      key: `${Regions['us-states']}.alabama`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.alaska`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.arizona`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.arkansas`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.california`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.colorado`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.connecticut`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.delaware`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.district-of-columbia`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.florida`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.georgia`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.hawaii`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.idaho`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.illinois`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.indiana`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.iowa`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.kansas`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.kentucky`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.louisiana`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.maine`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.maryland`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.massachusetts`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.michigan`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.minnesota`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.mississippi`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.missouri`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.montana`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.nebraska`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.nevada`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.new-hampshire`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.new-jersey`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.new-mexico`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.new-york`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.north-carolina`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.north-dakota`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.ohio`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.oklahoma`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.oregon`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.pennsylvania`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.rhode-island`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.south-carolina`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.south-dakota`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.tennessee`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.texas`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.utah`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.vermont`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.virginia`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.washington`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.west-virginia`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.wisconsin`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.wyoming`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.american-samoa`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.canal-zone`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.guam`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.puerto-rico`,
      region: Regions['us-states'],
    },
    {
      key: `${Regions['us-states']}.virgin-islands`,
      region: Regions['us-states'],
    },
  ],
  [Regions['ca-provinces']]: [
    {
      key: `${Regions['ca-provinces']}.alberta`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.british-columbia`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.manitoba`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.new-brunswick`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.newfoundland`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.northwest-territories`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.nova-scotia`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.nunavut`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.ontario`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.prince-edward-island`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.quebec`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.saskatchewan`,
      region: Regions['ca-provinces'],
    },
    {
      key: `${Regions['ca-provinces']}.yukon`,
      region: Regions['ca-provinces'],
    },
  ],
  [Regions.africa]: [
    { iso2: 'DZ', region: Regions.africa },
    { iso2: 'AO', region: Regions.africa },
    { iso2: 'BJ', region: Regions.africa },
    { iso2: 'BW', region: Regions.africa },
    { iso2: 'BF', region: Regions.africa },
    { iso2: 'BI', region: Regions.africa },
    { iso2: 'CM', region: Regions.africa },
    { iso2: 'CV', region: Regions.africa },
    { iso2: 'CF', region: Regions.africa },
    { iso2: 'TD', region: Regions.africa },
    { iso2: 'KM', region: Regions.africa },
    { iso2: 'CD', region: Regions.africa },
    { iso2: 'CG', region: Regions.africa },
    { iso2: 'DJ', region: Regions.africa },
    { iso2: 'EG', region: Regions.africa },
    { iso2: 'GQ', region: Regions.africa },
    { iso2: 'ER', region: Regions.africa },
    { iso2: 'ET', region: Regions.africa },
    { iso2: 'GA', region: Regions.africa },
    { iso2: 'GM', region: Regions.africa },
    { iso2: 'GH', region: Regions.africa },
    { iso2: 'GN', region: Regions.africa },
    { iso2: 'GW', region: Regions.africa },
    { iso2: 'KE', region: Regions.africa },
    { iso2: 'LS', region: Regions.africa },
    { iso2: 'LR', region: Regions.africa },
    { iso2: 'LY', region: Regions.africa },
    { iso2: 'MG', region: Regions.africa },
    { iso2: 'MW', region: Regions.africa },
    { iso2: 'ML', region: Regions.africa },
    { iso2: 'MR', region: Regions.africa },
    { iso2: 'MU', region: Regions.africa },
    { iso2: 'MA', region: Regions.africa },
    { iso2: 'MZ', region: Regions.africa },
    { iso2: 'NA', region: Regions.africa },
    { iso2: 'NE', region: Regions.africa },
    { iso2: 'NG', region: Regions.africa },
    { iso2: 'RW', region: Regions.africa },
    { iso2: 'ST', region: Regions.africa },
    { iso2: 'SN', region: Regions.africa },
    { iso2: 'SC', region: Regions.africa },
    { iso2: 'SL', region: Regions.africa },
    { iso2: 'SO', region: Regions.africa },
    { iso2: 'ZA', region: Regions.africa },
    { iso2: 'SD', region: Regions.africa },
    { iso2: 'SZ', region: Regions.africa },
    { iso2: 'TZ', region: Regions.africa },
    { iso2: 'TG', region: Regions.africa },
    { iso2: 'TN', region: Regions.africa },
    { iso2: 'UG', region: Regions.africa },
    { iso2: 'ZM', region: Regions.africa },
    { iso2: 'ZW', region: Regions.africa },
  ],
  [Regions.asia]: [
    { iso2: 'AF', region: Regions.asia },
    { iso2: 'AM', region: Regions.asia },
    { iso2: 'AZ', region: Regions.asia },
    { iso2: 'BH', region: Regions.asia },
    { iso2: 'BD', region: Regions.asia },
    { iso2: 'BT', region: Regions.asia },
    { iso2: 'BN', region: Regions.asia },
    { iso2: 'KH', region: Regions.asia },
    { iso2: 'CN', region: Regions.asia },
    { iso2: 'GE', region: Regions.asia },
    { iso2: 'IN', region: Regions.asia },
    { iso2: 'ID', region: Regions.asia },
    { iso2: 'IR', region: Regions.asia },
    { iso2: 'IL', region: Regions.asia },
    { iso2: 'JP', region: Regions.asia },
    { iso2: 'JO', region: Regions.asia },
    { iso2: 'KZ', region: Regions.asia },
    { iso2: 'KW', region: Regions.asia },
    { iso2: 'KG', region: Regions.asia },
    { iso2: 'LA', region: Regions.asia },
    { iso2: 'LB', region: Regions.asia },
    { iso2: 'MY', region: Regions.asia },
    { iso2: 'MV', region: Regions.asia },
    { iso2: 'MN', region: Regions.asia },
    { iso2: 'MM', region: Regions.asia },
    { iso2: 'NP', region: Regions.asia },
    { iso2: 'KP', region: Regions.asia },
    { iso2: 'OM', region: Regions.asia },
    { iso2: 'PK', region: Regions.asia },
    { iso2: 'PH', region: Regions.asia },
    { iso2: 'QA', region: Regions.asia },
    { iso2: 'SA', region: Regions.asia },
    { iso2: 'SG', region: Regions.asia },
    { iso2: 'KR', region: Regions.asia },
    { iso2: 'LK', region: Regions.asia },
    { iso2: 'SY', region: Regions.asia },
    { iso2: 'TW', region: Regions.asia },
    { iso2: 'TJ', region: Regions.asia },
    { iso2: 'TH', region: Regions.asia },
    { iso2: 'TR', region: Regions.asia },
    { iso2: 'TM', region: Regions.asia },
    { iso2: 'AE', region: Regions.asia },
    { iso2: 'UZ', region: Regions.asia },
    { iso2: 'VN', region: Regions.asia },
    { iso2: 'YE', region: Regions.asia },
  ],
  [Regions.europe]: [
    { iso2: 'AL', region: Regions.europe },
    { iso2: 'AD', region: Regions.europe },
    { iso2: 'AT', region: Regions.europe },
    { iso2: 'BY', region: Regions.europe },
    { iso2: 'BE', region: Regions.europe },
    { iso2: 'BA', region: Regions.europe },
    { iso2: 'BG', region: Regions.europe },
    { iso2: 'HR', region: Regions.europe },
    { iso2: 'CY', region: Regions.europe },
    { iso2: 'CZ', region: Regions.europe },
    { iso2: 'DK', region: Regions.europe },
    { iso2: 'EE', region: Regions.europe },
    { iso2: 'FI', region: Regions.europe },
    { iso2: 'FR', region: Regions.europe },
    { iso2: 'DE', region: Regions.europe },
    { iso2: 'GR', region: Regions.europe },
    { iso2: 'HU', region: Regions.europe },
    { iso2: 'IS', region: Regions.europe },
    { iso2: 'IE', region: Regions.europe },
    { iso2: 'IT', region: Regions.europe },
    { iso2: 'LV', region: Regions.europe },
    { iso2: 'LI', region: Regions.europe },
    { iso2: 'LT', region: Regions.europe },
    { iso2: 'LU', region: Regions.europe },
    { iso2: 'MK', region: Regions.europe },
    { iso2: 'MT', region: Regions.europe },
    { iso2: 'MD', region: Regions.europe },
    { iso2: 'MC', region: Regions.europe },
    { iso2: 'NL', region: Regions.europe },
    { iso2: 'NO', region: Regions.europe },
    { iso2: 'PL', region: Regions.europe },
    { iso2: 'PT', region: Regions.europe },
    { iso2: 'RO', region: Regions.europe },
    { iso2: 'RU', region: Regions.europe },
    { iso2: 'SM', region: Regions.europe },
    { iso2: 'SK', region: Regions.europe },
    { iso2: 'SI', region: Regions.europe },
    { iso2: 'ES', region: Regions.europe },
    { iso2: 'SE', region: Regions.europe },
    { iso2: 'CH', region: Regions.europe },
    { iso2: 'UA', region: Regions.europe },
    { iso2: 'GB', region: Regions.europe },
  ],
  [Regions['north-america']]: [
    { iso2: 'AG', region: Regions['north-america'] },
    { iso2: 'BS', region: Regions['north-america'] },
    { iso2: 'BB', region: Regions['north-america'] },
    { iso2: 'BZ', region: Regions['north-america'] },
    { iso2: 'CA', region: Regions['north-america'] },
    { iso2: 'CR', region: Regions['north-america'] },
    { iso2: 'CU', region: Regions['north-america'] },
    { iso2: 'DM', region: Regions['north-america'] },
    { iso2: 'DO', region: Regions['north-america'] },
    { iso2: 'SV', region: Regions['north-america'] },
    { iso2: 'GD', region: Regions['north-america'] },
    { iso2: 'GT', region: Regions['north-america'] },
    { iso2: 'HT', region: Regions['north-america'] },
    { iso2: 'HN', region: Regions['north-america'] },
    { iso2: 'JM', region: Regions['north-america'] },
    { iso2: 'MX', region: Regions['north-america'] },
    { iso2: 'NI', region: Regions['north-america'] },
    { iso2: 'PA', region: Regions['north-america'] },
    { iso2: 'KN', region: Regions['north-america'] },
    { iso2: 'VC', region: Regions['north-america'] },
    { iso2: 'TT', region: Regions['north-america'] },
    { iso2: 'US', region: Regions['north-america'] },
  ],
  [Regions.oceania]: [
    { iso2: 'AU', region: Regions.oceania },
    { iso2: 'CK', region: Regions.oceania },
    { iso2: 'FJ', region: Regions.oceania },
    { iso2: 'KI', region: Regions.oceania },
    { iso2: 'NZ', region: Regions.oceania },
    { iso2: 'PG', region: Regions.oceania },
    { iso2: 'WS', region: Regions.oceania },
    { iso2: 'SB', region: Regions.oceania },
    { iso2: 'TL', region: Regions.oceania },
    { iso2: 'TO', region: Regions.oceania },
    { iso2: 'VU', region: Regions.oceania },
  ],
  [Regions['south-america']]: [
    { iso2: 'AR', region: Regions['south-america'] },
    { iso2: 'BO', region: Regions['south-america'] },
    { iso2: 'BR', region: Regions['south-america'] },
    { iso2: 'CL', region: Regions['south-america'] },
    { iso2: 'CO', region: Regions['south-america'] },
    { iso2: 'EC', region: Regions['south-america'] },
    { iso2: 'GY', region: Regions['south-america'] },
    { iso2: 'PY', region: Regions['south-america'] },
    { iso2: 'PE', region: Regions['south-america'] },
    { iso2: 'SR', region: Regions['south-america'] },
    { iso2: 'UY', region: Regions['south-america'] },
    { iso2: 'VE', region: Regions['south-america'] },
  ],
};

export const timezones = [
  { label: 'US Eastern Time (ET)', value: 'et', code: 'US/Eastern' },
  { label: 'US Central Time (CT)', value: 'ct', code: 'US/Central' },
  { label: 'US Mountain Time (MT)', value: 'mt', code: 'US/Mountain' },
  { label: 'US Pacific Time (PT)', value: 'pt', code: 'US/Pacific' },
];

export const pageSizes = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: 30, label: 30 },
];
