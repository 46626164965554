export const isURL = (url: string) => {
  const urlRegex =
    /^(?:(?:https?|ftps):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/g;

  const links = url.match(urlRegex);

  return !!(Array.isArray(links) && links[0]);
};

export const convertURL = (url: string): string => {
  const validProtocolsRegexp = /^(https?:\/\/)|(ftps?:\/\/)/;
  if (url.match(validProtocolsRegexp)) return url;

  const replaceProtocolRegexp = /^.*?\/\//;
  const replacedProtocolValue = url.replace(replaceProtocolRegexp, '');

  const replacedRedundantSlashValue = replacedProtocolValue.replace(/^\//, '');

  return `https://${replacedRedundantSlashValue}`;
};
