import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import axiosDefault from 'axios';
import { v4 as uuidv4 } from 'uuid';

import type { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';
import type { TFunction } from 'react-i18next';
import type { AxiosError } from 'axios';
import {
  ProductPricingPlanResponse,
  ProductPricingPlanImage,
  ProductPricingPlanPeriods,
  ProductPricingPlanMonthlyAnnuallyPeriods,
} from 'components/Product/models';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import Overview from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/Overview';
import Benefits from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/Benefits';
import Pricing from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/Pricing';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { validationSchemaCreatePricingPlan } from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/validationSchema';

import styles from './index.module.scss';

type Props = {
  defaultValues?: ProductPricingPlanResponse;
  companySlug: string;
  productSlug: string;
  productId: number;
  planId?: number;
};

const DEFAULT_CURRENCY = 'USD';

const convertResponseToDefaultValues = (
  pricingPlanResponse: ProductPricingPlanResponse,
  t: TFunction
): ProductPricingPlanFormValues => {
  const pricingPlanDetails = pricingPlanResponse.period_details.map(item => ({
    ...item,
    isFree: Number(item.price) === 0,
    isChecked: true,
  }));
  const isLacksPeriods =
    pricingPlanResponse.period ===
      ProductPricingPlanPeriods['monthly-or-annually'] &&
    pricingPlanResponse.period_details.length === 1;
  if (isLacksPeriods) {
    const selectedPeriod = pricingPlanResponse.period_details[0];
    const basePlanDetailsProperties = {
      price: '',
      isChecked: false,
      isFree: false,
      link: '',
      link_title: '',
    };
    if (
      selectedPeriod.name === ProductPricingPlanMonthlyAnnuallyPeriods.monthly
    ) {
      pricingPlanDetails.push({
        ...basePlanDetailsProperties,
        name: ProductPricingPlanMonthlyAnnuallyPeriods.annual,
      });
    } else {
      pricingPlanDetails.unshift({
        name: ProductPricingPlanMonthlyAnnuallyPeriods.monthly,
        ...basePlanDetailsProperties,
      });
    }
  }

  const defaultImagesArray = new Array(4)
    .fill({ image: null, image_original: null })
    .map((item, index) => ({ ...item, order: index, uuid: uuidv4() }));

  pricingPlanResponse.images.forEach(image => {
    defaultImagesArray[image.order] = {
      ...image,
      image: { renderLink: image.image },
      uuid: uuidv4(),
    };
  });

  return {
    ...pricingPlanResponse,
    images: defaultImagesArray,
    currency: {
      value: pricingPlanResponse.currency,
      label: pricingPlanResponse.currency,
    },
    period: {
      value: pricingPlanResponse.period,
      label: t(`common.field.${pricingPlanResponse.period}`),
    },
    period_details: pricingPlanDetails,
  };
};

const CreatePricingPlanModal = ({
  defaultValues,
  companySlug,
  productSlug,
  planId,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const formMethods = useForm<ProductPricingPlanFormValues>({
    resolver: yupResolver(validationSchemaCreatePricingPlan),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: defaultValues
      ? convertResponseToDefaultValues(defaultValues, t)
      : {
          currency: { value: DEFAULT_CURRENCY, label: DEFAULT_CURRENCY },
          period: {
            value: ProductPricingPlanPeriods['monthly-or-annually'],
            label: t(
              `common.field.${ProductPricingPlanPeriods['monthly-or-annually']}`
            ),
          },
          period_details: [
            {
              name: ProductPricingPlanMonthlyAnnuallyPeriods.monthly,
              price: '',
              link: '',
              link_title: '',
              isFree: false,
              isChecked: true,
            },
            {
              name: ProductPricingPlanMonthlyAnnuallyPeriods.annual,
              price: '',
              link: '',
              link_title: '',
              isFree: false,
              isChecked: true,
            },
          ],
          images: new Array(4)
            .fill({ image: null, image_original: null })
            .map((item, index) => ({ ...item, order: index })),
        },
  });

  const { mutateAsync } = useMutation<
    PromiseSettledResult<ProductPricingPlanImage>[],
    AxiosError,
    { images: ProductPricingPlanFormValues['images']; pricingPlanId: number }
  >(async ({ images, pricingPlanId }) => {
    const promises: Promise<ProductPricingPlanImage>[] = [];

    images.forEach(imageData => {
      const formData = new FormData();
      if (imageData.image?.file) {
        formData.append('image', imageData.image.file);
      }
      if (
        typeof imageData.image_original === 'object' &&
        imageData.image_original
      ) {
        formData.append('image_original', imageData.image_original);
      }
      formData.append('order', imageData.order.toString());

      promises.push(
        imageData.id
          ? axios
              .patch<ProductPricingPlanImage>(
                `/companies/${companySlug}/products/${productSlug}/pricing-plans/${pricingPlanId}/pricing-plan-images/${imageData.id}/`,
                formData
              )
              .then(({ data }) => data)
          : axios
              .post<ProductPricingPlanImage>(
                `/companies/${companySlug}/products/${productSlug}/pricing-plans/${pricingPlanId}/pricing-plan-images/`,
                formData
              )
              .then(({ data }) => data)
      );
    });

    return Promise.allSettled(promises);
  });

  const {
    mutate: createPricingPlan,
    isLoading,
    error,
  } = useMutation<
    | (Omit<ProductPricingPlanResponse, 'images'> & {
        images: PromiseSettledResult<ProductPricingPlanImage>[];
      })
    | void,
    AxiosError<ProductPricingPlanResponse>,
    Omit<
      ProductPricingPlanResponse,
      'id' | 'product_id' | 'benefits' | 'images'
    > & {
      benefits: { name: string }[];
      images: ProductPricingPlanFormValues['images'];
    }
  >(
    async pricingPlanData => {
      let data;
      try {
        const { data: responseData } = planId
          ? await axios.patch<ProductPricingPlanResponse>(
              `/companies/${companySlug}/products/${productSlug}/pricing-plans/${planId}/`,
              pricingPlanData
            )
          : await axios.post<ProductPricingPlanResponse>(
              `/companies/${companySlug}/products/${productSlug}/pricing-plans/`,
              pricingPlanData
            );

        data = responseData;
      } catch (err) {
        throw err;
      }

      const resolvedImages = await mutateAsync({
        pricingPlanId: data.id,
        images: pricingPlanData.images,
      });

      resolvedImages.forEach((image, index) => {
        if (
          image.status === 'rejected' &&
          axiosDefault.isAxiosError(image.reason)
        ) {
          formMethods.setError(`images.${index}`, {
            message: getResponseError(image.reason),
          });
        }
      });

      return { ...data, images: resolvedImages };
    },
    {
      onSuccess: data => {
        if (!data) return;
        if (data.images.some(image => image.status === 'rejected')) {
          throw new Error();
        }

        const images = data.images
          .map(image => ('value' in image ? image.value : null))
          .filter(image => image) as ProductPricingPlanImage[];
        const updatedPricingPlanData = { ...data, images };

        queryClient.setQueryData<ProductPricingPlanResponse[]>(
          ['get-pricing-plans', companySlug, productId?.toString()],
          oldData => {
            const oldPlansArray = oldData ? [...oldData] : [];
            const planIndex = oldPlansArray.findIndex(
              plan => plan.id === updatedPricingPlanData.id
            );
            const insertIndex =
              planIndex !== -1 ? planIndex : oldPlansArray.length;

            oldPlansArray[insertIndex] = updatedPricingPlanData;

            return oldData ? oldPlansArray : [updatedPricingPlanData];
          }
        );
        closeModal();
      },
    }
  );

  const handleSubmit = (values: ProductPricingPlanFormValues) => {
    const isMonthlyOrAnnuallyNotChecked =
      values.period.value ===
        ProductPricingPlanPeriods['monthly-or-annually'] &&
      values.period_details.every(
        (detail: ProductPricingPlanFormValues['period_details'][0]) =>
          !detail.isChecked
      );

    if (isMonthlyOrAnnuallyNotChecked) {
      return values.period_details.forEach((_, index) =>
        formMethods.setError(`period_details.${index}.isChecked`, {
          message: t('common.error.select-at-least-one-option'),
        })
      );
    }
    createPricingPlan({
      ...values,
      currency: values.currency.value,
      period: values.period.value,
      period_details: values.period_details.filter(detail => detail.isChecked),
      images: values.images.filter(item => !!item.image),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <ModalWindow
          isLockBodyScroll
          closeOnClickAway={false}
          className={styles.wrapper}
          primaryTitle={`${t(
            defaultValues ? 'common.button.edit' : 'common.button.add'
          )} ${t('manage-products.pricing-plan')}`}
          hasSubmitButton
          topSubmitButtonName={
            defaultValues
              ? t('common.button.save')
              : `${t('common.button.add')} ${t('manage-products.plan')}`
          }
        >
          <div className={styles.sections}>
            <Overview
              submitErrors={error?.response}
              companySlug={companySlug}
              productSlug={productSlug}
              pricingPlanId={planId}
              productId={productId}
            />
            <Benefits submitErrors={error?.response} />
            <Pricing submitErrors={error?.response} />
          </div>
        </ModalWindow>
      </form>
      {isLoading && <LoaderScreen />}
    </FormProvider>
  );
};

export default CreatePricingPlanModal;
