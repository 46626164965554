import { useNavigate } from 'react-router-dom';

import SideMenu from 'components/DocumentsContent/ServiceContract/SideMenu';
import ScrollToTopButton from 'components/DocumentsContent/ScrollToTopButton';

import { CommonPaths } from 'constants/routes';
import { ServiceContractIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const ServiceContract = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.main}>
      <SideMenu />
      <div className={styles.content_container}>
        <h1 className={styles.content_header}>Service Contract</h1>
        <div className={styles.noteWrapper}>
          Effective Date:
          <span>October 7, 2022</span>
          <div className={styles.semibold}>Version: 2.0</div>
        </div>

        <div className={styles.text}>
          <p>
            This SERVICE CONTRACT (this
            <span className={styles.semibold}> “Contract”</span>) is made and
            entered into by and between one party (the
            <span className={styles.semibold}> “Client”</span>) and other party
            (the <span className={styles.semibold}> “Vendor”</span>), which are
            authorized users of the BeeLeads platform available at{' '}
            <a href="https://dashboard.beeleads.ai" className={styles.link}>
              https://dashboard.beeleads.ai
            </a>{' '}
            including its subdomains, software, components, API, and
            applications relating thereto (the
            <span className={styles.semibold}> “Platform”</span>). The Platform
            is provided by Leadforce AI, Inc., a Delaware corporation, 2055
            Limestone Rd STE 200-C, Wilmington, in the County of New Castle,
            Delaware, USA (<span className={styles.semibold}> “BeeLeads”</span>
            ), and is governed by the
            <span
              className={styles.linkToPage}
              onClick={() =>
                navigate(`/${CommonPaths.DOCUMENTS}/${CommonPaths.TERMS}`)
              }
            >
              BeeLeads Terms of Service
            </span>
            (the
            <span className={styles.semibold}> “Terms”</span>).
          </p>
          <p>
            Unless otherwise defined herein, capitalized terms shall have the
            meaning assigned in the Terms.
          </p>
          <p>
            As the case may be, the party that creates and manages Campaigns
            through the Platform and desires to engage the other party to render
            the Services shall be hereinafter referred to as the
            <span className={styles.semibold}> “Client”</span>, and the other
            party that accepts the engagement and is willing to perform the
            Services to the Client shall be referred to as the
            <span className={styles.semibold}> “Vendor”</span>. The Client and
            the Vendor may be jointly hereinafter referred to as
            <span className={styles.semibold}> “Parties”</span> and each
            separately as a <span className={styles.semibold}>“Party.”</span>
          </p>
          <p>
            If and to the extent that the Parties have not agreed to the Direct
            Contract, the Parties agree, confirm, and acknowledge that this
            Contract is legally binding for them and applies to their
            cooperation on and through the Platform as if the Parties have each
            caused to be affixed hereto its or his/her hand. The Client and
            Vendor, who have engaged in negotiations and discussions and desire
            to enter into a certain legally binding contractual relationship,
            are free to agree to a Direct Contract, in which case this Contract
            will not apply unless otherwise stated in the Direct Contract.
          </p>
          <p>
            This Contract is a sample only, which may not be appropriate for the
            Parties&apos; cooperation and which may be adjusted and added to as
            the Parties deem appropriate. The Parties acknowledge that they have
            discretion whether to contract with each other and may negotiate and
            determine the specific terms of their cooperation, acting at their
            sole and absolute discretion. Thus notwithstanding, in each case,
            the Parties shall not alter the rights, obligations, or
            responsibilities of BeeLeads by any agreements and negotiations,
            whether written or oral, or agree to any terms and conditions that
            constitute any breach or violation of the Terms. BeeLeads is not or
            does not intend to be a party to this Contract or any other
            contracts and agreements, executed between the Parties, regardless
            of whether the terms and conditions contained herein, wholly or
            partially, are incorporated thereto.
          </p>
        </div>
        <div className={styles.chapter} id={ServiceContractIDs.interpretation}>
          <h2 className={styles.subheading}>1. Interpretation</h2>
          <p>
            <span className={styles.semibold}>1.1 </span>The following
            definitions shall apply, unless otherwise expressly stated in this
            Contract:
          </p>
          <p>
            <span className={styles.semibold}>“Client Content”</span>
            means any data, materials, information, Intellectual Property,
            Client’s Confidential Information, copyrighted content, and
            trademarks provided by the Client or otherwise made available to the
            Vendor by the Client, including by displaying on the Platform.
          </p>
          <p>
            <span className={styles.semibold}>“Compensation”</span> has the
            meaning provided in Clause 3.1 of this Contract.
          </p>
          <p>
            <span className={styles.semibold}>“Confidential Information” </span>
            means all information not publicly known that is disclosed by the
            Client to the Vendor in connection with the performance of this
            Contract and/or the Services, including, but not limited to,
            technical data, know-how, Company Content, non-public details
            relating to the Campaign, business and product or service plans,
            business forecasts, business plans, projects, and any other
            information which would, under the circumstances, appear to a
            reasonable person to be confidential or proprietary. Notwithstanding
            the foregoing, the Confidential Information does not include
            information that: (I) is or becomes publicly known or generally
            known other than as a result of the Vendor’s breach of its
            obligations hereunder, (II) was known to the Vendor without
            restriction as to use or disclosure, at the time of disclosure, or
            (III) is required to be disclosed by a valid order or requirement of
            a court, administrative agency or other governmental body, provided
            that the Vendor shall promptly notify the Client of such disclosure.
            The Vendor shall be solely responsible for the sufficient and
            adequate proving of the aforementioned circumstances.
          </p>
          <p>
            <span className={styles.semibold}>1.2. </span>Unless the context
            otherwise requires, a reference to one gender shall include a
            reference to the other genders; words in the singular shall include
            the plural and in the plural shall include the singular; any words
            following the terms including, include, in particular, for example
            or any similar expression shall be construed as illustrative and
            shall not limit the sense of the words, description, definition,
            phrase or term preceding those terms; Section headings do not affect
            the interpretation of this Contract.
          </p>
          <p>
            Each provision of this Contract shall be construed as though both
            Parties participated equally in the drafting of the same, and the
            Parties hereby agree and confirm that a rule of construction does
            not apply to the disadvantage of any Party.
          </p>
        </div>
        <div className={styles.chapter} id={ServiceContractIDs.services}>
          <h2 className={styles.subheading}>2. Services</h2>
          <p>
            <span className={styles.semibold}>2.1 </span>The Client hereby
            retains the Services of the Vendor, which shall be subject to the
            terms of that certain Campaign related to the Services, and the
            Vendor shall be entitled to receive certain consideration for the
            Services rendered to the Client as may be agreed between the
            Parties. The Vendor will render the Services in a timely and
            professional manner, and in accordance with this Contract, good
            industry practice, if and where applicable, and other terms and
            conditions concerning the Services as specified in the Terms or on
            the Platform.
          </p>
          <p>
            <span className={styles.semibold}>2.2. </span>The terms and
            conditions concerning the Services to be performed under this
            Contract which are agreed between the Parties and reflected on the
            Platform, including in the terms and conditions of certain
            Campaigns, form an integral part of this Contract and are legally
            binding for the Parties (
            <span className={styles.semibold}>the “Binding Terms”</span>). For
            the avoidance of doubt, the Parties may mutually amend, alter or
            modify the Binding Terms (
            <span className={styles.semibold}>the “Amended Binding Terms”</span>
            ), in which case those Amended Binding Terms shall be duly reflected
            on the Platform in the description of the respective Campaign(s). If
            the Amended Binding Terms are not reflected on the Platform, in case
            of any discrepancies between the Binding Terms reflected on the
            Platform and any Amended Binding Terms, which are not reflected on
            the Platform, the Binding Terms reflected on the Platform shall
            control.
          </p>
          <p>
            <span className={styles.semibold}>2.3. </span>
            The Vendor is free to choose the place, time and approach for the
            provision of the Services. The Vendor shall search Leads for the
            Client within the terms of a certain Campaign by its own means or
            with the use of Platform’s functionality, if available, and provide
            a Report in a workmanlike and timely manner.
          </p>
          <p>
            <span className={styles.semibold}>2.4. </span>
            The Vendor may engage third persons (other than its employees) to
            render Services hereunder or subcontract the Services without
            Client’s consent, provided that before any such third person is
            engaged, the Vendor shall (I) execute with such person relevant
            agreements, providing the terms and conditions, including concerning
            the intellectual property and confidentiality obligations, at least
            of the same scope and nature as those contained herein, (II) not be
            relieved from its obligations hereunder, and (III) make sure that
            all terms of this Contract shall be fully effective and enforceable
            with regard to such third person.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.compensation_and_payment_terms}
        >
          <h2 className={styles.subheading}>
            3. Compensation and Payment Terms
          </h2>
          <p>
            <span className={styles.semibold}>3.1. </span>
            In consideration of the Services, the Vendor shall be entitled to
            receive the compensation (the
            <span className={styles.semibold}> “Compensation”</span>) on the
            terms set forth herein, outlined in the description of certain
            Campaign and/or otherwise agreed between the Parties in writing.
          </p>
          <p>
            <span className={styles.semibold}>3.2. </span>
            In connection with each Payable Action, the Vendor shall be entitled
            to receive the Compensation that shall be equal to the sum due to
            the Vendor for such Payable Actions.
          </p>
          <p>
            <span className={styles.semibold}>3.3. </span>
            Any Compensation due to the Vendor shall be accrued by deducting the
            respective amounts corresponding to the number of Payable Actions
            from the respective Campaign Balance. Further, the Vendor Platform
            Balance will be credited with the Compensation corresponding to the
            number of Payable Actions within the specific Campaign.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.intellectual_property_rights}
        >
          <h2 className={styles.subheading}>4. Intellectual Property Rights</h2>
          <p>
            <span className={styles.semibold}>4.1. </span>
            In order to provide the Services, the Vendor may access and use the
            Client Content. Any such access or use shall be for the term of the
            respective Campaign and only to perform the Services by the Vendor.
          </p>
          <p>
            <span className={styles.semibold}>4.2. </span>
            The Client hereby grants the Vendor a limited, temporary,
            non-exclusive, revocable (at any time, at Client’s sole and absolute
            discretion), royalty-free, non-transferable, terminable, worldwide
            license (without the right to sublicense) to use the Client Content
            as necessary solely for the performance of the Services hereunder
            and during the term of the respective Campaign.
          </p>
          <p>
            <span className={styles.semibold}>4.3. </span>
            It is expressly understood that nothing in this Contract grants the
            Vendor any title, rights, or interest in or to the Client Content,
            other than the limited right to use the Client Content as explicitly
            outlined herein and the Vendor shall not register or apply for
            registration of any rights in or to the Client Content.
          </p>
          <p>
            <span className={styles.semibold}>4.4. </span>
            The Vendor hereby acknowledges and agrees that any and all rights
            conferred under statute, common law, copyright, or equity, now or in
            the future, whether registered or unregistered and wherever in the
            world subsisting in and related to the Report (or its part) shall
            vest in and remain the sole ownership of the Client immediately once
            the respective Report (or its part) is approved by the Client.
          </p>
          <p>
            <span className={styles.semibold}>4.5. </span>
            Upon expiration or termination of this Contract, or upon the
            Client’s written request, the Vendor shall promptly return all
            Client Content to the Client and further agrees to destroy, deleted
            all copies thereof, contained in or on Vensor’s premises, systems,
            or any other equipment or location otherwise under Vendor’s control.
            Within five (5) calendar days of such the Client’s request, the
            Vendor agrees to provide written confirmation to the Client that the
            Vendor has returned or destroyed all Client Content as provided
            herein.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.confidential_information}
        >
          <h2 className={styles.subheading}>5. Confidential Information</h2>
          <p>
            <span className={styles.semibold}>5.1. </span>
            If and to the extent either the Client provides any Confidential
            Information to the Vendor, the Vendor will protect the secrecy of
            the Client’s Confidential Information with the same degree of care
            as it uses to protect its own Confidential Information, but in no
            event with less than due care, and will:
          </p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>a&#041; </span>
              not disclose or permit others to disclose Confidential Information
              to anyone without first obtaining the express written consent of
              the owner of the Confidential Information, except as reasonably
              necessary for the performance of the Services;
            </p>
            <p>
              <span className={styles.semibold}>b&#041; </span>
              not use or permit the use of Client’s Confidential Information,
              except as necessary for the performance of the Services
              (including, without limitation, the storage or transmission of
              Confidential Information on or through the Platform for the use by
              the Vendor or Client);
            </p>
            <p>
              <span className={styles.semibold}>c&#041; </span>
              limit access to Client’s Confidential Information to Vendor’s
              employees, agents, or subcontractors who need to know such
              information for the performance of the Services, provided that all
              such persons are subject to substantially the same requirements as
              outlined herein;
            </p>
            <p>
              <span className={styles.semibold}>d&#041; </span>
              upon request by the Client, return all Confidential Information to
              the Client within five (5) calendar days, without retaining
              copies; and
            </p>
            <p>
              <span className={styles.semibold}>e&#041; </span>
              be fully responsible for the actions of any third parties involved
              regarding the Client’s Confidential Information.
            </p>
          </div>
          <p>
            <span className={styles.semibold}>5.2. </span>
            Provisions of this Section 5 shall survive any termination or
            expiration of this Contract for the period of 3 (three) years.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.warranties_and_representations}
        >
          <h2 className={styles.subheading}>
            6. Warranties and Representations
          </h2>
          <p>
            <span className={styles.semibold}>6.1. </span>
            Each of the Parties hereby represents and warrants for itself to the
            other Party that:
          </p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>a&#041; </span>
              it has the right, power and authority to enter into this Contract,
              and to perform its obligations hereunder;
            </p>
            <p>
              <span className={styles.semibold}>b&#041; </span>
              its execution and performance of this Contract has been duly
              authorized by all necessary actions and resolutions, and the
              execution and performance of this Contract by such Party does not
              violate or constitute a material default under (i) the provisions
              of the formation or similar organizational documents of such
              Party, (ii) any contract to which it is a party; or (iii) any
              provision of any judgment, decree or order to which such Party is
              a party, by which it is bound, or to which any of its material
              assets are subject;
            </p>
            <p>
              <span className={styles.semibold}>c&#041; </span>
              the person(s) entering into this Contract has (have) authority to
              bind the Party to this Contract and that this Contract will
              legally bind both the Client and the Vendor;
            </p>
            <p>
              <span className={styles.semibold}>d&#041; </span>
              this Contract is executed solely be and between the Parties and
              for their benefit; therefore BeeLeads is not a party to this
              Contract and, in no event, shall be responsible or held liable for
              any damages and losses of any kind arising from or in connection
              herewith; and
            </p>
            <p>
              <span className={styles.semibold}>e&#041; </span>
              it is not a resident of, domiciled in, or representing or acting
              on behalf of an entity established, incorporated or registered in
              or under the laws of a country or territory that is subject to any
              sanctions administered or enforced by any country, government or
              international authority.
            </p>
          </div>
          <p>
            <span className={styles.semibold}>6.2. </span>
            The express warranties in this Contract are in lieu of all other
            warranties (except for the warranties and representations outlined
            in the Terms, express, implied, or statutory regarding the Services
            and the Parties hereby expressly disclaims all other warranties of
            any kind, express or implied, arising from the course of dealing,
            course of performance or otherwise.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.limitation_of_liability}
        >
          <h2 className={styles.subheading}>7. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted under the law, under no
            circumstances shall (I) either Party be liable to the other Party
            for any indirect or consequential damages or losses, including loss
            of profit, loss of data, loss of business, diminution of value,
            damages claimed by any other party in connection herewith,
            regardless of whether such damages and losses were foreseeable or
            not, and (II) either Party’s aggregate liability to the other Party
            under this Contract or in connection with the performance hereof
            exceed the sum equal to the Compensation due to and/or received by
            the Vendor hereunder. Provisions of this Section 7 shall not apply
            (a) in the event of fraud, gross negligence, willful misconduct, and
            (b) to the Party’s obligations under Section 8 (Indemnification) and
            in the event of violation of Section 5 (Confidential Information).
          </p>
        </div>
        <div className={styles.chapter} id={ServiceContractIDs.indemnification}>
          <h2 className={styles.subheading}>8. Indemnification</h2>
          <p>
            <span className={styles.semibold}>8.1. </span>
            The Vendor shall indemnify, defend and hold the Client harmless from
            and against any liability, losses, third-party demands, claims or
            suits that are based on or arise in connection with the Vendor’s
            non-performance of the terms hereof, breach of any representations
            or warranties, or violation of any third-party rights by the
            Services, to the fullest extent permitted under applicable law, in
            each case whether or not caused by the negligence of the Client,
            whether or not the relevant claim has merit or was foreseeable at
            the moment of this Contract execution.
          </p>
          <p>
            <span className={styles.semibold}>8.2. </span>
            The Client shall indemnify, defend and hold the Vendor harmless from
            and against any liability, losses, third-party demands, claims or
            suits that are based on or arise in connection with the Client
            Content, provided to the Vendor in connection with the performance
            of the Services, breach of any representations or warranties, or
            violation of any third-party rights by the Client Content, to the
            fullest extent permitted under applicable law, in each case whether
            or not caused by the negligence of the Vendor, whether or not the
            relevant claim has merit or was foreseeable at the moment of this
            Contract execution.
          </p>
          <p>
            <span className={styles.semibold}>8.3. </span>
            The Parties hereby indemnify, defend and hold the BeeLeads and its
            directors, shareholders, officers, agents and employees
            (collectively,
            <span className={styles.semibold}> “Indemnified Parties”</span>)
            harmless from and against any and all actions, claims, suits,
            demands, losses, damages, obligations or liabilities of any nature
            whatsoever, whether known or unknown, foreseeable or unforeseeable,
            and, in each case, whether or not caused by the negligence of the
            Indemnified Parties, which the Parties or any of their employees,
            agents, successors, assigns, legal representatives, affiliates,
            directors and officers have had, now have or may in the future have
            directly or indirectly arising out of (or in connection with) this
            Contract, performance of the Services, or any other activities
            undertaken pursuant hereto or in connection therewith, including but
            not limited to potential disputes arising out of performance and
            termination of this Contract and/or the Services and other
            obligations hereunder, including those that are unknown,
            unanticipated or unsuspected or which may later arise as a result of
            the discovery of new or additional facts.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.term_and_termination}
        >
          <h2 className={styles.subheading}>9. Term and Termination</h2>
          <p>
            <span className={styles.semibold}>9.1. </span>
            This Contract enters into force once the Parties agreed and accepted
            the terms and conditions of the respective Campaign and shall remain
            in force and effect until terminated as provided herein.
          </p>
          <p>
            <span className={styles.semibold}>9.2. </span>
            This Contract may be terminated in the following circumstances (I)
            by either Party at any time by a written notice to the other Party;
            (II) as a result of expiration or termination of the respective
            Campaign; or (III) upon completion of the Services rendered
            hereunder. Except as expressly set forth to the contrary in this
            Contract, an termination or expiration of this Contract will not
            affect the rights or remedies accrued by either Party prior to the
            termination or expiration date.
          </p>
          <p>
            <span className={styles.semibold}>9.3. </span>
            Except as required by law or as otherwise may be agreed by the
            Parties, the Vendor shall be entitled to receive all Compensation
            due hereunder for the Services rendered prior to the termination or
            expiration date of this Contract.
          </p>
          <p>
            <span className={styles.semibold}>9.4. </span>
            The obligations and duties of the Parties which by their nature
            extend beyond the expiration or termination of this Contract shall
            survive any termination or expiration of this Contract, regardless
            of reason.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.no_partnership_or_agency}
        >
          <h2 className={styles.subheading}>10. No Partnership or Agency</h2>
          <p>
            The Vendor’s relationship with the Client will be that of an
            independent contractor and not that of an employee. Nothing in this
            Contract establishes or is intended to, or shall be deemed to,
            establish any partnership, joint venture or employment relations
            between the Parties, constitute one Party the agent of another
            Party, or authorize either Party to make or enter into any
            commitments that bind another Party, create any obligations on the
            part of another Party or make the public statements in the name of
            or on behalf of another Party, without the prior written
            authorisation of such Party.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.applicable_law_and_dispute_resolution}
        >
          <h2 className={styles.subheading}>
            11. Applicable Law and Dispute Resolution
          </h2>
          <p>
            Unless otherwise agreed between the Parties, this Contract shall be
            governed by and construed in accordance with the laws of the State
            of Delaware, USA. The United Nations Convention on Contracts for the
            International Sale of Goods shall not apply to this Contract. The
            courts of Delaware shall have exclusive jurisdiction to settle any
            dispute, arising out of or in connection with this Contract
            (including a dispute relating to the existence, validity or
            termination of this Contract or any non-contractual obligation
            arising out of or in connection with this Contract) or the
            consequences of its nullity. Any court proceedings and related
            information shall be confidential and shall not be disclosed by
            either Party (other than to its legal counsel) to the fullest extent
            possible under the law.
          </p>
        </div>
        <div
          className={styles.chapter}
          id={ServiceContractIDs.personal_data_and_data_protection}
        >
          <h2 className={styles.subheading}>
            12. Personal Data and Data Protection
          </h2>
          <p>
            <span className={styles.semibold}>12.1. </span>
            While performing this Contract, the Services or otherwise processing
            personal data (information) in connection therewith, the Parties
            shall comply with all applicable data protection, privacy,
            anti-spam, unfair competition, electronic communication, and other
            relevant legislation. This Section 12 is an addition to and does not
            relieve, remove or replace a Party&apos;s obligations and rights in
            relation to the processing of personal data (information) under the
            applicable laws.
          </p>
          <p>
            <span className={styles.semibold}>12.2. </span>
            The Client and the Vendor are solely responsible for determining and
            deciding whether to enter into any non-disclosure (confidentiality)
            agreement, data processing agreement, and/or any other agreement,
            which will set forth particular rights and obligations of the
            Parties with respect to the processing of personal data
            (information).
          </p>
        </div>
        <div className={styles.chapter} id={ServiceContractIDs.miscellaneous}>
          <h2 className={styles.subheading}>13. Miscellaneous</h2>
          <p>
            <span className={styles.semibold}>13.1. </span>
            If any provision or part-provision of this Contract shall be held by
            a court to be invalid, unenforceable, or void, such provision shall
            be enforced to the fullest extent permitted by law, and the
            remainder of this Contract shall remain in full force and effect. In
            the event that the time period or scope of any provision is declared
            by a court of competent jurisdiction to exceed the maximum time
            period or scope that such court deems enforceable, then such court
            shall reduce the time period or scope to the maximum time period or
            scope permitted by law.
          </p>
          <p>
            <span className={styles.semibold}>13.2. </span>
            This Contract shall be construed as a whole, according to its fair
            meaning, and not in favor of or against any Party. Captions are used
            for reference purposes only and should be ignored in the
            interpretation of this Contract. The Parties hereby agree and
            confirm that a rule of construction does not apply to the
            disadvantage of BeeLeads because it was responsible for the
            preparation of this Contract.
          </p>
          <p>
            <span className={styles.semibold}>13.3. </span>
            All notices, requests, demands and other communications which are
            required or may be given pursuant to the terms of this Contract
            shall be in writing and shall be deemed delivered on the date of
            transmission when sent by electronic mail during normal business
            hours of the recipient, otherwise — on the following business day.
            In case a notice is sent via regular mail or courier, it shall be
            deemed to be delivered when received by the recipient. Any notices
            given by email shall be of full legal force and effect. The Parties
            shall use the means of communication and addresses and/or emails
            informed by either Party to another Party in writing
          </p>
          <p>
            <span className={styles.semibold}>13.4. </span>
            No failure or delay by a Party to exercise any right or remedy
            provided under this Contract or by law shall constitute a waiver of
            that or any other right or remedy, nor shall it preclude or restrict
            the further exercise of that or any other right or remedy. No single
            or partial exercise of such right or remedy shall preclude or
            restrict the further exercise of that or any other right or remedy.
          </p>
          <p>
            <span className={styles.semibold}>13.5. </span>
            Subject to the preamble of this Contract, this Contract constitutes
            the entire agreement between the Parties and supersedes and
            extinguishes all previous agreements, promises, assurances,
            warranties, representations, and understandings between them,
            whether written or oral, relating to its subject matter.
          </p>
        </div>
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default ServiceContract;
