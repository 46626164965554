import type { Dispatch, SetStateAction } from 'react';
import { SelectOption } from 'types/models';

export type SubmenuType = {
  key: string;
  label: string;
} | null;

export enum LogsFilterCategoryTypes {
  array = 'array',
  date_range = 'date-range',
}

export type LogsFilterType = {
  [key: string]: {
    label: string;
    key: string;
    type: LogsFilterCategoryTypes;
    getSubmenu?: ({
      setSearchParams,
      category,
      searchParams,
      setIsOptionsListVisible,
      translationPrefix,
    }: {
      setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
      category: string;
      searchParams: SelectOption[];
      setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
      translationPrefix?: string;
    }) => React.ReactElement;
    options?: string[];
    translationPrefix?: string;
  };
};
