import { useTranslation } from 'react-i18next';

import type { UseFormGetValues, UseFormWatch } from 'react-hook-form';
import {
  CheckoutOptions,
  PaymentMethodName,
  DepositFormValues,
  CreatePaymentPayload,
  PaymentOptions,
} from 'components/PaymentsComponents/models';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import DepositLinkModal from 'components/CreateCampaign/Step4/TotalCost/DepositLinkModal';

import useCreatePayment from 'hooks/api/useCreatePayment';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  disabled: boolean;
  campaignId: number | string;
  checkoutOptions: Partial<CheckoutOptions>;
  handleValidateCampaign: () => Promise<boolean>;
  getValues: UseFormGetValues<DepositFormValues>;
  formatPayload: (payload: DepositFormValues) => CreatePaymentPayload;
  watch: UseFormWatch<DepositFormValues>;
};

const ReceiveDepositLinkButton = ({
  disabled,
  campaignId,
  checkoutOptions,
  handleValidateCampaign,
  getValues,
  formatPayload,
  watch,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { mutate: createPayment, isLoading: isCreatePaymentLoading } =
    useCreatePayment();

  const handleReceiveDepositLink = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const isDeliveryValid = await handleValidateCampaign();
    if (!isDeliveryValid) return;

    const paymentValues = getValues();
    const payload = formatPayload({
      ...paymentValues,
      campaign_id: campaignId.toString(),
    });
    createPayment(
      {
        ...payload,
        isSkipBalanceModal: payload.terms_method !== PaymentMethodName.invoice,
        checkoutOptions,
      },
      {
        onError: err => {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        },
        onSuccess: response => {
          if (response.isSkipBalanceModal) {
            openModal({
              Content: <DepositLinkModal link={response.link} />,
            });
          }
        },
      }
    );
  };

  const isPayWithTermsSelected = watch('option') === PaymentOptions.later;

  return (
    <>
      <Button
        type="submit"
        className={styles.deposit}
        disabled={disabled}
        onClick={handleReceiveDepositLink}
        isBig
        iconProps={{
          name: isPayWithTermsSelected ? IconsNames.dollar : IconsNames.link,
        }}
      >
        {t(
          isPayWithTermsSelected
            ? 'common.button.deposit-now'
            : 'common.button.receive-deposit-link'
        )}
      </Button>
      {isCreatePaymentLoading && <LoaderScreen />}
    </>
  );
};

export default ReceiveDepositLinkButton;
