import { createContext, useState, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';

export type ModalState = {
  visible: boolean;
  Content: React.ReactNode | null | ((props: unknown) => JSX.Element);
};

export type NestedModalState = {
  NestedModalContent:
    | React.ReactNode
    | null
    | ((props: unknown) => JSX.Element);
};

const initialState = {
  visible: false,
  Content: null,
};
const nestedModalInitialState = {
  NestedModalContent: null,
};

export const ModalContext = createContext<{
  openModal: (data: Omit<ModalState, 'visible'>) => void;
  closeModal: (isNested?: unknown) => void;
  isModalVisible: boolean;
  openNestedModal: (data: NestedModalState) => void;
  isNestedModalVisible: boolean;
}>({
  openModal: () => {},
  closeModal: () => {},
  isModalVisible: false,
  openNestedModal: () => {},
  isNestedModalVisible: false,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>(initialState);
  const [nestedModalState, setNestedModalState] = useState<NestedModalState>(
    nestedModalInitialState
  );
  const modalContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { visible, Content } = modalState;
  const { NestedModalContent } = nestedModalState;

  const openModal = (data: Omit<ModalState, 'visible'>) => {
    setModalState({ ...data, visible: true });
  };
  const closeModal = (isNested?: unknown) => {
    if (isNested && typeof isNested === 'boolean') {
      return setNestedModalState(nestedModalInitialState);
    }
    setModalState(initialState);
    setNestedModalState(nestedModalInitialState);
  };

  const openNestedModal = (data: NestedModalState) => {
    if (visible) setNestedModalState(data);
  };

  const isNestedModalVisible = !!NestedModalContent;

  const value = {
    openModal,
    closeModal,
    isModalVisible: visible,
    openNestedModal,
    isNestedModalVisible,
  };

  const isModalVisible = visible && modalContainerRef;

  return (
    <ModalContext.Provider value={value}>
      <div ref={modalContainerRef}>
        {isNestedModalVisible && (
          <>
            {typeof NestedModalContent === 'function' ? (
              <NestedModalContent />
            ) : (
              <>{NestedModalContent}</>
            )}
          </>
        )}
      </div>
      {children}
      {isModalVisible &&
        ReactDOM.createPortal(
          typeof Content === 'function' ? <Content /> : <>{Content}</>,
          modalContainerRef.current
        )}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export default useModal;
