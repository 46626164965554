import cn from 'classnames';

import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import type {
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';
import type { InputData, SelectOption } from 'types/models';

import CountryField from 'components/common/AuthForm/CountryField';
import AddressField from 'components/common/AuthForm/AddressField';
import JobTitleAutocomplete from 'components/common/JobTitleAutocomplete';
import CountrySelectField from 'components/common/CountrySelectField';
import PhoneField from 'components/common/PhoneField';
import Error from 'components/common/Error';
import DatePicker from 'components/common/DatePicker';
import TimezoneSelectField from 'components/common/TimezoneFieldSelector';

import { authSelectStyles } from 'utils/selectStyles';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

const getNestedErrorValue = (object: FieldErrors, path: string) => {
  const pathArray = path.split('.');

  // @ts-ignore
  const result = pathArray.reduce((acc, val) => {
    if (acc && acc[val]) {
      return acc[val];
    } else {
      return undefined;
    }
  }, object);

  return result;
};

type InputFieldProps<FormValues extends FieldValues> = {
  data: InputData<FormValues>;
  register: UseFormRegister<FormValues>;
  disableChanges?: boolean;
  validationError: FieldErrors;
  formSettings: UseFormReturn<FormValues, any>;
  labelClasses?: string;
  isInvited?: boolean;
  disableAutocomplete?: boolean;
};

const InputField = <FormValues extends FieldValues>({
  data,
  register,
  disableChanges,
  validationError,
  formSettings,
  labelClasses,
  isInvited = false,
  disableAutocomplete,
}: InputFieldProps<FormValues>): JSX.Element => {
  const { t } = useTranslation();
  const {
    type,
    name,
    placeholder,
    required,
    label,
    fromMonth,
    toMonth,
    selectOptions,
  } = data;
  const translateOptions = useTranslateSelectOptions();
  const translatedOptions = translateOptions(selectOptions || []);
  const queryClient = useQueryClient();
  const invitedData = queryClient.getQueriesData('invitedUser');
  const { invitedUser } = Object.fromEntries(invitedData);

  const isNotVendorInvited =
    invitedUser?.current_group_name !== UserTypes.vendor;

  const isPhone = type === 'tel';
  const isLocation = type === 'location';
  const isCountry = type === 'location-country-only';
  const isSingleSelect = type === 'single-select';
  const isDateInput = type === 'date';
  const isAddressInput = type === 'address';
  const isJobTitle = type === 'job-title';
  const isEmail = type === 'email';
  const isCompanyName = name === 'company.name';
  const isTimezone = type === 'timezone';
  const isSimpleInput =
    !isPhone &&
    !isLocation &&
    !isSingleSelect &&
    !isDateInput &&
    !isAddressInput &&
    !isJobTitle &&
    !isCountry &&
    !isTimezone;

  const isInvitedEmail = isEmail && isInvited;
  const isInvitedCompany = isCompanyName && isInvited && isNotVendorInvited;

  const isDisableForInvited =
    disableChanges || isInvitedEmail || isInvitedCompany;

  const isNestedField = name.split('.').length > 1;
  const error = isNestedField
    ? getNestedErrorValue(validationError, name)
    : validationError[name];
  const arrayError = Array.isArray(error) && error[0];
  const yupErrorMessage =
    // @ts-ignore
    typeof error !== 'string' && (error?.message || error?.value?.message);
  const translationObjError =
    yupErrorMessage?.key && t(yupErrorMessage?.key, yupErrorMessage.values);
  const translationKeyError = t(yupErrorMessage);
  const errorMessage = arrayError || translationObjError || translationKeyError;
  const isStringLabel = typeof label === 'string';

  if (isLocation) {
    return (
      <CountryField
        formSettings={formSettings}
        name={name}
        label={isStringLabel ? label : undefined}
      />
    );
  }

  return (
    <>
      <label
        htmlFor={name}
        className={cn(styles.label, labelClasses, {
          [styles.label__checkbox]: type === 'checkbox',
        })}
      >
        {label && (
          <span className={styles.label__text}>
            {isStringLabel ? t(`common.field.${label}`) : label}
          </span>
        )}
        <Error message={errorMessage} className={styles.error} />

        {isPhone && <PhoneField control={formSettings.control} name={name} />}
        {isDateInput && (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value as string}
                onChange={onChange}
                fromMonth={fromMonth}
                toMonth={toMonth}
                inputClassName={styles.input}
              />
            )}
          />
        )}
        {isCountry && (
          <CountrySelectField formSettings={formSettings} name={name} />
        )}
        {isTimezone && (
          <TimezoneSelectField formSettings={formSettings} name={name} />
        )}
        {isSingleSelect && selectOptions && (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Select<SelectOption>
                value={value as SelectOption}
                onChange={onChange}
                options={translatedOptions}
                styles={authSelectStyles}
                placeholder=""
                onBlur={onBlur}
              />
            )}
          />
        )}
        {isJobTitle && (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field }) => (
              <JobTitleAutocomplete
                field={field}
                styles={authSelectStyles}
                placeholder=" "
              />
            )}
          />
        )}
        {isAddressInput && (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field }) => (
              <AddressField field={field} formSettings={formSettings} />
            )}
          />
        )}
        {isSimpleInput && (
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, { required })}
            disabled={isDisableForInvited}
            id={name}
            className={styles.input}
            autoComplete={disableAutocomplete ? 'new-password' : `${type}-form`}
            min={0}
          />
        )}
      </label>
    </>
  );
};

export default InputField;
