import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import type { FeatureResponse } from 'types/models';
import type { AxiosError } from 'axios';

import InputWrapper from 'components/common/InputWrapper';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import Button from 'components/common/Button';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import {
  validationSchemaCompanyFeatures,
  MAX_FEATURE_CHARACTERS_LENGTH,
} from 'components/Product/CreateProductContent/CompanyFeatures/FeatureForm/validationSchema';

import styles from '../index.module.scss';

type FormValues = {
  feature: string;
};

type Props = {
  companySlug?: string;
  productSlug: string | null;
  isBenefit?: boolean;
  productId?: number | undefined;
};

const FeatureForm = ({
  companySlug,
  productSlug,
  isBenefit = false,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    resetField,
    formState: { errors },
    trigger,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaCompanyFeatures),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { axios } = useAuth();
  const queryClient = useQueryClient();

  const {
    mutate: postFeature,
    isLoading: isLoadingPost,
    error,
  } = useMutation<FeatureResponse, AxiosError, Omit<FeatureResponse, 'id'>>(
    async feature => {
      try {
        const { data } = await axios.post<FeatureResponse>(
          `/companies/${companySlug}/products/${productSlug}/company-features/`,
          feature
        );
        return data;
      } catch (err) {
        throw err;
      }
    },

    {
      onSuccess: feature => {
        queryClient.setQueryData<FeatureResponse[]>(
          ['featuresList', companySlug, productId],
          oldData => [...(oldData || []), feature]
        );
        resetField('feature');
      },
    }
  );

  const handleSubmitFeature = async () => {
    const isValid = await trigger('feature');
    if (!isValid) return;

    postFeature({ name: getValues().feature, is_benefit: isBenefit });
  };

  const featureValue = watch('feature');

  return (
    <>
      {isLoadingPost && <LoaderScreen />}
      <form
        aria-label={`feature form ${isBenefit ? 'benefits' : 'disadvantages'}`}
      >
        <InputWrapper
          validationError={errors?.feature?.message}
          isErrorHidden={!errors?.feature?.message}
          isMediumInput
          label={
            isBenefit
              ? t('manage-products.benefits')
              : t('manage-products.disadvantages')
          }
          charactersLength={[
            featureValue?.length,
            MAX_FEATURE_CHARACTERS_LENGTH,
          ]}
        >
          <div className={styles.inputWrapper}>
            <input
              type="text"
              {...register('feature')}
              placeholder={
                isBenefit
                  ? t('manage-products.ex-benefits')
                  : t('manage-products.ex-disadvantages')
              }
              onKeyDown={async event => {
                if (event.code === 'Enter') {
                  event.preventDefault();
                  await handleSubmitFeature();
                }
              }}
            />
            {!errors?.feature?.message && featureValue && (
              <Button type="button" transparent onClick={handleSubmitFeature}>
                {t('common.button.add')}
              </Button>
            )}
          </div>
        </InputWrapper>
        {error && <Error message={getResponseError(error)} />}
      </form>
    </>
  );
};

export default FeatureForm;
