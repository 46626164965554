import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { PrivatePaths } from 'constants/routes';
import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  isPreviewMode: boolean;
  setIsPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<Props> = ({
  isFirstStepDone,
  isPreviewMode,
  setIsPreviewMode,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCancelAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(`/${PrivatePaths.PRODUCTS}`);
  };

  const handleChangeEditMode = () => {
    setIsPreviewMode(prev => {
      if (!prev) {
        document.body.classList.add(styles.hidden);
      } else {
        document.body.classList.remove(styles.hidden);
      }
      return !prev;
    });
  };

  return (
    <header className={styles.header}>
      <h1 className={styles.text}>
        {`${
          isPreviewMode ? t('common.button.view') : t('common.button.create')
        } ${t('manage-products.product-page')}`}
      </h1>
      <div className={styles.buttons}>
        {!isPreviewMode && (
          <>
            <Button
              type="button"
              className={styles.button}
              onClick={handleCancelAndSubmit}
              white
            >
              {t('common.button.cancel')}
            </Button>
            <Button
              type="button"
              className={cn(styles.button, styles.save)}
              disabled={!isFirstStepDone}
              onClick={handleCancelAndSubmit}
            >
              {t('common.button.save-changes')}
            </Button>
          </>
        )}

        <Button
          iconProps={{ name: isPreviewMode ? IconsNames.edit : IconsNames.eye }}
          className={cn({ [styles.view]: !isPreviewMode })}
          onClick={handleChangeEditMode}
          white={!isPreviewMode}
        >
          {t(
            isPreviewMode
              ? 'common.button.continue-editing'
              : 'common.button.view-as-customer'
          )}
        </Button>
      </div>
    </header>
  );
};

export default Header;
