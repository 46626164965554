import { PaymentMethodName } from './models';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebounce } from 'react-use';
import { useMutation } from 'react-query';

import {
  CreatePaymentPayload,
  PaymentOptions,
  DepositFormValues,
} from 'components/PaymentsComponents/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { validationSchemaDepositAmount } from 'utils/validations';

type ExpectedPriceResponse = { amount: string; processing_fee: string };

const useGetExpectedPrice = (amount?: string | number) => {
  const { axios } = useAuth();
  const formMethods = useForm<DepositFormValues>({
    resolver: yupResolver(validationSchemaDepositAmount),
    mode: 'onChange',
    defaultValues: {
      amount: Number(amount) || 0,
      option: PaymentOptions.now,
      term: null,
    },
  });

  const mutation = useMutation<
    ExpectedPriceResponse,
    AxiosError,
    CreatePaymentPayload
  >(async formValues => {
    try {
      const { data } = await axios.post<ExpectedPriceResponse>(
        '/payments/expected-price/',
        formValues
      );

      return data;
    } catch (err) {
      throw err;
    }
  });

  const term = formMethods.watch('term');
  const method = formMethods.watch('method');
  const amountValue = formMethods.watch('amount');
  const option = formMethods.watch('option');

  const formatPayload = (payload: DepositFormValues): CreatePaymentPayload => ({
    ...payload,
    discount: Number(payload.discount) > 0 ? payload.discount : undefined,
    term: payload.option === PaymentOptions.now ? null : payload.term?.value,
    method: payload.method?.value,
    terms_method:
      payload.option === PaymentOptions.now
        ? undefined
        : PaymentMethodName.invoice,
    line_items: payload.line_items || [
      { title: 'Deposit funds', quantity: 1, price: payload.amount },
    ],
  });

  useDebounce(
    () => {
      if (
        amountValue &&
        method &&
        ((option !== PaymentOptions.now && term) ||
          option === PaymentOptions.now)
      ) {
        mutation.mutate(formatPayload(formMethods.getValues()));
      }
    },
    350,
    [term, method, amountValue, option]
  );

  return { mutation, formatPayload, formMethods };
};

export default useGetExpectedPrice;
