import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import axios from 'axios';
import cn from 'classnames';

import {
  UnsubscribeFormValues,
  UnsubscribeOption,
  UnsubscribePayload,
  UnsubscribeStatusResponse,
} from 'components/UnsubscribeContent/models';
import type { AxiosError } from 'axios';

import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import ModalWindow from 'components/common/ModalWindow';

import Step1 from 'components/UnsubscribeContent/Step1';
import Step2 from 'components/UnsubscribeContent/Step2';
import SuccessScreen from 'components/UnsubscribeContent/SuccessScreen';

import useModal from 'contexts/ModalContext';

import { validationSchemaUnsubscribe } from 'components/UnsubscribeContent/validationSchema';

import getResponseError from 'helpers/getResponseError';

import { LocalStorageKeys } from 'constants/constants';

import { ReactComponent as LogoSVG } from 'assets/images/logo.svg';

import styles from './index.module.scss';

const UnsubscribeContent = () => {
  const { t } = useTranslation();
  const { unsubscribeId } = useParams();
  const { openModal } = useModal();

  const { data: unsubscribeStatus, isLoading: isGetStatusLoading } = useQuery<
    UnsubscribeStatusResponse,
    AxiosError
  >(
    ['unsubscribe', unsubscribeId],
    async () => {
      try {
        const { data } = await axios.get<UnsubscribeStatusResponse>(
          `${process.env.REACT_APP_BACKEND_URL}/unsubscribe/api/unsubscribe/${unsubscribeId}/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const {
    data: submitResponse,
    isSuccess,
    isLoading,
    mutate,
    error,
    reset: resetMutationState,
  } = useMutation<UnsubscribePayload, AxiosError, UnsubscribePayload>(
    async payload => {
      try {
        await axios.post<UnsubscribePayload>(
          `${process.env.REACT_APP_BACKEND_URL}/unsubscribe/api/unsubscribe/${unsubscribeId}/`,
          payload
        );
        localStorage.removeItem(
          `${LocalStorageKeys.UNSUBSCRIBE_ID}${unsubscribeId}`
        );

        return payload;
      } catch (err) {
        throw err;
      }
    }
  );

  const localStorageData = localStorage.getItem(
    `${LocalStorageKeys.UNSUBSCRIBE_ID}${unsubscribeId}`
  );
  const parsedLocalStorageData = localStorageData
    ? JSON.parse(localStorageData)
    : null;

  const formMethods = useForm<UnsubscribeFormValues>({
    resolver: yupResolver(validationSchemaUnsubscribe),
    mode: 'all',
    defaultValues: parsedLocalStorageData
      ? (parsedLocalStorageData as UnsubscribeFormValues)
      : {
          unsubscribe_option: {
            value: UnsubscribeOption.snooze_receiving_emails,
            label: t(
              `unsubscribe.options.${UnsubscribeOption.snooze_receiving_emails}`
            ),
          },
          step: 0,
          isCustomReason: false,
        },
  });

  const handleSubmit = (values: UnsubscribeFormValues) => {
    mutate({
      unsubscribe_option: values.unsubscribe_option.value,
      until_date: values.until_date?.customValue || values.until_date?.value,
      reason: values.reason,
    });
  };

  const step = useWatch({ control: formMethods.control, name: 'step' });
  const isUnsubscribed = unsubscribeStatus?.is_submitted || isSuccess;

  return (
    <>
      <header className={styles.header}>
        <LogoSVG className={styles.logo} />
      </header>
      <main className={cn(styles.main, { [styles.success]: isUnsubscribed })}>
        {!isGetStatusLoading && (
          <>
            {!isUnsubscribed ? (
              <FormProvider {...formMethods}>
                <form
                  onSubmit={formMethods.handleSubmit(handleSubmit)}
                  className={cn(styles.form, {
                    [styles.hasBackButton]: !!step,
                  })}
                  aria-label="unsubscribe form"
                >
                  {!step ? (
                    <Step1 />
                  ) : (
                    <Step2 resetMutationState={resetMutationState} />
                  )}
                </form>
                {error && <Error message={getResponseError(error)} />}
              </FormProvider>
            ) : (
              <SuccessScreen
                unsubscribeOption={submitResponse?.unsubscribe_option}
              />
            )}
          </>
        )}
      </main>
      <footer className={styles.footer}>
        <small className={styles.copyright}>
          BeeLeads {dayjs().get('year')}. {t('unsubscribe.copyright')}
        </small>
      </footer>
      {(isLoading || isGetStatusLoading) && <LoaderScreen />}
    </>
  );
};

export default UnsubscribeContent;
