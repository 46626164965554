import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import {
  ConnectionStatus,
  CRMDetailsType,
} from 'components/IntegrationsContent/models';

import PageContainer from 'components/common/PageContainer';
import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import LogsTable from 'components/IntegrationsContent/AdminContent/LogsTable';
import Status from 'components/IntegrationsContent/AdminContent/Status';

import { ReactComponent as HubspotSVG } from 'assets/images/integrations/hubspot.svg';
import { ReactComponent as MarketoSVG } from 'assets/images/integrations/marketo.svg';

import useAuth from 'contexts/AuthContext';

import { IconsNames, UserTypes, CRMPlatformNames } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const CRMPage = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();

  const navigate = useNavigate();
  const { connectionId } = useParams();

  const {
    data: CRMDetails,
    isLoading,
    error,
  } = useQuery<CRMDetailsType, AxiosError>(
    ['crm-details', user?.company.id, connectionId],
    async () => {
      try {
        const { data } = await axios.get(
          `/crm-integrations/api/${user?.company.id}/connections/${connectionId}/`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: user?.current_group_name === UserTypes.admin }
  );

  return (
    <PageContainer isWhiteBackground isWithoutPadding>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <Button
            white
            iconProps={{ name: IconsNames.arrow }}
            className={styles.button}
            onClick={() => navigate(`/${PrivatePaths.INTEGRATIONS}`)}
          />
          {isLoading && <Loader />}
          {CRMDetails && (
            <div className={styles.info}>
              <div className={styles.box}>
                <p className={styles.label}>{t('common.field.service')}</p>

                <div className={cn(styles.value, styles.title)}>
                  {CRMDetails.platform === CRMPlatformNames.marketo && (
                    <MarketoSVG className={styles.icon} />
                  )}
                  {CRMDetails.platform === 'HubSpot' && (
                    <HubspotSVG className={styles.icon} />
                  )}
                  <span className={styles.value}>{CRMDetails.platform}</span>
                </div>
              </div>

              <div className={styles.box}>
                <p className={styles.label}>
                  {t('common.field.connection-type')}
                </p>
                <p className={styles.value}>
                  {t(
                    `integrations.connection-type.${CRMDetails.connection_type}`
                  )}
                </p>
              </div>
              <div className={styles.box}>
                <p className={styles.label}>{t('common.field.status')}</p>

                <Status
                  isFailed={CRMDetails.status === ConnectionStatus.failed}
                  isEnabled={CRMDetails.status === ConnectionStatus.enabled}
                />
              </div>
            </div>
          )}
          {error && <Error message={getResponseError(error)} />}
        </div>
        <LogsTable />
      </div>
    </PageContainer>
  );
};

export default CRMPage;
