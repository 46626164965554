import type { TFunction } from 'i18next';

import { FILE_NAME_MAX_LENGTH } from 'constants/constants';

export const createDownloadFileLink = (href: string, name: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
    link?.parentNode?.removeChild(link);
  }, 2000);
};

export const downloadFile = (
  data: string,
  typeOfFile: string,
  nameOfFile: string
) => {
  const fileName = nameOfFile.includes('filename=')
    ? nameOfFile.split('filename=')[1]
    : nameOfFile;

  const blob = new Blob([data], { type: typeOfFile });
  createDownloadFileLink(window.URL.createObjectURL(blob), fileName);
};

export const createImageRenderLink = (
  image: File,
  uploadImage: (imageSrc: string) => void
) => {
  const reader = new FileReader();
  reader.onload = (event: ProgressEvent<FileReader>) =>
    uploadImage(event.target?.result as string);
  reader.readAsDataURL(image);
};

export const validateUploadedFiles = ({
  files,
  maxSize,
  t,
}: {
  files: File | File[];
  maxSize?: number;
  t: TFunction;
}) => {
  const filesArray = Array.isArray(files) ? files : [files];

  if (filesArray.some(file => file.name.length > FILE_NAME_MAX_LENGTH)) {
    return t('common.error.file-name-length', {
      length: FILE_NAME_MAX_LENGTH,
    });
  }

  if (maxSize && filesArray.some(file => file.size > maxSize)) {
    return `${t('common.error.max-file-size', {
      size: Math.round(maxSize / 1024 ** 2),
    })}${t('common.field.mb')}`;
  }
};
