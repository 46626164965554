import { useFormContext, useWatch, useController } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';
import {
  ProductPricingPlanPeriods,
  ProductPricingPlanResponse,
} from 'components/Product/models';

import type { AxiosResponse } from 'axios';

import InputWrapper from 'components/common/InputWrapper';
import ToggleSwitch from 'components/common/ToggleSwitch';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import styles from '../index.module.scss';

type Props = {
  index: number;
  name: string;
  submitErrors?: AxiosResponse<ProductPricingPlanResponse>;
};

const PriceDetails = ({ index, name, submitErrors }: Props) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    control,
    clearErrors,
    setValue,
    trigger,
    getValues,
  } = useFormContext<ProductPricingPlanFormValues>();
  const tooltipOptions = usePopperTooltip({
    placement: 'left-end',
    offset: [0, 15],
  });
  const { field: isFreeField } = useController({
    control,
    name: `period_details.${index}.isFree`,
  });

  const period = useWatch({
    control,
    name: 'period',
  });
  const submitErrorsPeriodDetails = submitErrors?.data.period_details?.[index];
  const validationErrorsPeriodDetails = errors.period_details?.[index];

  return (
    <>
      {period?.value === ProductPricingPlanPeriods.custom && (
        <InputWrapper
          label="common.field.name"
          isMediumInput
          validationError={validationErrorsPeriodDetails?.name}
          submitError={submitErrorsPeriodDetails?.name?.[0]}
        >
          <input type="text" {...register(`period_details.${index}.name`)} />
        </InputWrapper>
      )}

      {period.value === ProductPricingPlanPeriods['monthly-or-annually'] && (
        <div ref={tooltipOptions.setTriggerRef}>
          <InputWrapper
            label={`common.field.${name}`}
            wrapperClasses={cn(styles.free, styles.checkbox)}
            isErrorHidden
          >
            <input
              type="checkbox"
              {...register(`period_details.${index}.isChecked`)}
              className={styles.checkbox}
              onChange={e => {
                if (validationErrorsPeriodDetails?.isChecked?.message) {
                  getValues().period_details?.forEach((_, detailIndex) =>
                    trigger(`period_details.${detailIndex}.isChecked`)
                  );
                }

                register(`period_details.${index}.isChecked`).onChange(e);
              }}
            />
          </InputWrapper>
        </div>
      )}

      {validationErrorsPeriodDetails?.isChecked?.message && (
        <SimpleTooltip options={tooltipOptions}>
          {validationErrorsPeriodDetails?.isChecked?.message}
        </SimpleTooltip>
      )}

      <div className={styles.price}>
        <InputWrapper
          label="common.field.price"
          isMediumInput
          validationError={validationErrorsPeriodDetails?.price?.message}
          submitError={submitErrorsPeriodDetails?.price?.[0]}
        >
          <input
            placeholder="$0.00"
            {...register(`period_details.${index}.price`)}
            type="number"
            step="0.01"
            min="0"
            lang="en"
            disabled={isFreeField.value}
          />
        </InputWrapper>
        <InputWrapper
          label="auth.free"
          wrapperClasses={styles.free}
          isErrorHidden
        >
          <ToggleSwitch
            id={`price ${index}`}
            checked={isFreeField.value}
            onChange={isChecked => {
              if (isChecked) {
                clearErrors(`period_details.${index}.price`);
                setValue(`period_details.${index}.price`, '0');
              }
              isFreeField.onChange(isChecked);
            }}
          />
        </InputWrapper>
      </div>

      <InputWrapper
        label="manage-products.price-details-link"
        isMediumInput
        tip={t('manage-products.price-details-link-tip')}
        validationError={validationErrorsPeriodDetails?.link}
        submitError={submitErrorsPeriodDetails?.link?.[0]}
      >
        <input
          type="text"
          {...register(`period_details.${index}.link`)}
          placeholder={t('common.field.enter-link')}
        />
      </InputWrapper>
      <InputWrapper
        label="manage-products.link-title"
        isMediumInput
        tip={t('manage-products.link-title-tip')}
        tipClassName={styles.tip}
        submitError={submitErrorsPeriodDetails?.link_title?.[0]}
      >
        <input
          type="text"
          placeholder={t('manage-products.our-product-detailed-description')}
          {...register(`period_details.${index}.link_title`)}
        />
      </InputWrapper>
    </>
  );
};

export default PriceDetails;
