import { isMobile } from 'react-device-detect';

import { ProductPricingPlanResponse } from 'components/Product/models';

import PlanDetailsModal from 'components/Product/ProductPage/PricingPlans/PlanDetailsModal';
import PlanInfo from 'components/Product/ProductPage/PricingPlans/PlanInfo';
import PlanImagesCarousel from 'components/Product/ProductPage/PricingPlans/PlanImagesCarousel';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

type Props = {
  planData: ProductPricingPlanResponse;
};

const PricingPlan: React.FC<Props> = ({ planData }) => {
  const { openModal } = useModal();

  const handleOpenPricingDetailsModal = () => {
    openModal({ Content: <PlanDetailsModal plan={planData} /> });
  };

  const { images } = planData;

  return (
    <li
      className={styles.pricingCard}
      onClick={!isMobile ? handleOpenPricingDetailsModal : undefined}
    >
      {!!images.length && (
        <PlanImagesCarousel
          images={images}
          navigationButtonsClassName={styles.navigation}
        />
      )}

      <PlanInfo planData={planData} />
    </li>
  );
};

export default PricingPlan;
