import { useTranslation } from 'react-i18next';

import type { ShareContactData } from 'types/models';

import Button from 'components/common/Button';

import MarketoForm from 'components/Product/ProductPage/TopInfo/MarketoForm';

import useModal from 'contexts/ModalContext';

import defaultLogo from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  name?: string;
  productPhoto?: string;
  isGetADemoAvailable: boolean;
  onOpenGetDemo: () => void;
  buttonName?: string;
  productId?: number;
  companySlug?: string;
  shareContactData?: ShareContactData;
};

const PROD_BAMBOOHR_PRODUCT_ID = 82;
const PROD_BAMBOOHR_COMPANY_SLUG = 'bamboohr';
const DEV_TEST_COMPANY_SLUG = '1-company';
const DEV_TEST_PRODUCT_ID = 33;

const TopInfo: React.FC<Props> = ({
  name,
  productPhoto,
  buttonName,
  isGetADemoAvailable,
  onOpenGetDemo,
  productId,
  companySlug,
  shareContactData,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const handleOpenGetDemo = () => {
    const isMarketoForm =
      (productId === PROD_BAMBOOHR_PRODUCT_ID &&
        companySlug === PROD_BAMBOOHR_COMPANY_SLUG) ||
      (productId === DEV_TEST_PRODUCT_ID &&
        companySlug === DEV_TEST_COMPANY_SLUG);

    if (isMarketoForm) {
      return openModal({
        Content: <MarketoForm shareContactData={shareContactData} />,
      });
    }

    onOpenGetDemo();
  };

  return (
    <section className={styles.topInfo}>
      <img
        src={productPhoto || defaultLogo}
        alt={name}
        className={styles.productLogo}
      />
      <h1 className={styles.mobile}>{name}</h1>
      {isGetADemoAvailable && (
        <Button className={styles.demo} onClick={handleOpenGetDemo}>
          {buttonName ? buttonName : t('common.button.get-demo')}
        </Button>
      )}
    </section>
  );
};

export default TopInfo;
