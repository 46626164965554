import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch, useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { SingleValue } from 'react-select';
import {
  UnsubscribeFormValues,
  UnsubscribeOption,
  UnsubscribeSelectOption,
} from 'components/UnsubscribeContent/models';

import InputWrapper from 'components/common/InputWrapper';
import CheckmarkOption from 'components/UI/ReactSelect/CheckmarkOption';
import Button from 'components/common/Button';

import SelectSnoozeDays from 'components/UnsubscribeContent/Step1/SelectSnoozeDays';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { LocalStorageKeys } from 'constants/constants';

import styles from './index.module.scss';

const Step1 = () => {
  const { unsubscribeId } = useParams();
  const { t } = useTranslation();
  const translateOptions = useTranslateSelectOptions();
  const {
    control,
    setValue,
    formState: { isValid },
    reset,
    getValues,
  } = useFormContext<UnsubscribeFormValues>();
  const { field: unsubscribeOptionField } = useController({
    name: 'unsubscribe_option',
    control,
  });

  const handleChangeOption = (
    newValue: SingleValue<UnsubscribeSelectOption>
  ) => {
    if (newValue)
      reset({
        ...getValues(),
        reason: undefined,
        isCustomReason: false,
        unsubscribe_option: newValue,
        until_date: undefined,
      });
  };

  const handleGoToNextStep = () => {
    setValue('step', 1);
    localStorage.setItem(
      `${LocalStorageKeys.UNSUBSCRIBE_ID}${unsubscribeId}`,
      JSON.stringify(getValues())
    );
  };

  const unsubscribeSelectOptions = translateOptions(
    Object.keys(UnsubscribeOption) as UnsubscribeOption[],
    'unsubscribe.options.'
  );
  const isSnoozeOptionSelected =
    useWatch({
      control,
      name: 'unsubscribe_option',
    })?.value === UnsubscribeOption.snooze_receiving_emails;

  return (
    <>
      <h2 className={styles.title}>{t('unsubscribe.unsubscribe-or-snooze')}</h2>
      <InputWrapper
        label="common.field.option"
        wrapperClasses={styles.option}
        isErrorHidden
      >
        <Select<UnsubscribeSelectOption>
          styles={getDefaultSelectStylesWithError({ isSmall: true })}
          options={unsubscribeSelectOptions}
          defaultValue={unsubscribeSelectOptions[0]}
          components={{ Option: CheckmarkOption }}
          isSearchable={false}
          {...unsubscribeOptionField}
          onChange={handleChangeOption}
        />
      </InputWrapper>
      {isSnoozeOptionSelected && <SelectSnoozeDays />}
      <Button
        type="button"
        onClick={handleGoToNextStep}
        className={styles.button}
        disabled={!isValid}
      >
        {t('common.button.next')}
      </Button>
    </>
  );
};

export default Step1;
