import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch, useController } from 'react-hook-form';
import dayjs from 'dayjs';

import type { UnsubscribeFormValues } from 'components/UnsubscribeContent/models';
import type { TFunction } from 'react-i18next';

import InputWrapper from 'components/common/InputWrapper';
import CheckmarkOption from 'components/UI/ReactSelect/CheckmarkOption';
import DatePicker from 'components/UI/DatePicker';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';
import { CustomDefaultValue } from 'constants/constants';

import styles from './index.module.scss';

const MAX_POSSIBLE_SNOOZE_DAYS_COUNT = 180;

const termOptions = [
  { daysCount: 1, labelTranslationPath: 'common.field.day' },
  { daysCount: 7, labelTranslationPath: 'common.field.days' },
  { daysCount: 30, labelTranslationPath: 'common.field.days' },
  { daysCount: 60, labelTranslationPath: 'common.field.days' },
  { daysCount: 90, labelTranslationPath: 'common.field.months-days' },
  { daysCount: 180, labelTranslationPath: 'common.field.months' },
  { daysCount: null, labelTranslationPath: 'common.field.custom' },
];

const createSnoozeTermOption = ({
  daysCount,
  t,
  labelTranslationPath,
}: {
  daysCount: number | null;
  t: TFunction;
  labelTranslationPath: string;
}) => ({
  value: daysCount
    ? dayjs().add(daysCount, 'day').format('YYYY-MM-DD')
    : CustomDefaultValue.custom,
  label: t(labelTranslationPath, {
    days: daysCount,
    months: (daysCount || 0) / 30,
  }),
  ...(daysCount ? {} : { customValue: '' }),
});

const SelectSnoozeDays = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<UnsubscribeFormValues>();

  const snoozeTermOptions = termOptions.map(item =>
    createSnoozeTermOption({ ...item, t })
  );

  const { field: unsubscribeOptionField } = useController({
    name: 'until_date',
    control,
    defaultValue: snoozeTermOptions[0],
  });

  const handleChangeDate = (value: Date | null) => {
    const dayjsValue = dayjs(value);
    setValue(
      'until_date',
      {
        value: CustomDefaultValue.custom,
        label: `${t('common.field.custom')} (${t(
          'common.field.until'
        )} ${dayjsValue.format('D MMMM, YYYY')})`,
        customValue: value ? dayjsValue.format('YYYY-MM-DD') : '',
      },
      { shouldValidate: true }
    );
  };

  const selectedOption = useWatch({ control, name: 'until_date' });
  const isCustomSelected = selectedOption?.value === CustomDefaultValue.custom;
  const minDate = dayjs().add(1, 'days').toDate();
  const maxDate = dayjs().add(MAX_POSSIBLE_SNOOZE_DAYS_COUNT, 'days').toDate();

  return (
    <>
      <InputWrapper label="common.field.snooze-for">
        <Select
          {...unsubscribeOptionField}
          styles={getDefaultSelectStylesWithError({ isSmall: true })}
          options={snoozeTermOptions}
          defaultValue={snoozeTermOptions[0]}
          components={{ Option: CheckmarkOption }}
          isSearchable={false}
          className={styles.selector}
        />
        {isCustomSelected && (
          <div className={styles.picker}>
            <DatePicker
              inline
              onChange={handleChangeDate}
              minDate={minDate}
              maxDate={maxDate}
              selected={
                selectedOption.customValue
                  ? dayjs(selectedOption.customValue).toDate()
                  : null
              }
            />
          </div>
        )}
      </InputWrapper>
    </>
  );
};

export default SelectSnoozeDays;
