import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button';
import Status from 'components/IntegrationsContent/AdminContent/Status';

import { ConnectionStatus } from 'components/IntegrationsContent/models';

import {
  IconsNames,
  ConnectionType,
  CRMPlatformNames,
} from 'constants/constants';

import { ReactComponent as HubspotSVG } from 'assets/images/integrations/hubspot.svg';
import { ReactComponent as MarketoSVG } from 'assets/images/integrations/marketo.svg';

import styles from './index.module.scss';

type Props = {
  title: string;
  type: ConnectionType;
  status: ConnectionStatus;
  id: string;
};

const Card = ({ title, type, status, id }: Props) => {
  const { t } = useTranslation();

  const isEnabled = status === ConnectionStatus.enabled;
  const isFailed = status === ConnectionStatus.failed;

  return (
    <Link to={`${id}`} className={styles.link}>
      <div className={styles.card}>
        <div className={styles.title}>
          {/* WILL BE IMPLEMENTED ON SERVER SIDE */}
          {title === CRMPlatformNames.marketo && (
            <MarketoSVG className={styles.icon} />
          )}
          {title === CRMPlatformNames.hubspot && (
            <HubspotSVG className={styles.icon} />
          )}
          <span>{title}</span>
        </div>
        <div className={styles.box}>
          <p className={styles.label}>{t('common.field.connection-type')}</p>
          <p className={styles.value}>
            {t(`integrations.connection-type.${type}`)}
          </p>
        </div>

        <div className={styles.bottom}>
          <div className={styles.box}>
            <p className={styles.label}>{t('common.field.status')}</p>
            <Status isFailed={isFailed} isEnabled={isEnabled} />
          </div>
          <Button
            white
            iconProps={{ name: IconsNames.arrow }}
            className={styles.button}
          />
        </div>
      </div>
    </Link>
  );
};
export default Card;
