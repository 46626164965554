import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { AxiosError } from 'axios';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { CampaignCreatingStep } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  disabled: boolean;
  handleValidateCampaign: () => Promise<boolean>;
  setValueToCampaignForm: UseFormSetValue<CreateCampaignValues>;
  campaignId: number | string;
  handleGoToHomePage: () => Promise<void>;
};

const SubmitCampaignButton = ({
  disabled,
  campaignId,
  handleValidateCampaign,
  setValueToCampaignForm,
  handleGoToHomePage,
}: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal } = useModal();

  const { mutateAsync: submitCampaign, isLoading: isSubmitLoading } =
    useMutation<void, AxiosError>(
      async () => {
        try {
          await axios.post(`/campaigns/${campaignId}/submit/`);
        } catch (err) {
          throw err;
        }
      },
      {
        onSuccess: handleGoToHomePage,
        onError: err => {
          setValueToCampaignForm(
            'campaign_details.creating_step',
            CampaignCreatingStep['fourth-step']
          );
          if (err.response?.status === 402) {
            openModal({
              Content: <NoEnoughCreditsModal error={err} />,
            });

            return;
          }
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        },
      }
    );

  const handleSubmit = async () => {
    const isDeliveryValid = await handleValidateCampaign();
    if (!isDeliveryValid) return;
    setValueToCampaignForm(
      'campaign_details.creating_step',
      CampaignCreatingStep.completed
    );
    submitCampaign();
  };

  return (
    <>
      <Button
        className={styles.submit}
        onClick={handleSubmit}
        disabled={disabled}
        isBig
      >
        {t('common.button.submit')}
      </Button>
      {isSubmitLoading && <LoaderScreen />}
    </>
  );
};

export default SubmitCampaignButton;
