import { useFormContext, useController, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import type { AssetValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import SelectMethod from 'components/common/CreateAssetModal/Step1/SelectMethod';
import PreviewButton from 'components/Product/CreateProductContent/PreviewButton';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';
import useFetchProductsOptions from 'hooks/api/useFetchProductsOptions';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { AssetTypes, AssetContentType } from 'constants/assets';
import { assetTypes } from 'constants/selectOptions';

import styles from './index.module.scss';

type Props = {
  isSelectContentTypeAvailable?: boolean;
  isInCampaignUse?: boolean;
  isSelectProductPage?: boolean;
};

const Step1 = ({
  isSelectContentTypeAvailable,
  isInCampaignUse,
  isSelectProductPage,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
  } = useFormContext<AssetValues>();

  const { data: productsOptions, isLoading: isLoadingProducts } =
    useFetchProductsOptions(isSelectProductPage);
  const buttonName = useWatch({ name: 'attached_file_link_name', control });
  const translateOptions = useTranslateSelectOptions();
  const assetTypeOptions = translateOptions<AssetTypes>(assetTypes);
  const assetContentTypeOptions = translateOptions<AssetContentType>(
    Object.values(AssetContentType) as AssetContentType[]
  );

  const registerDescriptionMethods = register('description');

  const { field } = useController({
    control,
    name: 'type',
  });
  const { field: contentTypeField } = useController({
    control,
    name: 'content_type',
  });
  const { field: productField } = useController({
    control,
    name: 'product',
  });

  return (
    <div className={styles.fields}>
      {isSelectContentTypeAvailable && (
        <InputWrapper
          label="common.field.asset-type"
          validationError={errors?.content_type?.message}
        >
          <Select
            options={assetContentTypeOptions}
            {...contentTypeField}
            onChange={value => {
              reset({
                ...getValues(),
                attached_file_link: '',
                attached_file: undefined,
                content_type: value || undefined,
              });
              clearErrors(['attached_file', 'attached_file_link']);
            }}
            styles={getDefaultSelectStylesWithError({
              isSmall: true,
              error: !!errors?.content_type?.message,
            })}
            placeholder={t('common.field.select-asset-type')}
            isSearchable={false}
            menuPosition="fixed"
          />
        </InputWrapper>
      )}
      <InputWrapper
        label="common.field.title"
        isMediumInput
        validationError={errors?.title?.message}
        tip={
          isInCampaignUse
            ? t('manage-products.asset-in-use', {
                instance: t('common.field.title').toLowerCase(),
              })
            : undefined
        }
      >
        <input
          type="text"
          {...register('title')}
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.title'
          ).toLowerCase()}`}
          disabled={isInCampaignUse}
        />
      </InputWrapper>

      <SelectMethod />

      <InputWrapper
        label="common.field.description"
        validationError={errors?.description?.message}
      >
        <TextareaAutoHeight
          methods={registerDescriptionMethods}
          control={control}
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.description'
          ).toLowerCase()}`}
        />
      </InputWrapper>
      <InputWrapper
        label="common.field.type"
        validationError={errors?.type?.message}
      >
        <Select
          options={assetTypeOptions}
          {...field}
          styles={getDefaultSelectStylesWithError({
            isSmall: true,
            error: !!errors?.type?.message,
          })}
          placeholder={`${t('common.field.select')} ${t(
            'common.field.type'
          ).toLowerCase()}`}
          noOptionsMessage={() => t('common.field.no-options')}
          menuPlacement="auto"
          isSearchable={false}
          menuPosition="fixed"
        />
      </InputWrapper>
      {isSelectProductPage && (
        <InputWrapper
          label="common.field.product-page"
          validationError={errors?.product?.message}
          tip={
            isInCampaignUse
              ? t('manage-products.asset-in-use', {
                  instance: t('common.field.product-page').toLowerCase(),
                })
              : undefined
          }
        >
          <Select
            options={productsOptions}
            {...productField}
            styles={getDefaultSelectStylesWithError({
              isSmall: true,
              error: !!errors?.product?.message,
            })}
            placeholder={`${t('common.field.select')} ${t(
              'common.field.product-page'
            ).toLowerCase()}`}
            noOptionsMessage={() => t('common.field.no-options')}
            menuPlacement="auto"
            isSearchable={false}
            menuPosition="fixed"
            isLoading={isLoadingProducts}
            isDisabled={isInCampaignUse}
          />
        </InputWrapper>
      )}
      <div className={styles.button}>
        <InputWrapper
          label={t('common.field.button-name')}
          isMediumInput
          validationError={errors?.attached_file_link_name?.message}
        >
          <input
            type="text"
            {...register('attached_file_link_name')}
            maxLength={31}
          />
        </InputWrapper>
        <PreviewButton className={styles.preview}>
          {buttonName || t('common.button.download')}
        </PreviewButton>
      </div>
    </div>
  );
};

export default Step1;
