import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import type {
  StylesConfig,
  InputProps,
  InputActionMeta,
  MenuPosition,
} from 'react-select';
import type { SelectOption } from 'types/models';

import Option from 'components/UI/ReactSelect/Option';
import Menu from 'components/UI/ReactSelect/SelectAllMenu';

import { selectAllOptionValue } from 'constants/constants';

type Props = {
  field: any;
  isMulti?: boolean;
  styles?: StylesConfig;
  placeholder?: string;
  menuPosition?: MenuPosition;
};

const Input = (props: InputProps) => {
  return (
    <components.Input
      {...props}
      // Chrome ignores autocomplete="off"
      autoComplete={`password-${props.selectProps.name}`}
      id={`password-${props.selectProps.name}`}
    />
  );
};

const JobTitleAutocomplete = ({
  field,
  isMulti = false,
  styles,
  placeholder,
  menuPosition,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), 250, [searchValue]);
  const { t } = useTranslation();

  const { data: options, isLoading } = useQuery(
    ['job-titles', debouncedValue],
    async () => {
      try {
        const { data } = await axios.get<{
          results: { name: string }[];
        }>(
          `${process.env.REACT_APP_BACKEND_URL}/autocomplete/job-titles/?search=${debouncedValue}`
        );

        const result = data.results.length
          ? data.results.map(value => ({
              value: value.name,
              label: value.name,
            }))
          : [];

        return result;
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const isAllSelected =
    isMulti &&
    field.value?.length &&
    field.value[0].value === selectAllOptionValue;
  const selectOptions = options || [];

  const handleSelectAll = () => {
    field.onChange([
      {
        label: t('common.field.all'),
        value: selectAllOptionValue,
      },
    ]);
  };

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setSearchValue(value);
    }
  };

  const CustomMenu = useCallback(
    ({ ...menuProps }) => (
      <Menu
        {...menuProps}
        selectAll={handleSelectAll}
        isSelectAllVisible={!searchValue}
      />
    ),
    [searchValue]
  );
  const customComponents = {
    Input,
    ...(isMulti
      ? {
          Option,
          Menu: CustomMenu,
        }
      : {}),
  };

  return (
    <CreatableSelect<SelectOption, typeof isMulti>
      isMulti={isMulti}
      loadingMessage={() => t('common.field.loading')}
      formatCreateLabel={value => `${t('common.button.create')} "${value}"`}
      isLoading={isLoading}
      styles={styles}
      options={isAllSelected ? [] : selectOptions}
      onInputChange={handleInputChange}
      inputValue={isMulti ? searchValue : undefined}
      {...field}
      onChange={e => {
        field.onChange(e);
        if (!isMulti) {
          setSearchValue('');
        }
      }}
      placeholder={placeholder || t('common.field.start-typing')}
      noOptionsMessage={() =>
        !isAllSelected ? t('common.field.no-options') : ''
      }
      hideSelectedOptions={!isMulti}
      closeMenuOnSelect={!isMulti}
      components={customComponents}
      menuPosition={menuPosition}
    />
  );
};

export default JobTitleAutocomplete;
