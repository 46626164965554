import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { ProductFormValues } from 'components/Product/CreateProductContent/models';
import type { AxiosError } from 'axios';

import UploadFile from 'components/common/UploadFile';
import InputWrapper from 'components/common/InputWrapper';
import CropImageModal from 'components/common/CropImageModal';

import useModal from 'contexts/ModalContext';

import { createImageRenderLink } from 'helpers/files';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  error?: AxiosError | null;
};

const MAX_FILES_SIZE = 3145728; // 3mb
const LOGO_SIZE = 144;
const BACKGROUND_WIDTH = 1280;
const BACKGROUND_HEIGHT = 300;

const TopInfo: React.FC<Props> = ({ isFirstStepDone, error }) => {
  const { t } = useTranslation();
  const { register, control, formState, getValues, setValue } =
    useFormContext<ProductFormValues>();
  const { openModal } = useModal();

  const {
    field: { value: BGImage, onChange: onChangeBGImage },
  } = useController({ control, name: 'background_image' });

  const openBackgroundCropModal = (
    imageSrc: string,
    file: File | null | string
  ) => {
    openModal({
      Content: (
        <CropImageModal
          value={imageSrc}
          title={t('manage-products.cover-image')}
          imageHeight={BACKGROUND_HEIGHT}
          imageWidth={BACKGROUND_WIDTH}
          onDeleteImage={() => {
            onChangeBGImage(null);
            setValue('background_image_original', null);
          }}
          objectFit="horizontal-cover"
          instanceName={t('manage-products.cover-image')}
          itemName={t('manage-products.cover-image')}
          uploadImage={imageData => {
            onChangeBGImage(imageData);
            if (imageData.originalFile) {
              setValue('background_image_original', imageData.originalFile);
            } else {
              setValue('background_image_original', file);
            }
          }}
        />
      ),
    });
  };

  const insertBgPhoto = (
    file: File | null,
    renderLink: string | ArrayBuffer | null | undefined
  ) => {
    if (renderLink) {
      openBackgroundCropModal(renderLink as string, file);
    }
  };

  const {
    field: { value: logotype, onChange: onChangeLogotype },
  } = useController({ control, name: 'product_photo' });

  const openLogoCropModal = (imageSrc: string, file: File | null | string) => {
    openModal({
      Content: (
        <CropImageModal
          title={t('manage-products.logo')}
          imageHeight={LOGO_SIZE}
          imageWidth={LOGO_SIZE}
          uploadImage={imageData => {
            onChangeLogotype(imageData);
            if (imageData.originalFile) {
              setValue('product_photo_original', imageData.originalFile);
            } else {
              setValue('product_photo_original', file);
            }
          }}
          onDeleteImage={() => {
            onChangeLogotype(null);
            setValue('product_photo_original', null);
          }}
          cropSize={{ width: LOGO_SIZE, height: LOGO_SIZE }}
          instanceName={t('manage-products.logo')}
          itemName={t('manage-products.logo')}
          value={imageSrc}
        />
      ),
    });
  };

  const insertLogotype = (
    file: File | null,
    renderLink?: string | ArrayBuffer | null
  ) => {
    openLogoCropModal(renderLink as string, file);
  };

  const handleEditLogoImage = (originalImage?: string | File | null) => {
    if (!originalImage) return;

    if (typeof originalImage !== 'string') {
      createImageRenderLink(originalImage, src =>
        openLogoCropModal(src, originalImage)
      );
    } else {
      openLogoCropModal(originalImage, originalImage);
    }
  };

  const handleEditBackgroundImage = (originalImage?: string | File | null) => {
    if (!originalImage) return;

    if (typeof originalImage !== 'string') {
      createImageRenderLink(originalImage, src =>
        openBackgroundCropModal(src, originalImage)
      );
    } else {
      openBackgroundCropModal(originalImage, originalImage);
    }
  };

  return (
    <section
      className={cn(styles.topInfo, { [styles.disabled]: isFirstStepDone })}
    >
      <UploadFile
        accept={['image/jpeg', 'image/png']}
        imageSrc={typeof BGImage === 'string' ? BGImage : BGImage?.renderLink}
        inputClasses={styles.bgInput}
        maxSize={MAX_FILES_SIZE}
        secondaryTitle={t('manage-products.cover-image')}
        sign={t('common.field.optimal-dimensions', {
          dimensions: `${BACKGROUND_WIDTH} x ${BACKGROUND_HEIGHT} px`,
        })}
        secondarySign={`${t('common.field.formats', {
          formats: 'PNG, JPG',
        })}. ${t('common.field.max-size')}: 3Mb`}
        onChangeOptions={{
          maxFiles: 1,
          insertSingle: insertBgPhoto,
          withPreview: true,
        }}
        backgroundImageClassName={styles.preview}
        editButtonClassName={styles.bgImageEdit}
        onEdit={() => {
          handleEditBackgroundImage(getValues('background_image_original'));
        }}
        errorFilledImageClassName={styles.coverImageError}
      />

      <div className={styles.container}>
        <UploadFile
          tip={`${t('common.field.optimal-dimensions', {
            dimensions: `${LOGO_SIZE} x ${LOGO_SIZE} px`,
          })} ${t('common.field.formats', { formats: 'PNG, JPG' })}. ${t(
            'common.error.max'
          )} 3 MB.`}
          accept={['image/jpeg', 'image/png']}
          wrapperClasses={styles.logoUpload}
          secondaryTitle={t('manage-products.logo')}
          maxSize={MAX_FILES_SIZE}
          errorFilledImageClassName={styles.error}
          imageSrc={
            typeof logotype === 'string' ? logotype : logotype?.renderLink
          }
          onChangeOptions={{
            maxFiles: 1,
            insertSingle: insertLogotype,
            withPreview: true,
          }}
          backgroundImageClassName={styles.preview}
          tooltipClassName={styles.hint}
          onEdit={() => {
            handleEditLogoImage(getValues('product_photo_original'));
          }}
          errorEmptyImageClassName={styles.errorEmpty}
        />

        <InputWrapper
          wrapperClasses={styles.input}
          validationError={formState.errors?.name?.message}
          submitError={error?.response?.data?.name?.[0]}
          label="manage-products.product-name"
          isMediumInput
        >
          <input
            type="text"
            placeholder={t('manage-products.title-placeholder')}
            {...register('name')}
            aria-label="product name"
          />
        </InputWrapper>
      </div>
    </section>
  );
};

export default TopInfo;
