import * as Yup from 'yup';

import {
  REQUIRED_MESSAGE,
  MAX_LENGTH_MESSAGE,
  INVALID_VALUE,
} from 'utils/validations';

export const validationSchemaGetADemo = (isRecaptchaEnabled: boolean) =>
  Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required(REQUIRED_MESSAGE)
        .max(20, { key: MAX_LENGTH_MESSAGE, values: { length: 20 } })
        .onlyLettersSpacesNumbers(),
      last_name: Yup.string()
        .required(REQUIRED_MESSAGE)
        .max(20, { key: MAX_LENGTH_MESSAGE, values: { length: 20 } })
        .onlyLettersSpacesNumbers(),
      company_name: Yup.string()
        .max(60, { key: MAX_LENGTH_MESSAGE, values: { length: 60 } })
        .required(REQUIRED_MESSAGE)
        .onlyLettersSpacesNumbers(),
      job_title: Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE),
      }),
      email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
      phone_number: Yup.string()
        .phoneNumber()
        .typeError(REQUIRED_MESSAGE)
        .required(REQUIRED_MESSAGE),
      ...(isRecaptchaEnabled
        ? {
            recaptcha: Yup.mixed().required(REQUIRED_MESSAGE),
          }
        : {}),
    })
  );
