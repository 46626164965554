import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import LoaderScreen from 'components/common/LoaderScreen';
import Button from 'components/common/Button';

import SignUpContent from 'components/Authorization/SignUpContent';

import { PublicPaths } from 'constants/routes';
import { UserTypes } from 'constants/constants';

import type { User } from 'contexts/AuthContext';

import styles from './index.module.scss';

const InviteAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email, token } = useParams();

  const encodedEmail = encodeURIComponent(email!);

  const {
    data: invitedData,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useQuery<User, AxiosError>(
    'invitedUser',
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/signup-invited/?email=${encodedEmail}&token=${token}`
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
  const { company } = invitedData || {};

  const isVendorInvited = invitedData?.current_group_name === UserTypes.vendor;

  const defaultValues = invitedData
    ? {
        ...invitedData,
        ...(isVendorInvited
          ? {}
          : {
              company: {
                ...company,
                country_code: {
                  value: company?.country_code || '',
                  label: company?.country_code || '',
                },
                department: {
                  value: company?.department || '',
                  label: t(`common.field.${company?.department}`) || '',
                },
              },
            }),
        job_title: invitedData?.job_title
          ? {
              value: invitedData?.job_title || '',
              label: invitedData?.job_title || '',
            }
          : undefined,
      }
    : undefined;

  const backToLoginHandler = () => {
    navigate(`/${PublicPaths.LOG_IN}`);
  };

  const inviteUserError =
    error?.response?.data && Object.keys(error?.response?.data);
  const inviteUserErrorMessage =
    inviteUserError?.length &&
    typeof error?.response?.data === 'object' &&
    error?.response?.data[inviteUserError[0]];
  const textError: string = Array.isArray(inviteUserErrorMessage)
    ? inviteUserErrorMessage?.join('')
    : inviteUserErrorMessage;
  const nativeError = error?.message;

  return (
    <div className={styles.wrapper}>
      {isLoading && <LoaderScreen />}
      {isError && (
        <>
          <span className={styles.error}>{textError || nativeError}</span>
          <span className={styles.message}>{t('common.error.try-again')}</span>
          <div className={styles.action}>
            <Button onClick={backToLoginHandler} className={styles.button}>
              {t('auth.back-to-login')}
            </Button>
          </div>
        </>
      )}
      {isSuccess && invitedData && (
        <SignUpContent isInvited defaultValues={defaultValues} />
      )}
    </div>
  );
};

export default InviteAccount;
