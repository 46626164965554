import * as Yup from 'yup';

import {
  MAX_LENGTH_MESSAGE,
  REQUIRED_MESSAGE,
  MAXIMUM_DIGITS_AFTER_DECIMAL,
  TWO_DIGITS_AFTER_COMMA_REGEXP,
} from 'utils/validations';

export const PRICING_PLAN_NAME_MAX_LENGTH = 40;
export const PRICING_PLAN_DESCRIPTION_MAX_LENGTH = 120;
export const PRICING_PLAN_BENEFIT_NAME_MAX_LENGTH = 30;
export const PRICING_BENEFITS_MAX_COUNT = 10;
export const PRICING_IMAGE_WIDTH = 408;
export const PRICING_IMAGE_HEIGHT = 284;

export const validationSchemaCreatePricingPlan = Yup.lazy(() =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(PRICING_PLAN_NAME_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: PRICING_PLAN_NAME_MAX_LENGTH },
      })
      .required(REQUIRED_MESSAGE)
      .onlyLettersSpacesNumbers(),
    description: Yup.string().max(PRICING_PLAN_DESCRIPTION_MAX_LENGTH, {
      key: MAX_LENGTH_MESSAGE,
      values: { length: PRICING_PLAN_DESCRIPTION_MAX_LENGTH },
    }),
    period_details: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().trim().required(REQUIRED_MESSAGE),
        price: Yup.string()
          .max(10, { key: MAX_LENGTH_MESSAGE, values: { length: 10 } })
          .test({
            name: 'price',
            test: function (value) {
              const isNotEmpty =
                this.parent.isFree || !!value || !this.parent.isChecked;

              return (
                isNotEmpty ||
                this.createError({
                  message: REQUIRED_MESSAGE,
                  path: '',
                })
              );
            },
          })
          .test(
            'is-decimal',
            {
              key: MAXIMUM_DIGITS_AFTER_DECIMAL,
              values: { value: 2 },
            },
            (val?: string) => {
              if (!!val) {
                return TWO_DIGITS_AFTER_COMMA_REGEXP.test(val);
              }
              return true;
            }
          ),
        link: Yup.string().url().nullable(),
      })
    ),
  })
);
