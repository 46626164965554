import { usePopperTooltip } from 'react-popper-tooltip';

import SimpleTooltip from 'components/UI/SimpleTooltip';

import getLongStringView from 'helpers/getLongStringView';

import styles from './index.module.scss';

type Props = {
  label: string;
  length: number;
  secondPartLength?: number;
};

const CellWithLongValue = ({ label, length, secondPartLength }: Props) => {
  const tooltipProps = usePopperTooltip({ placement: 'top' });

  return (
    <div className={styles.wrapper}>
      <span ref={tooltipProps.setTriggerRef} className={styles.label}>
        {getLongStringView(label, length, secondPartLength)}
      </span>
      {tooltipProps.visible && label.length >= length && (
        <SimpleTooltip className={styles.tooltip} options={tooltipProps}>
          {label}
        </SimpleTooltip>
      )}
    </div>
  );
};

export default CellWithLongValue;
