import cn from 'classnames';
import { useState } from 'react';

import styles from './index.module.scss';

type Props = {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  register?: { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void };
  onChange?: (isChecked: boolean) => void;
  className?: string;
};

const ToggleSwitch = ({
  id,
  register,
  checked: defaultChecked,
  disabled,
  onChange,
  className,
}: Props) => {
  const [checked, setChecked] = useState(Boolean(defaultChecked));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(!checked);
      setChecked(!checked);
    }

    if (!register) return;
    register.onChange(e);
    setChecked(!checked);
  };

  return (
    <div className={cn(styles.toggle, className)}>
      <label
        className={cn(styles.toggle__label, {
          [styles['toggle__label--checked']]: defaultChecked,
          [styles['toggle__label--disabled']]: disabled,
        })}
        onClick={e => {
          e.stopPropagation();
        }}
        htmlFor={id}
      >
        <input
          type="checkbox"
          className={styles.toggle__checkbox}
          id={id}
          {...register}
          checked={defaultChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className={styles.inner} />
        <span className={styles.switch} tabIndex={-1} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
