import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { AxiosError } from 'axios';
import {
  CreateCampaignValues,
  DeliveryLabels,
  CreateCampaignStepProps,
} from 'components/CreateCampaign/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import ToggleSwitch from 'components/common/ToggleSwitch';

import InfoSectionWrapper from 'components/CampaignDetails/InfoSectionWrapper';
import DepositNowButton from 'components/PaymentsComponents/DepositNowButton';
import SelectPaymentMethod from 'components/PaymentsComponents/SelectPaymentMethod';
import PriceDetails from 'components/CreateCampaign/Step4/TotalCost/PriceDetails';
import ReceiveDepositLinkButton from 'components/CreateCampaign/Step4/TotalCost/ReceiveDepositLinkButton';
import SubmitCampaignButton from 'components/CreateCampaign/Step4/TotalCost/SubmitCampaignButton';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import useGetExpectedPrice from 'components/PaymentsComponents/useGetExpectedPrice';

import getResponseError from 'helpers/getResponseError';
import { getDeliveryErrorsArray } from 'helpers/createCampaign';

import { CampaignCreatingStep } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  isDemoCampaign?: boolean;
  goToStep: CreateCampaignStepProps['goToStep'];
};

export type TotalCostResponse = {
  cost_per_lead: string;
  total_leads: number;
  platform_fee: string;
  qc_fee: string;
  total_price: string;
  lack_amount: string;
};

const DEFAULT_REFETCH_PRICE_INTERVAL = 2500;

const TotalCost = ({ isDemoCampaign, goToStep }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const [isUsingGeneralWallet, setIsUsingGeneralWallet] = useState(false);
  const navigate = useNavigate();
  const [isBalanceModalClosed, setIsModalClosed] = useState(false);
  const {
    formMethods: {
      setValue,
      watch,
      control,
      getValues,
      formState: { errors, isValid },
    },
    formatPayload,
    mutation: { data: expectedPriceData, isLoading: isGetPriceLoading },
  } = useGetExpectedPrice();
  const campaignFormSettings = useFormContext<CreateCampaignValues>();

  const handleSetTotalDepositAmount = (
    useGeneralWallet: boolean,
    totalData: TotalCostResponse
  ) => {
    const lackAmountNumber = Number(totalData.lack_amount);
    const totalPriceNumber = Number(totalData.total_price);
    const lackAmountDiscount =
      lackAmountNumber < 0
        ? totalPriceNumber + lackAmountNumber
        : totalPriceNumber;
    const discount = useGeneralWallet ? lackAmountDiscount : undefined;
    const lackAmount = lackAmountNumber < 0 ? lackAmountNumber * -1 : 0;

    setValue('amount', !useGeneralWallet ? totalPriceNumber : lackAmount);
    setValue('discount', discount?.toString());
  };

  const campaign = campaignFormSettings.getValues();

  const handleGoToHomePage = async () => {
    await queryClient.refetchQueries([
      'campaign-list',
      user?.current_group_name,
    ]);
    await queryClient.invalidateQueries([
      'campaign',
      campaign.id,
      user?.current_group_name,
      user?.company.id.toString(),
    ]);
    openModal({
      Content: (
        <ModalWindow
          className={styles.successModal}
          successMessage={t('campaign.created-successfully', {
            name: campaign.campaign_details.name,
          })}
        />
      ),
    });
    navigate(PrivatePaths.INDEX);
  };

  const {
    isLoading: isProcessingPaymentLoading,
    mutate: postProcessingPayment,
  } = useMutation<void, AxiosError>(async () => {
    try {
      await axios.post(`/campaigns/${campaign.id}/payment-processing/`);
      campaignFormSettings.setValue(
        'campaign_details.creating_step',
        CampaignCreatingStep.completed
      );
      await handleGoToHomePage();
    } catch (err) {
      throw err;
    }
  });

  const handleValidateCampaign = async () => {
    const isDeliveryValid = await campaignFormSettings.trigger(
      'campaign_delivery'
    );

    if (!isDeliveryValid) {
      goToStep(CampaignCreatingStep['first-step']);
      await campaignFormSettings.trigger('campaign_delivery', {
        shouldFocus: true,
      });
      const errorsData = getDeliveryErrorsArray(
        campaignFormSettings.formState.errors?.campaign_delivery
      );

      openModal({
        Content: (
          <ModalWindow title={t('common.error.something-went-wrong')}>
            {errorsData?.map(err =>
              err?.message ? (
                <Error
                  message={`${t(
                    // @ts-ignore
                    `common.field.${DeliveryLabels[err.key]}`
                  )}: ${t(err.message)}`}
                  key={err.key}
                />
              ) : null
            )}
          </ModalWindow>
        ),
      });
    }
    return isDeliveryValid;
  };

  const {
    data: total,
    isLoading,
    error,
  } = useQuery<TotalCostResponse, AxiosError>(
    ['total-cost', campaign?.id],
    async () => {
      try {
        const response = await axios.get<TotalCostResponse>(
          `/campaigns/${campaign?.id}/price/`
        );
        return response.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async response => {
        if (Number(response.lack_amount) >= 0) {
          await queryClient.refetchQueries('balance');
        }
        handleSetTotalDepositAmount(isUsingGeneralWallet, response);
        setValue('line_items', [
          {
            title: `Campaign leads (${campaign.campaign_details.name})`,
            quantity: response.total_leads,
            price: Number(response.cost_per_lead),
          },
          ...(Number(response.qc_fee)
            ? [
                {
                  title: 'QC fee',
                  quantity: response.total_leads,
                  price: Number(response.qc_fee),
                },
              ]
            : []),
          {
            title: 'Platform fee',
            quantity: response.total_leads,
            price: Number(response.platform_fee),
          },
        ]);
      },
      enabled: !!campaign.id,
      refetchInterval: response => {
        return (response?.lack_amount || 0) < 0
          ? DEFAULT_REFETCH_PRICE_INTERVAL
          : false;
      },
    }
  );

  const checkoutOptions = {
    onSuccess: () => {
      postProcessingPayment();
    },
    onClose: async () => {
      setIsModalClosed(true);
    },
  };

  const handleUseGeneralWalletToggleChange = () => {
    setIsUsingGeneralWallet(prev => {
      if (total) handleSetTotalDepositAmount(!prev, total);
      return !prev;
    });
  };

  const discount = Number(watch('discount') || 0);
  const isPaymentRequired = watch('amount') > 0;
  const isLoaderScreenVisible =
    isProcessingPaymentLoading && isBalanceModalClosed;
  const totalWithDiscount = (
    Number(total?.total_price || 0) - discount
  ).toFixed(2);
  const totalCampaignCost = isPaymentRequired
    ? expectedPriceData?.amount || totalWithDiscount
    : totalWithDiscount;

  return (
    <>
      {!isDemoCampaign && (
        <InfoSectionWrapper>
          <InfoSectionWrapper.Header>
            <InfoSectionWrapper.Title>
              {t('common.field.total-cost')}
            </InfoSectionWrapper.Title>
          </InfoSectionWrapper.Header>
          {total && (
            <InfoSectionWrapper.Content className={styles.wrapper}>
              <PriceDetails
                totalCampaignCost={total}
                isPaymentRequired={isPaymentRequired}
                transactionFee={expectedPriceData?.processing_fee}
              />
              <div className={styles.total}>
                <div className={styles.amount}>
                  <p>
                    $
                    {isGetPriceLoading ? (
                      <Loader size={26} className={styles.loading} />
                    ) : (
                      totalCampaignCost
                    )}
                    <span className={styles.sign}>
                      {t('common.field.total-deposit-amount')}
                    </span>
                  </p>
                  <label className={styles.toggle}>
                    <ToggleSwitch
                      id="use-current-balance"
                      checked={isUsingGeneralWallet}
                      onChange={handleUseGeneralWalletToggleChange}
                    />
                    <span>{t('payments.use-current-balance')}</span>
                  </label>
                </div>

                {isPaymentRequired && (
                  <div className={styles.insufficient}>
                    <form>
                      <SelectPaymentMethod
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        daysCountError={errors.term?.message}
                        methodError={errors.method?.message}
                      />
                      {user?.is_superadmin ? (
                        <ReceiveDepositLinkButton
                          disabled={!isValid}
                          campaignId={campaign.id}
                          checkoutOptions={checkoutOptions}
                          handleValidateCampaign={handleValidateCampaign}
                          getValues={getValues}
                          formatPayload={formatPayload}
                          watch={watch}
                        />
                      ) : (
                        <DepositNowButton
                          checkoutOptions={checkoutOptions}
                          disabled={!isValid}
                          className={styles.deposit}
                          getIsPaymentEnabled={handleValidateCampaign}
                          getPaymentOptions={() =>
                            formatPayload({
                              ...getValues(),
                              campaign_id: campaign?.id.toString(),
                            })
                          }
                        />
                      )}
                    </form>
                  </div>
                )}
              </div>
            </InfoSectionWrapper.Content>
          )}
          {isLoading && <Loader className={styles.loader} />}
          {error && (
            <InfoSectionWrapper.Content>
              <p className={styles.error}>
                {t('common.error.something-went-wrong')}
              </p>
              <Error message={getResponseError(error)} />
            </InfoSectionWrapper.Content>
          )}
        </InfoSectionWrapper>
      )}
      <SubmitCampaignButton
        campaignId={campaign.id}
        disabled={isPaymentRequired}
        handleValidateCampaign={handleValidateCampaign}
        setValueToCampaignForm={campaignFormSettings.setValue}
        handleGoToHomePage={handleGoToHomePage}
      />
      {isLoaderScreenVisible && <LoaderScreen />}
    </>
  );
};

export default TotalCost;
