import { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { Column } from 'react-table';
import type { Dispatch, SetStateAction } from 'react';

import { LogsFilterCategoryTypes } from 'components/common/Table/TableFilter/models';
import { SyncDataModalProps } from 'components/IntegrationsContent/models';
import type {
  PaginationFastAPI,
  CRMSyncLog,
  DateRangeOption,
  SelectOption,
} from 'types/models';

import Pagination from 'components/common/Pagination';
import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';
import Table from 'components/common/Table';
import Button from 'components/common/Button';
import TableFilter from 'components/common/Table/TableFilter';
import CellWithLongValue from 'components/common/Table/CellWithLongValue';
import SortingHeader from 'components/common/Table/SortingHeader';

import LogStatus from 'components/IntegrationsContent/AdminContent/LogsTable/LogStatus';
import SyncDataModal from 'components/IntegrationsContent/AdminContent/LogsTable/SyncDataModal';
import AppliedFilter from 'components/IntegrationsContent/AdminContent/LogsTable/AppliedFilter';
import DateFilter from 'components/IntegrationsContent/AdminContent/LogsTable/AppliedFilter/DateAppliedFilter';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { LogsTableAccessors, UserTypes } from 'constants/constants';
import { logsFilters } from 'components/IntegrationsContent/AdminContent/LogsTable/tableFilterConfig';

import { PrivatePaths } from 'constants/routes';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const PAGE_SIZE = 10;

type RowsType = { [key in LogsTableAccessors]: any };

const LogsTable = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();

  const { openModal } = useModal();

  const infoRef = useRef(null);

  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<
    Record<string, SelectOption[] | DateRangeOption>
  >({});

  const [orderingList, setOrderList] = useState<string[]>([]);

  const { connectionId } = useParams();

  const orderListString = orderingList.join(',');

  const searchParamsKeys = Object.keys(searchParams);

  const getSearchParamsString = () => {
    const searchParamsArray = Object.entries(searchParams).reduce<string[]>(
      (acc, [key, value]) => {
        if (key === LogsFilterCategoryTypes.date_range) {
          if ((value as DateRangeOption).date_from) {
            acc.push(`date_from=${(value as DateRangeOption).date_from}`);
          }
          if ((value as DateRangeOption).date_to) {
            acc.push(`date_to=${(value as DateRangeOption).date_to}`);
          }
        } else if (value) {
          let completedString = `${key}=`;
          (value as SelectOption[]).map((item: SelectOption, index: number) => {
            return index === (value as SelectOption[]).length - 1
              ? (completedString += `${item.value}`)
              : (completedString += `${item.value},`);
          });
          acc.push(completedString);
        }
        return acc;
      },
      []
    );
    return searchParamsArray.length ? `&${searchParamsArray.join('&')}` : '';
  };

  const {
    data: logsData,
    isLoading,
    error,
    dataUpdatedAt,
  } = useQuery<PaginationFastAPI<CRMSyncLog[]>, AxiosError>(
    [
      'logs-list',
      user?.company.id,
      pageSize,
      page,
      orderListString,
      searchParams,
      connectionId,
    ],
    async () => {
      try {
        const { data } = await axios.get<PaginationFastAPI<CRMSyncLog[]>>(
          `/crm-integrations/api/${
            user?.company.id
          }/connections/${connectionId}/logs/?size=${pageSize}&page=${page}${
            !!orderListString ? `&order_by=${orderListString}` : ''
          }${getSearchParamsString()}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: user?.current_group_name === UserTypes.admin,
      keepPreviousData: true,
    }
  );

  const openSyncDataModal = ({
    syncData,
    status,
    date,
    email,
  }: SyncDataModalProps) => {
    openModal({
      Content: (
        <SyncDataModal
          syncData={syncData}
          status={status}
          date={date}
          email={email}
        />
      ),
    });
  };

  const rowsData = useMemo(() => {
    return logsData?.items.map(
      log =>
        ({
          [LogsTableAccessors.leadsEmail]: (
            <CellWithLongValue
              label={log.campaign_lead_email}
              length={30}
              secondPartLength={10}
            />
          ),
          [LogsTableAccessors.campaign]: (
            <Link
              to={`/${PrivatePaths.CAMPAIGN}/${log.campaign_id}`}
              className={styles.link}
            >
              <CellWithLongValue label={log.campaign_name} length={60} />
            </Link>
          ),
          [LogsTableAccessors.status]: (
            <LogStatus status={log.status} failReason={log.fail_reason} />
          ),
          [LogsTableAccessors.dateTime]: (
            <p className={styles.time}>
              {dayjs(log.created_at)
                .utcOffset(0)
                .format('DD MMM, YYYY, hh:mm A')}
            </p>
          ),
          [LogsTableAccessors.syncData]: (
            <Button
              className={styles.button}
              white
              onClick={() =>
                openSyncDataModal({
                  syncData: log.sync_data,
                  status: log.status,
                  date: log.created_at,
                  email: log.campaign_lead_email,
                })
              }
            >
              {t('common.button.view-details')}
            </Button>
          ),
        } as RowsType)
    );
  }, [dataUpdatedAt]);

  const columnsData = useMemo<Column<RowsType>[]>(
    () => [
      {
        Header: (
          <SortingHeader
            label={t('common.field.leads-email')}
            value={LogsTableAccessors.leadsEmail}
            setOrderingList={setOrderList}
            orderingList={orderingList}
          />
        ),
        accessor: LogsTableAccessors.leadsEmail,
      },
      {
        Header: (
          <SortingHeader
            label={t('campaign.campaign')}
            value={LogsTableAccessors.campaign}
            setOrderingList={setOrderList}
            orderingList={orderingList}
          />
        ),
        accessor: LogsTableAccessors.campaign,
      },
      {
        Header: (
          <SortingHeader
            label={t('common.field.status')}
            value={LogsTableAccessors.status}
            setOrderingList={setOrderList}
            orderingList={orderingList}
          />
        ),
        accessor: LogsTableAccessors.status,
      },
      {
        Header: (
          <SortingHeader
            label={t('common.field.date-time')}
            value={LogsTableAccessors.dateTime}
            setOrderingList={setOrderList}
            orderingList={orderingList}
          />
        ),
        accessor: LogsTableAccessors.dateTime,
      },
      {
        Header: '',
        accessor: LogsTableAccessors.syncData,
      },
    ],
    [orderingList]
  );

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {error && <Error message={getResponseError(error)} />}
      {!isLoading && (
        <div className={styles.tableWrapper}>
          <div className={styles.manageTable} ref={infoRef}>
            <div className={styles.top}>
              <div className={styles.left}>
                <div className={styles.tabs}>
                  <span
                    className={cn(styles.tab, {
                      [styles.active]: true,
                    })}
                  >
                    {t('common.field.logs')}
                  </span>
                </div>
              </div>
              <div className={styles.right}>
                {(!!logsData?.total ||
                  (!!searchParamsKeys.length && !logsData?.total)) && (
                  <TableFilter
                    filterOptions={logsFilters}
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                  />
                )}
              </div>
            </div>
            <div className={styles.bottom}>
              {!!searchParamsKeys.length &&
                searchParamsKeys.map(item => {
                  if (item === LogsFilterCategoryTypes.date_range) {
                    return (
                      <DateFilter
                        setSearchParams={
                          setSearchParams as Dispatch<
                            SetStateAction<Record<string, DateRangeOption>>
                          >
                        }
                        category={item}
                        submittedValue={searchParams[item] as DateRangeOption}
                        key={item}
                      />
                    );
                  } else {
                    return (
                      <AppliedFilter
                        key={item}
                        label={logsFilters[item].label}
                        category={item}
                        selectedOptions={searchParams[item] as SelectOption[]}
                        setSearchParams={setSearchParams}
                        getSubmenu={logsFilters[item].getSubmenu}
                        translationPrefix={
                          logsFilters[item]?.translationPrefix || undefined
                        }
                      />
                    );
                  }
                })}
            </div>
          </div>
          <Table
            tableOptions={{
              columns: columnsData,
              data: rowsData || [],
            }}
            wrapperClassName={styles.table}
            isEvenRowGrey
            headClassName={styles.thead}
            rowClassName={styles.row}
            isLastColumnFixed
          />
          <Pagination
            totalCount={logsData?.total || 0}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={page}
            setPage={setPage}
            scrollToRef={infoRef}
            className={styles.pagination}
            currentPageItems={logsData?.items.length}
          />
        </div>
      )}
    </>
  );
};

export default LogsTable;
