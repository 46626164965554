import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useRef } from 'react';

import Button from 'components/common/Button';

import { validateUploadedFiles, createImageRenderLink } from 'helpers/files';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  className?: string;
  accept?: string;
  uploadImage: (props: {
    originalFile: File | null;
    renderLink: string;
  }) => void;
  setValidationError: (message: string) => void;
  maxSize?: number;
};

const ChangeImageButton = ({
  className,
  uploadImage,
  setValidationError,
  maxSize,
  accept,
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];
    if (uploadedFile) {
      const validationErrorMessage = validateUploadedFiles({
        files: uploadedFile,
        maxSize,
        t,
      });
      if (validationErrorMessage) {
        if (inputRef.current) inputRef.current.value = '';
        return setValidationError(validationErrorMessage);
      }
      createImageRenderLink(uploadedFile, src => {
        uploadImage({
          originalFile: uploadedFile,
          renderLink: src,
        });
      });
    }
    if (inputRef.current) inputRef.current.value = '';
  };

  return (
    <Button
      white
      className={cn(styles.upload, className)}
      iconProps={{ name: IconsNames.upload_file }}
    >
      <input
        ref={inputRef}
        type="file"
        accept={accept || 'image/jpeg, image/png'}
        onChange={handleChangeImage}
        className={styles.input}
      />
      {t('common.button.change-image')}
    </Button>
  );
};

export default ChangeImageButton;
