import * as Yup from 'yup';

import { REQUIRED_MESSAGE } from 'utils/validations';

export const validationSchemaDownloadAsset = Yup.lazy(() =>
  Yup.object().shape({
    questions: Yup.array().of(
      Yup.object()
        .shape({
          answers: Yup.array().of(
            Yup.object()
              .shape({
                checked: Yup.boolean(),
                is_other: Yup.boolean(),
                custom_answer: Yup.string(),
              })
              .test('answer', REQUIRED_MESSAGE, function (answer) {
                if (
                  answer.checked &&
                  answer.is_other &&
                  !answer.custom_answer?.length
                ) {
                  return false;
                }
                return true;
              })
          ),
        })
        .test('answers', REQUIRED_MESSAGE, function (answers) {
          return answers?.answers?.some(answer => answer.checked) || false;
        })
    ),
  })
);
