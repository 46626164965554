const SECOND_PART_LENGTH = 5;
const DOTS = '...';

export default (
  string: string,
  maxLength: number,
  secondPartLength: number = SECOND_PART_LENGTH
) => {
  const firstPartEndIndex = maxLength - secondPartLength - DOTS.length;

  if (!string) return;

  return string.length < maxLength
    ? string
    : `${string.slice(0, firstPartEndIndex)}...${string.slice(
        -secondPartLength
      )}`;
};
