import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { toast } from 'react-toastify';

import type {
  TwoFADevice,
  TwoFATypes,
} from 'components/Authorization/TwoFA/models';
import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import Button from 'components/common/Button';
import InputWrapper from 'components/common/InputWrapper';

import useAuth, { User } from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { validationSchemaCreateDevice } from 'utils/validations';
import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

type Props = {
  devices: TwoFADevice[];
  type: TwoFATypes;
};

const DeactivateDeviceModal = ({ devices, type }: Props) => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { register, formState, handleSubmit } = useForm<{ password: string }>({
    resolver: yupResolver(validationSchemaCreateDevice(true)),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isShowPassword, setIsShowPassword] = useState(true);

  const {
    mutate: deleteDevice,
    isLoading: isDeleteDeviceLoading,
    error,
  } = useMutation<void, AxiosError, { password: string }>(
    async password => {
      try {
        devices.forEach(async device => {
          await axios.delete(`/accounts/devices/${device.persistent_id}/`, {
            data: password,
          });
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        const oldProfileData =
          queryClient.getQueryData<User>('profile') || user;
        if (oldProfileData) {
          const updatedDevices = oldProfileData.devices.filter(
            device =>
              !devices.find(item => item.persistent_id === device.persistent_id)
          );
          queryClient.setQueryData<User>('profile', {
            ...oldProfileData,
            devices: updatedDevices,
          });
        }
        closeModal();

        toast.success(t('profile.authentication-deleted', { type }), {
          autoClose: 3000,
          hideProgressBar: true,
        });
      },
    }
  );

  return (
    <>
      {isDeleteDeviceLoading && <LoaderScreen />}
      <ModalWindow
        primaryTitle={t('profile.delete-device', { type })}
        className={styles.modal}
      >
        <form onSubmit={handleSubmit(data => deleteDevice(data))}>
          <InputWrapper
            label="common.field.password"
            setIsHiddenInputContent={setIsShowPassword}
            validationError={formState.errors.password?.message}
            submitError={
              error ? getResponseError(error?.response?.data) : undefined
            }
            wrapperClasses={styles.inputWrapper}
            isMediumInput
          >
            <input
              type={isShowPassword ? 'password' : 'text'}
              {...register('password')}
            />
          </InputWrapper>
          <div className={styles.buttonsWrapper}>
            <Button white onClick={closeModal} type="button">
              {t('common.button.cancel')}
            </Button>
            <Button type="submit">{t('common.button.delete')}</Button>
          </div>
        </form>
      </ModalWindow>
    </>
  );
};

export default DeactivateDeviceModal;
