import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import getSymbolFromCurrencyMap from 'currency-symbol-map';

import type { AxiosError } from 'axios';
import {
  ProductPricingPlanResponse,
  ProductPricingPlanPeriods,
} from 'components/Product/models';

import CreatePricingPlanModal from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal';

import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import defaultImage from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  planData: ProductPricingPlanResponse;
  companySlug?: string;
  productSlug?: string | null;
  productId?: number;
  productName?: string;
  isGetADemoAvailable?: boolean;
  onOpenGetDemo?: () => void;
};

const PricingPlanItem: React.FC<Props> = ({
  planData,
  companySlug,
  productSlug,
  productId,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios } = useAuth();
  const {
    name,
    period,
    id,
    benefits,
    period_details: periodDetails,
    images,
    currency,
  } = planData;
  const queryClient = useQueryClient();

  const { mutate: deletePlan, isLoading } = useMutation<void, AxiosError>(
    async () => {
      try {
        await axios.delete(
          `/companies/${companySlug}/products/${productSlug}/pricing-plans/${id}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        closeModal();
        queryClient.setQueryData<ProductPricingPlanResponse[]>(
          ['get-pricing-plans', companySlug, productId?.toString()],
          oldData => oldData?.filter(item => item.id !== id) || []
        );
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const isEditButtonDisabled = !companySlug || !productId || !productSlug;

  const handleEdit = () => {
    if (isEditButtonDisabled) return;
    openModal({
      Content: (
        <CreatePricingPlanModal
          companySlug={companySlug}
          productSlug={productSlug}
          planId={id}
          productId={productId}
          defaultValues={planData}
        />
      ),
    });
  };

  const handleDelete = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('manage-products.pricing-plan').toLowerCase()}
          itemName={name}
          onDelete={deletePlan}
        />
      ),
    });
  };

  const formatPrice = (receivedPrice: string, index: number) => {
    const currencySymbol = getSymbolFromCurrencyMap(currency) || currency;

    return Number(receivedPrice) === 0
      ? t('auth.free')
      : `${index === 0 ? currencySymbol : ''}${receivedPrice}`;
  };

  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.name}>
          <img
            src={images[0]?.image || defaultImage}
            className={cn(styles.image, {
              [styles.default]: !images[0]?.image,
            })}
          />
          {name}
        </div>
      </td>
      <td>
        {period === ProductPricingPlanPeriods['monthly-or-annually']
          ? periodDetails
              .map(detail => t(`common.field.${detail.name}`))
              .join(', ')
          : t(`common.field.${period}`)}
      </td>
      <td>
        {periodDetails
          .map((detail, index) => formatPrice(detail.price, index))
          .join('/')}
      </td>
      <td className={styles.benefits}>{benefits.length}</td>

      <td className={styles.wrapper}>
        <div className={styles.actions}>
          <Button
            type="button"
            className={styles.edit}
            onClick={handleEdit}
            iconProps={{ name: IconsNames.edit }}
            disabled={isEditButtonDisabled}
          />
          <Button
            type="button"
            className={styles.delete}
            onClick={handleDelete}
            iconProps={{ name: IconsNames.close }}
            white
          />
        </div>
      </td>
      {isLoading && <LoaderScreen />}
    </tr>
  );
};

export default PricingPlanItem;
