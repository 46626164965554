import { ConnectionType, IntegrationsLogStatuses } from 'constants/constants';

export enum ConnectionStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  failed = 'failed',
}

export type CRMIntegration = {
  company_id: number;
  crm_platform: string;
  fail_reason: string;
  id: string;
  status: ConnectionStatus;
  connection_type: ConnectionType;
  platform: string;
};

export type CRMDetailsType = {
  connection_type: string;
  status: string;
  platform: string;
  company_id: string;
  crm_platform: string;
  fail_reason: string;
  id: string;
};

export type SyncDataModalProps = {
  syncData: Record<string, string>;
  status: IntegrationsLogStatuses;
  date: string;
  email: string;
};
