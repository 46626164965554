import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';
import Button, { ButtonProps } from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  iconName: IconsNames;
  title: string;
  buttonProps?: ButtonProps;
  disabled?: boolean;
  withoutBorder?: boolean;
  className?: string;
  isSkipHorizontalPadding?: boolean;
  isLoading?: boolean;
};

const SectionWrapper: React.FC<Props> = ({
  children,
  iconName,
  title,
  buttonProps,
  disabled = false,
  withoutBorder,
  className,
  isSkipHorizontalPadding,
  isLoading,
}) => {
  const isEmpty = Array.isArray(children)
    ? children.every(child => !child)
    : !children;

  return (
    <section
      className={cn(styles.section, className, {
        [styles.disabled]: disabled,
        [styles.noBorder]: withoutBorder,
        [styles.skipPadding]: isSkipHorizontalPadding,
      })}
      aria-label={title}
    >
      <h2 className={cn(styles.header, { [styles.empty]: isEmpty })}>
        {isLoading ? (
          <Loader size={16} />
        ) : (
          <IconSVG name={iconName} className={styles.icon} />
        )}
        <span className={styles.title}>{title}</span>
        {buttonProps && (
          <Button
            {...buttonProps}
            white
            className={styles.button}
            disabled={disabled}
          />
        )}
      </h2>
      {children}
    </section>
  );
};

export default SectionWrapper;
