import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';

import type { Dispatch, SetStateAction } from 'react';
import type { FetchNextPageOptions } from 'react-query';

import { SelectOption } from 'types/models';

import Button from 'components/common/Button';
import InputWrapper from 'components/common/InputWrapper';
import ToggleSwitch from 'components/common/ToggleSwitch';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';

import Option from 'components/common/Table/TableFilter/DynamicOptions/Option';

import { IconsNames } from 'constants/constants';

import useObserver from 'hooks/useObserver';

import styles from './index.module.scss';

export type DynamicOptionsProps = {
  setSearchParams: Dispatch<SetStateAction<Record<string, SelectOption[]>>>;
  searchParams: SelectOption[];
  options: SelectOption[];
  category: string;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  isRefetching?: boolean;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  isEmptyMessageVisible?: boolean;
  setIsOptionsListVisible: Dispatch<SetStateAction<boolean>>;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<any>;
};

const DynamicOptions = ({
  setSearchParams,
  searchParams,
  options,
  category,
  hasNextPage,
  isFetchingNextPage,
  setSearchValue,
  searchValue,
  isRefetching,
  isEmptyMessageVisible,
  setIsOptionsListVisible,
  fetchNextPage,
}: DynamicOptionsProps) => {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState(false);

  const loadMoreRef = useRef(null);

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
    searchParams || []
  );

  const [showOnlySelected, setShowOnlySelected] = useState(false);

  const handleInputChange = (value: SelectOption) => {
    if (selectedOptions.some(item => item.value === value.value)) {
      setSelectedOptions(
        selectedOptions.filter(item => item.value !== value.value)
      );
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const onClickApply = () => {
    if (selectedOptions.length) {
      setSearchParams(prev => {
        return {
          ...prev,
          [category]: selectedOptions,
        };
      });
    } else {
      setSearchParams(prev => {
        const updatedParams = { ...prev };
        delete updatedParams[category];
        return updatedParams;
      });
    }
    setIsOptionsListVisible(false);
  };

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting) fetchNextPage();
  });

  return (
    <div>
      <InputWrapper isMediumInput isErrorHidden wrapperClasses={styles.search}>
        {isRefetching && !isFetchingNextPage ? (
          <Loader size={20} />
        ) : (
          <IconSVG name={IconsNames.magnifier} className={styles.magnifier} />
        )}
        <input
          type="text"
          value={searchValue}
          onChange={e => setSearchValue(e.currentTarget.value)}
          placeholder={t('common.field.search')}
        />
      </InputWrapper>
      <InputWrapper
        label="common.button.show-selected-only"
        wrapperClasses={styles.toggle}
        isErrorHidden
      >
        <ToggleSwitch
          id="show selected only"
          checked={showOnlySelected}
          onChange={setShowOnlySelected}
        />
      </InputWrapper>
      {!!options.length && (
        <ul
          className={styles.optionsList}
          onScroll={() => {
            setShowTooltip(false);
          }}
        >
          {!showOnlySelected &&
            options.map(item => {
              return (
                <Option
                  key={item.value}
                  item={item}
                  handleInputChange={handleInputChange}
                  isChecked={selectedOptions.some(
                    element => element.value === item.value
                  )}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                />
              );
            })}
          {showOnlySelected &&
            selectedOptions.map(item => {
              return (
                <Option
                  key={item.value}
                  item={item}
                  handleInputChange={handleInputChange}
                  isChecked={true}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                />
              );
            })}
          <li ref={loadMoreRef}>
            {hasNextPage && isFetchingNextPage && (
              <Loader size={20} className={styles.loader} />
            )}
          </li>
        </ul>
      )}
      {isEmptyMessageVisible && (
        <p className={styles.empty}>
          {searchValue
            ? t('common.field.no-options-found')
            : t('common.field.no-options')}
        </p>
      )}
      <Button className={styles.apply} white onClick={onClickApply}>
        {t('common.button.apply')}
        {!!selectedOptions.length && <span>({selectedOptions.length})</span>}
      </Button>
    </div>
  );
};

export default DynamicOptions;
