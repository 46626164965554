import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useState, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import type { ProductPricingPlanFormValues } from 'components/Product/CreateProductContent/models';
import type { ProductPricingPlanResponse } from 'components/Product/models';
import type { AxiosError, AxiosResponse } from 'axios';

import InputWrapper from 'components/common/InputWrapper';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import PricingImage from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/Overview/PricingImage';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import {
  PRICING_PLAN_DESCRIPTION_MAX_LENGTH,
  PRICING_IMAGE_WIDTH,
  PRICING_IMAGE_HEIGHT,
} from 'components/Product/CreateProductContent/PricingPlans/CreatePricingPlanModal/validationSchema';

import styles from '../index.module.scss';

type Props = {
  submitErrors?: AxiosResponse<ProductPricingPlanResponse>;
  companySlug: string;
  productSlug: string;
  pricingPlanId?: number;
  productId: number;
};

const Overview = ({
  submitErrors,
  companySlug,
  productSlug,
  pricingPlanId,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext<ProductPricingPlanFormValues>();
  const { replace } = useFieldArray({
    control,
    name: 'images',
    keyName: 'key',
  });
  const [imagesCards, setImagesCards] = useState(getValues().images);
  const description = useWatch({ control, name: 'description' });
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation<
    void,
    AxiosError,
    { imageId?: number; imageUUID: string }
  >(async ({ imageId, imageUUID }) => {
    try {
      if (pricingPlanId && imageId) {
        await axios.delete(
          `/companies/${companySlug}/products/${productSlug}/pricing-plans/${pricingPlanId}/pricing-plan-images/${imageId}/`
        );
        queryClient.setQueryData<ProductPricingPlanResponse[]>(
          ['get-pricing-plans', companySlug, productId?.toString()],
          oldData => {
            const oldPlansArray = oldData ? [...oldData] : [];
            const planIndex = oldPlansArray.findIndex(
              plan => plan.id === pricingPlanId
            );
            if (planIndex) {
              oldPlansArray[planIndex] = {
                ...oldPlansArray[planIndex],
                images: oldPlansArray[planIndex].images.filter(
                  img => img.id !== imageId
                ),
              };
            }

            return oldData ? oldPlansArray : [];
          }
        );
      }
      setImagesCards(prev => {
        return prev.map(img => ({
          ...img,
          image: img.uuid === imageUUID ? null : img.image,
          image_original: img.uuid === imageUUID ? null : img.image_original,
        }));
      });
    } catch (err) {
      throw err;
    }
  });

  const handleMoveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newArray = [...getValues().images];
      const draggedAsset = newArray?.[dragIndex];
      if (draggedAsset) {
        newArray.splice(dragIndex, 1);
        newArray.splice(hoverIndex, 0, draggedAsset);
      }
      const imagesArrayWithUpdatedOrders = newArray.map((item, index) => ({
        ...item,
        order: index,
      }));
      replace(imagesArrayWithUpdatedOrders);
      setImagesCards(imagesArrayWithUpdatedOrders);
    },
    []
  );

  const saveImagesError = errors?.images;

  return (
    <fieldset>
      <legend>{t('manage-products.overview')}</legend>
      <InputWrapper
        label="common.field.plan-name"
        isMediumInput
        validationError={errors?.name?.message}
        submitError={submitErrors?.data.name?.[0]}
      >
        <input
          type="text"
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.plan-name'
          ).toLowerCase()}`}
          {...register('name')}
        />
      </InputWrapper>

      <InputWrapper
        isMediumInput
        label={`${t('common.field.short-description')} (${t(
          'common.field.optional'
        )})`}
        validationError={errors?.description?.message}
        isErrorHidden={!errors?.description?.message}
        wrapperClasses={styles.benefits}
        submitError={submitErrors?.data.description?.[0]}
        charactersLength={[
          description?.length,
          PRICING_PLAN_DESCRIPTION_MAX_LENGTH,
        ]}
      >
        <TextareaAutoHeight
          methods={register('description')}
          control={control}
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.description'
          ).toLowerCase()}`}
        />
      </InputWrapper>

      <p className={styles.label}>{t('manage-products.images-optional')}</p>
      <p className={styles.dimensions}>{`${t(
        'common.field.optimal-dimensions',
        {
          dimensions: `${PRICING_IMAGE_WIDTH}x${PRICING_IMAGE_HEIGHT} px`,
        }
      )} ${t('common.field.formats', { formats: 'PNG, JPG' })}. ${t(
        'common.error.max'
      )} 3 MB.`}</p>
      {error && <Error message={getResponseError(error)} />}
      {Array.isArray(saveImagesError) &&
        saveImagesError.map((err, idx) => {
          if (err.message) {
            return (
              <Error message={err.message} className={styles.error} key={idx} />
            );
          }
        })}
      <ul className={styles.images}>
        <DndProvider backend={HTML5Backend}>
          {imagesCards.map((image, index) => (
            <PricingImage
              index={index}
              key={image.uuid}
              replace={replace}
              onMoveImage={handleMoveImage}
              onDeleteImage={mutate}
            />
          ))}
        </DndProvider>
      </ul>
      {isLoading && <LoaderScreen />}
    </fieldset>
  );
};

export default Overview;
